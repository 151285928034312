import React from "react";

const NotFound404 = (props) => {
  return (
    <section className="group bk-prop-selected">
      <div className="group row">
        <div
          className="group columns large-12 medium-12 small-12"
          style={{ textAlign: "center" }}
        >
          <h1 style={{ textAlign: "center" }}>
            <i className="fas fa-exclamation-triangle" />
            Seite nicht gefunden
          </h1>
          <p>Seite nicht gefunden</p>
        </div>
      </div>
    </section>
  );
};

export default NotFound404;
