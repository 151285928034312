import React from "react";
import PropTypes from "prop-types";

const SearchGroupOption = (props) => {
  const disabledClass = props.disabled ? " disabled" : "";

  return (
    <div
      className={
        props.optionClass +
        disabledClass +
        " group columns large-12 medium-12 small-12"
      }
    >
      {props.children}
    </div>
  );
};

SearchGroupOption.propTypes = {
  optionClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchGroupOption;
