import { put, call, fork, take } from 'redux-saga/effects'
import * as actionType from './actionType'
import * as errorActionType from '../App/common/error/actionType'
import provider from './provider'
import konfigurationProvider from '../KonfigurationList/provider'
import wohnungsgrundrissProvider from '../App/common/wohnungsgrundriss/provider'

export function * init (konfigurationApi, wohnungsgrundrissApi) {
  while (true) {
    try {
      const action = yield take(actionType.INIT)
      const response = yield call(konfigurationApi.head, action.payload)
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: response.data })
      const wohnungsgrundrissId = response.data.wohnungsgrundrissId
      const responseWohnungsgrundriss =
        yield call(wohnungsgrundrissApi.getWohnungsgrundriss, wohnungsgrundrissId)
      yield put({ type: actionType.SET_WOHNUNGSGRUNDRISS, payload: responseWohnungsgrundriss.data })
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * upload (api) {
  while (true) {
    try {
      const action = yield take(actionType.UPLOAD)
      yield call(api.upload,
        action.payload.konfigurationId,
        action.payload.file,
        action.payload.uploadProgress)
      yield put({ type: actionType.EXPORT_SUCCESS })
    } catch (error) {
      yield put({ type: actionType.EXPORT_ERROR })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export const sagas = [
  fork(init, konfigurationProvider, wohnungsgrundrissProvider),
  fork(upload, provider)
]
