import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as wohnungsgrundrissActions from '../App/common/wohnungsgrundriss/action'
import * as konfigurationActions from '../App/common/konfigurator/action'
import { withRouter } from 'react-router-dom'
import Hero from '../App/common/Hero'
import Steps from '../App/common/Steps'
import Highlights from '../App/common/Highlights'
import NextPrevNavigation from '../App/common/NextPrevNavigation'
import Konfigurator from '../App/common/konfigurator'

export class KonfiguratorPage extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      konfigurationVId: undefined
    }
  }

  componentWillMount () {
    const konfigurationVId = Number(this.props.match.params.konfigurationVId)
    isNaN(konfigurationVId)
      ? this.props.history.push('/fehler/404')
      : this.props.actions.initByKonfigurationVId()

    this.props.konfigurationActions.updateKonfigurationFrontendProperty({
      step: this.props.step,
      url: this.props.history.location.pathname
    })

    this.setState(state => ({ konfigurationVId }))
  }

  componentWillReceiveProps (nextProps) {
    this.props.konfigurationActions.updateKonfigurationFrontendProperty({
      step: this.props.step,
      url: this.props.history.location.pathname
    })
  }

  getNav () {
    return {
      nextLink: this.props.nextLink(this.state.konfigurationVId),
      nextTitle: this.props.nextTitle,
      nextLinkId: this.props.wohnungsgrundrissId,
      previousLink: this.props.previousLink(this.state.konfigurationVId),
      previousTitle: this.props.previousTitle
    }
  }

  render () {
    const nav = this.getNav()
    const { subtitle = 'Wohnung ' + this.props.wohnungsgrundriss.wohnungsCodeReadable } = this.props
    const { frontendProperty = {} } = this.props.konfigurationV

    return (
      <div>
        <Hero
          title={this.props.title}
          subtitle={subtitle}
          description={this.props.description} />
        <div className='section'>
          <div className='container'>
            <div className='content'>
              <Steps step={this.props.step}
                highestStep={frontendProperty.highestStep}
                wohnungsgrundrissId={this.props.wohnungsgrundriss.wohnungsgrundrissId}
                konfigurationVId={this.state.konfigurationVId} />
            </div>
            <div className='content'>
              <Highlights {...this.props.wohnungsgrundriss}
                konfigurationName={this.props.konfigurationV.name}
                editKonfigurationName
                konfigurationId={this.props.konfigurationV.konfigurationId}
                showSaveButton={this.props.user === null}
                aufpreis={this.props.konfigurationV.aufpreis}
                highlights={this.props.konfigurationV.highlights}
                wohnungsgrundrissName={this.props.wohnungsgrundriss.grundrissName} />
            </div>
            <div>
              <Konfigurator konfigurationVId={this.state.konfigurationVId}
                isReadOnly={this.props.konfigurationV.readOnly}
                kategorieClasses={this.props.kategorieClasses} />
            </div>
            <div className='content'>
              <NextPrevNavigation {...nav} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

KonfiguratorPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  step: PropTypes.number.isRequired,
  kategorieClasses: PropTypes.array,
  match: PropTypes.shape({ params: PropTypes.shape({
    konfigurationVId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired }).isRequired,
  nextLink: PropTypes.func.isRequired,
  nextTitle: PropTypes.string.isRequired,
  previousLink: PropTypes.func.isRequired,
  previousTitle: PropTypes.string
}

KonfiguratorPage.defaultProps = {
  kategorieClasses: [],
  previousLink: () => '/',
  previousTitle: 'Konfiguration verlassen'
}

export function mapStateToProps (state) {
  return {
    wohnungsgrundriss: state.wohnungsgrundriss.current,
    konfigurationV: state.konfiguration.currentV,
    user: state.user.current
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(wohnungsgrundrissActions, dispatch),
    konfigurationActions: bindActionCreators(konfigurationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KonfiguratorPage))
