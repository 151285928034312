import React from "react";
import { connect } from "react-redux";
import {
  KonfigurationName,
  mapDispatchToProps,
} from "../../../lib/App/common/KonfigurationName";

export class KonfigurationNameLeonardo extends KonfigurationName {
  render() {
    const isNameKonfigurationHidden = this.state.showChangeNameForm
      ? { display: "none" }
      : {};
    const isNameKonfigurationFormHidden = !this.state.showChangeNameForm
      ? { display: "none" }
      : {};
    const isCancelNameKonfigurationInvisibility =
      this.state.nameKonfiguration.length === 0 ? { visibility: "hidden" } : {};

    return (
      <div>
        <span
          onClick={this.handleChangeNameToggle}
          style={isNameKonfigurationHidden}
        >
          {this.state.nameKonfiguration}
        </span>
        <span style={isNameKonfigurationFormHidden}>
          <input
            type="text"
            onChange={this.handleChangeName}
            value={this.state.nameKonfiguration}
          />
          <span
            onClick={this.handleChangeNameSave}
            style={isCancelNameKonfigurationInvisibility}
          >
            <i className={"fas fa-check"} />
          </span>
          <span onClick={this.handleChangeNameCancel}>
            <i className="fas fa-times" />
          </span>
        </span>
      </div>
    );
  }
}

export default connect(
  undefined,
  mapDispatchToProps
)(KonfigurationNameLeonardo);
