import React, { Component } from 'react'
import Image from '../../Image'
import Slider from 'rc-slider'
import Translate from '../../translator/components/translate'
import { CurrencyFormat } from '../../ValueFormatter'

export function variationenSwitch (variation, components, isReadOnly) {
  const { ReadOnlyVariation, Variation } = components
  if (variation.classes.indexOf('read_only') > -1 || isReadOnly) {
    return ReadOnlyVariation
  } else {
    return Variation
  }
}

const VariationenSwitch = props => {
  const variationen = props.variationen || []
  return <div className='variation-body'>
    {variationen.map(item => {
      const VariationComponent =
        props.switches.variationenSwitch(item, props.components, props.isReadOnly)

      return <VariationComponent key={item.variationId} {...item}
        handleVariationChange={props.handleVariationChange} />
    })}
  </div>
}

export default VariationenSwitch

export const Variation = props => {
  const charge = props.aufpreis !== undefined && props.aufpreis !== 0
    ? <p><CurrencyFormat value={props.aufpreis} currency='CHF' /></p> : undefined
  return (
    <div className='variation-item'>
      <div onClick={() => props.handleVariationChange(props)}
        className={'card is-clickable' +
          (props.selected ? ' is-active' : '') + (props.isDefault ? ' is-default' : '')}>
        <div className='card-image'>
          <figure className='image is-4by3'>
            <Image src={props.imagePath} alt={props.name + ' preview'}
              width='180w' tabletWidth='180w' mobileWidth='720w' ratio='r1.56' />
          </figure>
        </div>
        <div className='card-content'>
          <div className='content'>
            <p><strong><Translate id={'variationen/' + props.variationCode + '/name'}>{props.name}</Translate></strong></p>
            <p>{props.beschreibung &&
            <Translate id={'variationen/' + props.variationCode + '/beschreibung'}>{props.beschreibung}</Translate>}</p>
            {charge}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ReadOnlyVariation = props => {
  const charge = props.aufpreis !== undefined && props.aufpreis !== 0
    ? <p><CurrencyFormat value={props.aufpreis} currency='CHF' /></p> : undefined
  return (
    <div className='variation-item'>
      <div className={'card' +
      (props.selected && props.showSelected ? ' is-active' : '') + (props.isDefault ? ' is-default' : '')}>
        <div className='card-image'>
          <figure className='image is-4by3'>
            <Image src={props.imagePath} alt={props.name + ' preview'}
              width='180w' tabletWidth='180w' mobileWidth='720w' ratio='r1.56' />
          </figure>
        </div>
        <div className='card-content'>
          <div className='content'>
            <p><strong><Translate id={'variationen/' + props.variationCode + '/name'}>{props.name}</Translate></strong></p>
            <p>{props.beschreibung && <Translate id={'variationen/' + props.variationCode + '/beschreibung'}>{props.beschreibung}</Translate>}</p>
            {charge}
          </div>
        </div>
      </div>
    </div>
  )
}

ReadOnlyVariation.defaultProps = {
  showSelected: true
}

export const ReadOnlyBooleanVariation = props => {
  const charge = props.aufpreis !== undefined && props.aufpreis !== 0
    ? <p><CurrencyFormat value={props.aufpreis} currency='CHF' /></p> : undefined
  return (
    <div className='variation-item'>
      <div className='card'>
        <div className='card-content'>
          <div className='content'>
            <p><strong><Translate id={'variationen/' + props.variationCode + '/name'}>{props.name}</Translate></strong></p>
            <hr />
            <p>{props.beschreibung && <Translate id={'variationen/' + props.variationCode + '/beschreibung'}>{props.beschreibung}</Translate>}</p>
            {charge}
          </div>
        </div>
      </div>
    </div>
  )
}

export class Debounce extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value,
      hasError: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleAfterChange = this.handleAfterChange.bind(this)
  }

  checkInput (pattern, value) {
    const replacedValue = value.replace(/[\r\n]/g, ' ')
    const regExp = new RegExp(pattern)
    return regExp.test(replacedValue)
  }

  debouncedSave (value, delay, type) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => this.handleAfterChange(value, type), delay)
  }

  componentWillReceiveProps (nextProps) {
    this.setState(state => ({ value: nextProps.value }))
  }

  // override specific
  handleChange (value) {}

  handleAfterChange (value, type) {
    if (this.state.value !== this.props.value) {
      this.props.handleVariationChange(this.props, value, type)
    }
  }

  render () {
    return <div>debounce</div>
  }
}

export class SliderVariation extends Debounce {
  handleChange (inputValue) {
    const number = Number(inputValue)
    if (!isNaN(number)) {
      const value = number >= this.props.max
        ? this.props.max
        : number <= this.props.min ? this.props.min : Math.floor(number)
      this.setState(state => ({ value }))
      this.debouncedSave(value, 500, 'number')
    }
  }

  render () {
    const { min, max, step } = this.props
    let marks = {}
    const maxMarkers = 8 // set maxMarkers to max - 2 because min and max
    const markerStep = Math.max(Math.floor((max - min) / maxMarkers), step)
    for (let i = min; i < max; i += markerStep) {
      marks[i] = i
    }
    marks[max] = max
    const aufpreisBeschreibung = this.props.aufpreisBeschreibung !== undefined
      ? <span className='costs-additional' style={{ textTransform: 'none' }}>{this.props.aufpreisBeschreibung}</span>
      : null
    const { action, actionInput } = this.props.isReadOnly
      ? { action: () => {}, actionInput: () => {} }
      : { action: this.handleChange, actionInput: event => this.handleChange(event.target.value) }

    return (
      <div className='field'>
        <label className='label' htmlFor={'options-' + this.props.numberId}>
          <span>{this.props.name}</span>
          {aufpreisBeschreibung}
        </label>
        <Slider
          className='m-t-md'
          name={'slider-' + this.props.numberId}
          step={step} min={min} max={max}
          value={this.state.value}
          onChange={action}
          marks={marks}
          disabled={this.props.isReadOnly} />
        <div className='control p-t-lg'>
          <input
            className='input'
            type='number'
            value={this.state.value}
            onChange={actionInput}
            onFocus={event => event.target.select()}
            id={'options-' + this.props.numberId}
            disabled={this.props.isReadOnly} />
        </div>
      </div>
    )
  }
}

export const TextVariation = props => {
  const InputComponent = textVariationSwitch(props)
  return (
    <div className='field'>
      <label className='label' htmlFor={'text-' + props.textId}>{props.name}</label>
      <InputComponent {...props} />
    </div>
  )
}

function textVariationSwitch (props) {
  if (~props.classes.indexOf('textarea')) {
    return TextVariationTextarea
  } else {
    return TextVariationInput
  }
}

class TextVariationInput extends Debounce {
  handleChange (value, delay = 5000) {
    this.setState(state => ({ value }))
    if (this.checkInput(this.props.pattern, value)) {
      this.setState(state => ({ hasError: false }))
      this.debouncedSave(value, delay, 'text')
    } else {
      this.setState(state => ({ hasError: true }))
    }
  }

  render () {
    const action = this.props.isReadOnly ? () => {} : event => this.handleChange(event.target.value)
    return (
      <div className='control'>
        <input className='input' type='text' name={'text-' + this.props.textId} id={'text-' + this.props.textId}
          value={this.state.value} placeholder={this.props.placeholder}
          onChange={action}
          onBlur={action}
          disabled={this.props.isReadOnly} />
      </div>
    )
  }
}

class TextVariationTextarea extends Debounce {
  handleChange (value, delay = 5000) {
    this.setState(state => ({ value }))
    if (this.checkInput(this.props.pattern, value)) {
      this.setState(state => ({ hasError: false }))
      this.debouncedSave(value, delay, 'text')
    } else {
      this.setState(state => ({ hasError: true }))
    }
  }

  render () {
    const errorMsg = this.props.error || 'Ungültige Eingabe'
    const error = this.state.hasError
      ? <div className='error'>{errorMsg}</div> : null
    const action = this.props.isReadOnly ? () => {} : event => this.handleChange(event.target.value)
    return (
      <div className='control'>
        <textarea className='textarea' name={'text-' + this.props.textId} cols='32' rows='6' id={'text-' + this.props.textId}
          placeholder={this.props.placeholder} value={this.state.value}
          onChange={action}
          onBlur={action}
          disabled={this.props.isReadOnly} />
        {error}
      </div>
    )
  }
}
