import * as actionType from './actionType'

export function bootstrap (konfigurationVId, kategorieClasses,
  onlySelected = false, forceUpdate = false) {
  return {
    type: actionType.BOOTSTRAP,
    payload: { konfigurationVId, kategorieClasses, onlySelected, forceUpdate } }
}

export function fetch (konfigurationVId) {
  return { type: actionType.FETCH, payload: konfigurationVId }
}

export function fetchNew (action) {
  return { type: actionType.FETCH_NEW, payload: action }
}

export function fetchList (konfigurationVId, kategorieClasses, onlySelected = false) {
  return { type: actionType.FETCH_VARIATION_LIST, payload: { konfigurationVId, kategorieClasses, onlySelected } }
}

export function updateVariation (variationId, kategorieClasses) {
  return { type: actionType.SELECT_VARIATION, payload: { variationId, kategorieClasses } }
}

export function updateNumber (numberId, value, kategorieClasses) {
  return { type: actionType.SELECT_NUMBER_VARIATION, payload: { numberId, value, kategorieClasses } }
}

export function updateText (textId, value, kategorieClasses) {
  return { type: actionType.SELECT_TEXT_VARIATION, payload: { textId, value, kategorieClasses } }
}

export function updateFrontendProperty (property) {
  return { type: actionType.UPDATE_FRONTEND_PROPERTY, payload: property }
}

export function updateKonfigurationFrontendProperty (property) {
  return { type: actionType.UPDATE_KONFIGURATION_FRONTEND_PROPERTY, payload: property }
}

export function updateWohnungsgrundriss (params) {
  return { type: actionType.UPDATE_WOHNUNGSGRUNDRISS, payload: params }
}

export function fetchReport (konfigurationVId, filename) {
  return { type: actionType.FETCH_REPORT, payload: { konfigurationVId, filename } }
}

export function showKonfigurationVisualization () {
  return { type: actionType.SAVE_KONFIGURATION }
}
