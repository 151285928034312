import React, { Component, Fragment } from 'react'

export function kategorienSwitch (kategorie, components) {
  const { EmphasizedKategorie, CollapsableKategorie, PackageKategorie, Kategorie } = components
  if (kategorie.classes.indexOf('emphasized') > -1) {
    return EmphasizedKategorie
  } else if (kategorie.classes.indexOf('collapsable') > -1) {
    return CollapsableKategorie
  } else if (kategorie.classes.indexOf('package') > -1) {
    return PackageKategorie
  } else {
    return Kategorie
  }
}

const KategorienSwitch = props => {
  return (
    <Fragment>
      {props.kategorien.map(
        kategorie => {
          const KategorieComponent = props.switches.kategorienSwitch(kategorie, props.components)
          const popups = props.getPopups(kategorie.classes)

          return (
            <KategorieComponent key={kategorie.kategorieId} {...kategorie}
              isSubset={props.isSubset}
              isReadOnly={props.isReadOnly}
              hasOptionen={kategorie.optionen !== undefined && kategorie.optionen.length > 0}
              switches={props.switches}
              getPopups={props.getPopups}
              popups={popups}
              components={props.components}
              handleVariationChange={props.handleVariationChange}
              handleFrontendPropertyChange={props.handleFrontendPropertyChange} />
          )
        })}
    </Fragment>
  )
}

export default KategorienSwitch

export class TreeableKategorie extends Component {
  getSubKategorien (props) {
    return props.kategorien !== undefined
      ? <KategorienSwitch kategorien={props.kategorien} isSubset isReadOnly={props.isReadOnly}
        hasOptionen={props.optionen !== undefined && props.optionen.length > 0}
        handleVariationChange={props.handleVariationChange}
        handleFrontendPropertyChange={props.handleFrontendPropertyChange}
        switches={props.switches}
        getPopups={props.getPopups}
        components={props.components} />
      : null
  }

  render () {
    return <div />
  }
}

export function getKategorieSize (props) {
  return props.kategorien === null ? ' is-5' : ''
}

export function getKategorieRendering (props, Rendering) {
  return props.kategorien === null && props.rendering !== undefined
    ? (
      <div className='column'>
        <Rendering imagePath={props.rendering.imagePath} name={props.name} />
      </div>
    ) : null
}
