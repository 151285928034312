export const FETCH_NEW = 'USER_FETCH_NEW'
export const LOGIN = 'USER_LOGIN'
export const LOGOUT = 'USER_LOGOUT'
export const SET = 'USER_SET'
export const REMOVE = 'USER_REMOVE'
export const ACTIVATE = 'USER_ACTIVATE'
export const RECOVER_PASSWORD = 'USER_RECOVER_PASSWORD'
export const RESET_PASSWORD = 'USER_RESET_PASSWORD'
export const UPDATE_PROFILE = 'USER_UPDATE_PROFILE'
export const UPDATE_PROFILE_PASSWORD = 'USER_UPDATE_PROFILE_PASSWORD'

export const REGISTRATION_SUCCESS = 'USER_REGISTER_SUCCESS'
export const REGISTRATION_ERROR = 'USER_REGISTER_ERROR'
export const REGISTRATION_RESET = 'USER_REGISTER_RESET'
export const LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const ACTIVATION_ERROR = 'USER_ACTIVATION_ERROR'
export const PASSWORD_RECOVERY_ERROR = 'USER_PASSWORD_RECOVERY_ERROR'
export const PASSWORD_RECOVERY_SUCCESS = 'USER_PASSWORD_RECOVERY_SUCCESS'
export const PASSWORD_RECOVERY_RESET = 'USER_PASSWORD_RECOVERY_RESET'
export const PASSWORD_RESET_ERROR = 'USER_PASSWORD_RESET_ERROR'
export const PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR'
export const PROFILE_PASSWORD_UPDATE_SUCCESS = 'USER_PROFILE_PASSWORD_UPDATE_SUCCESS'
export const PROFILE_PASSWORD_UPDATE_ERROR = 'USER_PROFILE_PASSWORD_UPDATE_ERROR'
