import React, { Component } from 'react'
import PropTypes from 'prop-types'
import KategorienSwitch from './Kategorien'
import './konfiguration.css'

class Konfiguration extends Component {
  constructor (props, context) {
    super(props, context)

    this.handleFrontendPropertyChange = this.handleFrontendPropertyChange.bind(this)
  }

  handleFrontendPropertyChange (changed, type, id, property = {}) {
    const frontendProperty = Object.assign({}, property, changed)
    this.props.handleFrontendPropertyChange({ type, id, frontendProperty })
  }

  render () {
    return (
      <KategorienSwitch {...this.props} isSubset={false}
        handleFrontendPropertyChange={this.handleFrontendPropertyChange} />
    )
  }
}

Konfiguration.propTypes = {
  kategorien: PropTypes.array,
  isSubset: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  hasOptionen: PropTypes.bool,
  handleVariationChange: PropTypes.func,
  handleFrontendPropertyChange: PropTypes.func,
  components: PropTypes.any, // see propTypes of Konfigurator
  getPopups: PropTypes.func
}

Konfiguration.defaultProps = {
  kategorien: [],
  isSubset: false,
  isReadOnly: false,
  hasOptionen: true,
  handleVariationChange: () => {},
  handleFrontendPropertyChange: () => {},
  getPopups: classes => []
}

export default Konfiguration
