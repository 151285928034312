export const API_URL = process.env.REACT_APP_API_URL
export const API_VERSION = process.env.REACT_APP_API_VERSION
export const PICS_URL = process.env.REACT_APP_PICS_URL
const location = window.location

export const ROOT_URL = location.protocol + '//' + location.hostname +
  (location.port !== '80' || location.port !== '' ? ':' + location.port : '')

export const COVER_PAGE_URL = window.COVER_PAGE_URL
export const SYSTEM = window.SYSTEM
