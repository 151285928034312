import React from 'react'
import PropTypes from 'prop-types'

const Hero = props => (
  <div className='hero is-primary'>
    <div className='hero-body'>
      <div className='container'>
        <div className='columns'>
          <div className='column'>
            <h1 className='title'>{props.title}</h1>
            <h2 className='subtitle'>{props.subtitle}</h2>
          </div>
          <div className='column'>
            <h2>{props.description}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string
}

export default Hero
