import React from 'react'
import { withLocalize } from 'react-localize-redux'

const LanguageSelector = ({ languages, activeLanguage, setActiveLanguage }) => (
  <ul className='langSelector'>
    {languages.map(lang => (
      <li key={lang.code} onClick={() => setActiveLanguage(lang.code)}>
        {lang.name}
      </li>
    ))}
  </ul>
)

export default withLocalize(LanguageSelector)
