import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

/* eslint-disable jsx-a11y/anchor-is-valid */

export class NextPrevNavigation extends Component {
  getNext () {
    return this.props.nextLink !== undefined && this.props.nextTitle !== undefined
      ? {
        title: this.props.nextTitle,
        link: this.props.nextLink,
        position: 'right',
        onClick: this.props.nextOnClick
      }
      : undefined
  }

  getPrevious () {
    return this.props.previousLink !== undefined && this.props.previousTitle !== undefined
      ? {
        title: this.props.previousTitle,
        link: this.props.previousLink,
        position: 'left',
        onClick: this.props.previousOnClick
      }
      : undefined
  }

  getNavItems () {
    const next = this.getNext()
    const previous = this.getPrevious()
    return [previous, next].filter(item => item !== undefined)
  }

  render () {
    const navItems = this.getNavItems()

    return (
      <nav className='level'>
        {navItems.map(item => <NavItem key={item.position} {...item} />)}
      </nav>
    )
  }
}

NextPrevNavigation.propTypes = {
  nextLink: PropTypes.string,
  nextTitle: PropTypes.string,
  nextOnClick: PropTypes.func,
  nextOnClickValue: PropTypes.any,
  previousLink: PropTypes.string,
  previousTitle: PropTypes.string,
  previousOnClick: PropTypes.func,
  previousOnClickValue: PropTypes.any
}

export default NextPrevNavigation

export class NavItem extends Component {
  getClickHander () {
    return event => {
      event.preventDefault()
      this.props.onClick(this.props.link, this.props.value)
    }
  }

  getClickable (handleClick) {
    return this.props.onClick !== undefined
      ? <a onClick={handleClick}>{this.props.title}</a>
      : <Link to={this.props.link}>{this.props.title}</Link>
  }

  render () {
    const handleClick = this.getClickHander()
    const clickable = this.getClickable(handleClick)

    return (
      <div className={'level-' + this.props.position}>
        <div className='level-item'>
          <p className='subtitle'>
            {clickable}
          </p>
        </div>
      </div>
    )
  }
}
