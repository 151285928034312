import * as actionType from './actionType'

export function fetchListByWohnungId (id) {
  return { type: actionType.FETCH_LIST_BY_WOHNUNG_ID, payload: id }
}

export function fetchListByWohnungsgrundrissId (id) {
  return { type: actionType.FETCH_LIST_BY_WOHNUNG_GRUNDRISS_ID, payload: id }
}

export function set (wohnungsgrundriss) {
  return { type: actionType.SET, payload: wohnungsgrundriss }
}

export function initByKonfigurationVId () {
  return { type: actionType.INIT_BY_KONFIGURATIONV_ID }
}

export function initByKonfigurationVIdWithGrundrisse (konfigurationVId) {
  return {
    type: actionType.INIT_BY_KONFIGURATIONV_ID_WITH_WOHNUNGSGRUNDRISSE,
    payload: konfigurationVId
  }
}
