import React from 'react'
import PropTypes from 'prop-types'

const InfoTooltip = props => (
  <span className='tooltip is-tooltip-multiline' data-tooltip={props.text}>
    <i className={'fa fa-info-circle has-text-primary is-size-' + props.size} />
  </span>
)

InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.number
}

InfoTooltip.defaultProps = {
  size: 5
}

export default InfoTooltip
