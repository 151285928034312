import * as actionType from './actionType'

export function fetchNew (form) {
  return { type: actionType.FETCH_NEW, payload: form }
}

export function login (form) {
  return { type: actionType.LOGIN, payload: form }
}

export function logout () {
  return { type: actionType.LOGOUT }
}

export function activate (token) {
  return { type: actionType.ACTIVATE, payload: token }
}

export function recoverPassword (form) {
  return { type: actionType.RECOVER_PASSWORD, payload: form }
}

export function resetPassword (form) {
  return { type: actionType.RESET_PASSWORD, payload: form }
}

export function updateProfile (form) {
  return { type: actionType.UPDATE_PROFILE, payload: form }
}

export function updateProfilePassword (form) {
  return { type: actionType.UPDATE_PROFILE_PASSWORD, payload: form }
}

export function resetRegistrationFeedback () {
  return { type: actionType.REGISTRATION_RESET }
}

export function resetRecoverPasswordFeedback () {
  return { type: actionType.PASSWORD_RECOVERY_RESET }
}
