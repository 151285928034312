export const BOOTSTRAP = 'KONFIGURATOR_BOOTSTRAP'

export const FETCH_NEW = 'KONFIGURATOR_FETCH_NEW_KONFIGURATION'
export const FETCH = 'KONFIGURATOR_FETCH_KONFIGURATION'
export const FETCH_REPORT = 'KONFIGURATOR_FETCH_KONFIGURATION_V_REPORT'
export const FETCH_REPORT_FINISHED = 'KONFIGURATOR_FETCH_FINISHED_KONFIGURATION_V_REPORT'
export const SET = 'KONFIGURATOR_SET_KONFIGURATION'
export const SET_KONFIGURATION_V = 'KONFIGURATOR_SET_KONFIGURATION_V'
export const SET_KONFIGURATION_V_READ_ONLY = 'KONFIGURATOR_SET_KONFIGURATION_V_READ_ONLY'
export const SET_KONFIGURATION_V_NAME = 'KONFIGURATOR_SET_KONFIGURATION_V_NAME'

export const SET_VARIATION_LIST = 'KONFIGURATOR_LIST_SET_VARIATION'
export const FETCH_VARIATION_LIST = 'KONFIGURATOR_LIST_FETCH_VARIATION'

export const SET_VARIATION = 'KONFIGURATOR_SET_VARIATION'
export const UPDATE_VARIATION = 'KONFIGURATOR_UPDATE_VARIATION'
export const SELECT_VARIATION = 'KONFIGURATOR_SELECT_VARIATION'

export const UPDATE_NUMBER_VARIATION = 'KONFIGURATOR_UPDATE_NUMBER_VARIATION'
export const SELECT_NUMBER_VARIATION = 'KONFIGURATOR_SELECT_NUMBER_VARIATION'

export const UPDATE_TEXT_VARIATION = 'KONFIGURATOR_UPDATE_TEXT_VARIATION'
export const SELECT_TEXT_VARIATION = 'KONFIGURATOR_SELECT_TEXT_VARIATION'

export const SET_KATEGORIE_CLASSES_LIST = 'KONFIGURATOR_SET_KATEGORIE_CLASSES_LIST'

export const UPDATE_FRONTEND_PROPERTY = 'KONFIGURATION_UPDATE_FRONTEND_PROPERTY'
export const UPDATE_KONFIGURATION_FRONTEND_PROPERTY = 'KONFIGURATION_UPDATE_KONFIGURATION_FRONTEND_PROPERTY'
export const SET_FRONTEND_PROPERTY = 'KONFIGURATION_SET_FRONTEND_PROPERTY'

export const UPDATE_WOHNUNGSGRUNDRISS = 'KONFIGURATION_UPDATE_WOHNUNGSGRUNDRISS'

export const SET_SHARE_TOKEN = 'KONFIGURATION_SET_SHARE_TOKEN'
export const FETCH_FROM_SHARE_TOKEN = 'KONFIGURATION_FETCH_FROM_SHARE_TOKEN'

export const SAVE_KONFIGURATION = 'KONFIGURATION_SAVE'
