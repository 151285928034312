import React, { Component } from 'react'
import localStore from '../localStore'

class Authorized extends Component {
  componentWillMount () {
    if (localStore === undefined || localStore.getItem('renewToken') === null) {
      this.props.history.push('/')
    }
  }

  render () {
    return <div />
  }
}

export default Authorized
