import React, { Fragment } from "react";
import {
  BooleanVariation,
  SliderVariation,
  TextVariation,
} from "./Variationen";
import Translate from "../../../../lib/App/common/translator/components/translate";

const PackageKategorieLeonardo = (props) => {
  const options = props.optionen || [];
  const translateName = (
    <Translate id={"kategorie/" + props.kategorieCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateDesc = props.beschreibung ? (
    <Translate id={"kategorie/" + props.kategorieCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );
  return (
    <Fragment>
      <div className="bk-options-radios group columns large-12 medium-12 small-12">
        <div className="columns large-5 medium-5 small-12">
          <h2>{translateName}</h2>
          <p>{translateDesc}</p>
        </div>
        <div className="columns large-7 medium-7 small-12">
          {options.map((option, index) => packageSwitch(option, props, index))}
        </div>
      </div>
    </Fragment>
  );
};

export default PackageKategorieLeonardo;

function packageSwitch(option, props, index) {
  const styling = index > 1 ? { marginTop: "20px" } : {};
  if (~option.classes.indexOf("text")) {
    const variation = option.text || {};
    return (
      <ul className="group" style={styling}>
        <li className="group" key={option.optionId}>
          <TextVariation {...option} {...variation} />
        </li>
      </ul>
    );
  } else if (~option.classes.indexOf("number")) {
    const variation = option.number || {};
    return (
      <ul className="group sliderlist" key={option.optionId} style={styling}>
        <li className="group">
          <SliderVariation
            {...option}
            {...variation}
            isReadOnly={props.isReadOnly}
            handleVariationChange={props.handleVariationChange}
          />
        </li>
      </ul>
    );
  } else if (~option.classes.indexOf("boolean")) {
    const variationen = option.variationen || [];
    return (
      <ul className="group" key={option.optionId} style={styling}>
        <li className="group">
          {variationen.map((item) => (
            <BooleanVariation key={item.variationId} {...option} {...item} />
          ))}
        </li>
      </ul>
    );
  } else if (~option.classes.indexOf("info")) {
    const variation = option.variationen[0] || {};
    const translateOptionName = option.name ? (
      <Translate id={"optionen/" + option.optionCode + "/name"}>
        {option.name}
      </Translate>
    ) : (
      ""
    );
    let variationName = variation.name ? variation.name : "";

    const numbers = variation.name.match(/\d+/g).map(Number);
    let replaces = {};
    numbers.forEach((elem, index) => {
      replaces["num" + index] = elem;
    });
    Object.keys(replaces).forEach((key) => {
      variationName = variationName.replace("num", key);
    });

    const translateVName = variationName ? (
      <Translate
        id={"variationen/" + variation.variationCode + "/name"}
        data={replaces}
      >
        {variationName}
      </Translate>
    ) : (
      ""
    );

    return (
      <Fragment key={option.optionId}>
        <h3 style={styling}>{translateOptionName}</h3>
        <p>{translateVName}</p>
      </Fragment>
    );
  }
}
