import {
  fork,
  take,
  put,
  call,
  takeLatest,
  select,
  delay,
} from "redux-saga/effects";
import * as actionType from "../lib/User/actionType";
import * as konfiguratorActionType from "../lib/App/common/konfigurator/actionType";
import * as zusammenfassungActionType from "../lib//Zusammenfassung/actionType";
import * as errorActionType from "../lib/App/common/error/actionType";
import * as authActionType from "../lib/App/common/auth/actionType";
import localStore from "../lib/App/localStore";
import provider from "../lib/User/provider";
import history from "../lib/App/common/history";

export function* fetchNew(api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_NEW);
      yield call(api.register, action.payload);
      yield put({ type: actionType.REGISTRATION_SUCCESS });
    } catch (error) {
      yield put({ type: actionType.REGISTRATION_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* login(api) {
  while (true) {
    try {
      const action = yield take(actionType.LOGIN);
      const response = yield call(api.login, action.payload);
      yield put({ type: actionType.SET, payload: response.data.user });
      yield put({ type: authActionType.FETCH_LAST_KONFIGURATION });
    } catch (error) {
      yield put({ type: actionType.LOGIN_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* logout(localStore_ = localStore) {
  const removeItemOfLocalStore = localStore_.removeItem.bind(localStore_);
  yield call(removeItemOfLocalStore, "renewToken");
  yield call(removeItemOfLocalStore, "accessToken");
  yield put({ type: actionType.REMOVE });
  yield put({ type: konfiguratorActionType.SET, payload: {} });
  yield put({
    type: konfiguratorActionType.SET_KONFIGURATION_V,
    payload: { konfigurationV: {} },
  });
  yield put({ type: konfiguratorActionType.SET_VARIATION_LIST, payload: [] });
  yield put({
    type: zusammenfassungActionType.SET_VARIATION_LIST,
    payload: [],
  });
  const historyPush = history.push.bind(history);
  yield call(historyPush, "/anmelden");
}

export function* activate(api) {
  while (true) {
    try {
      const action = yield take(actionType.ACTIVATE);
      const response = yield call(api.activate, action.payload);
      yield put({ type: actionType.SET, payload: response.data.user });
      yield delay(5000);
      yield put({ type: authActionType.FETCH_LAST_KONFIGURATION });
    } catch (error) {
      yield put({ type: actionType.ACTIVATION_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* recoverPassword(api) {
  while (true) {
    try {
      const action = yield take(actionType.RECOVER_PASSWORD);
      yield call(api.recoverPassword, action.payload);
      yield put({ type: actionType.PASSWORD_RECOVERY_SUCCESS });
    } catch (error) {
      yield put({ type: actionType.PASSWORD_RECOVERY_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* resetPassword(api) {
  while (true) {
    try {
      const action = yield take(actionType.RESET_PASSWORD);
      const response = yield call(api.resetPassword, action.payload);
      yield put({ type: actionType.SET, payload: response.data.user });
      yield put({ type: authActionType.FETCH_LAST_KONFIGURATION });
    } catch (error) {
      yield put({ type: actionType.PASSWORD_RESET_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* updateProfile(api) {
  while (true) {
    try {
      const action = yield take(actionType.UPDATE_PROFILE);
      yield call(updateProfileCore, action, api);
      yield put({ type: actionType.PROFILE_UPDATE_SUCCESS });
    } catch (error) {
      yield put({ type: actionType.PROFILE_UPDATE_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* updateProfilePassword(api) {
  while (true) {
    try {
      const action = yield take(actionType.UPDATE_PROFILE_PASSWORD);
      yield call(updateProfileCore, action, api);
      yield put({ type: actionType.PROFILE_PASSWORD_UPDATE_SUCCESS });
    } catch (error) {
      yield put({ type: actionType.PROFILE_PASSWORD_UPDATE_ERROR });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* updateProfileCore(action, api) {
  const userId = yield select((state) => state.user.current.userId);
  const response = yield call(api.updateProfile, userId, action.payload);
  yield put({ type: actionType.SET, payload: response.data });
}

export const sagas = [
  fork(fetchNew, provider),
  fork(login, provider),
  takeLatest(actionType.LOGOUT, logout, localStore),
  fork(activate, provider),
  fork(recoverPassword, provider),
  fork(resetPassword, provider),
  fork(updateProfile, provider),
  fork(updateProfilePassword, provider),
];
