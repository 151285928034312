import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Translate as LocalizeTranslate, withLocalize, getActiveLanguage } from 'react-localize-redux'
import { selectTranslationId } from '../action'
import './translate.css'

class TranslateWrapper extends React.Component {
  render () {
    let cssClasses = ['translate']

    if (isSelected(this.props)) {
      cssClasses.push('selected')
    } else if (this.props.highlighted) {
      cssClasses.push('highlighted')
    }

    let onClick = null
    if (this.props.clickable) {
      onClick = () => {
        this.props.actions.selectTranslationId(this.props.id)
      }
    }

    const data = Object.assign({}, this.props.data, {br: '<br>'})
    return <span onClick={onClick} className={cssClasses.join(' ')}>
      <LocalizeTranslate id={this.props.id.toLowerCase()} data={data}>{this.props.children}</LocalizeTranslate>
    </span>
  }
}

function isSelected (props) {
  return props.activeTranslationIds.hasOwnProperty(props.id)
}

const mapStateToProps = state => {
  return {
    activeTranslationIds: state.translator.activeTranslationIds,
    clickable: state.translator.clickable,
    highlighted: state.translator.highlighted,
    activeLanguage: getActiveLanguage(state.localize)
  }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators({ selectTranslationId }, dispatch) }
}

const Translate = withLocalize(connect(mapStateToProps, mapDispatchToProps)(TranslateWrapper))
export default Translate
