import { take, put, call, fork } from 'redux-saga/effects'
import provider from './provider'
import * as actionType from './actionType'
// import * as errorActionType from '../error/actionType'

export function * fetchStaticVariations (api) {
  while (true) {
    const action = yield take(actionType.FETCH_STATIC_VARIATIONS)
    const response = yield call(api.fetchStaticVariations, action.payload)
    yield put({ type: actionType.SET_STATIC_VARIATIONS, payload: response.data })
  }
}

export const sagas = [
  fork(fetchStaticVariations, provider)
]
