import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Filter,
  mapStateToProps,
  mapDispatchToProps,
} from "../../lib/KonfigurationList/components/Filter";
import { InputLeonardo, DropdownLeonardo } from "../../App/common/Form/Form";

export class FilterLeonardo extends Filter {
  constructor(props) {
    super(props);

    this.state = { toggleFilter: false };
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
  }

  transformWohnungsStatusFilterOption(availableStatus) {
    return availableStatus.map((item) =>
      Object.assign({}, item, { text: item.text.toUpperCase() })
    );
  }

  getIdFilter(id, handleIdFilterChange) {
    return (
      <InputLeonardo
        fieldClass="columns large-1 medium-3 small-12 group"
        getValue={handleIdFilterChange}
        id="konfigurationListFilterKonfigurationId"
        value={id}
        displayName="ID"
      />
    );
  }

  getFirstNameFilter(isMakler, handleNameFirstFilterChange, nameFirst) {
    return isMakler ? (
      <InputLeonardo
        fieldClass="columns large-3 medium-4 small-12 group"
        getValue={handleNameFirstFilterChange}
        id="konfigurationListFilterNameFirst"
        value={nameFirst}
        displayName={
          <Fragment>
            Vorname <small>(Interessent)</small>
          </Fragment>
        }
      />
    ) : null;
  }

  getLastNameFitler(isMakler, handleNameLastFilterChange, nameLast) {
    return isMakler ? (
      <InputLeonardo
        fieldClass="columns large-3 medium-5 small-12 group"
        getValue={handleNameLastFilterChange}
        id="konfigurationListFilterNameLast"
        value={nameLast}
        displayName={
          <Fragment>
            Nachname <small>(Interessent)</small>
          </Fragment>
        }
      />
    ) : null;
  }

  getKonfigurationNameFilter(handleNameKonfigurationFilterChange, name) {
    return (
      <InputLeonardo
        fieldClass="columns large-2 medium-5 small-12 group"
        getValue={handleNameKonfigurationFilterChange}
        id="konfigurationListFilterNameKonfiguration"
        value={name}
        displayName={
          <Fragment>
            Name <small>(Konfiguration)</small>
          </Fragment>
        }
      />
    );
  }

  getWohnungsCodeFilter(handleWohnungsCodeFilterChange, wohnungsCode) {
    return (
      <InputLeonardo
        fieldClass="columns large-2 medium-3 small-12 group"
        getValue={handleWohnungsCodeFilterChange}
        id="konfigurationListFilterWohnungsCode"
        value={wohnungsCode}
        displayName="Einheiten"
      />
    );
  }

  getWohnungsStatusFilter(status, handleStatusFilterChange, availableStatus) {
    return (
      <li className="columns large-1 medium-4 small-12 group">
        <label htmlFor="konfigurationListFilterStatus">Status</label>
        <DropdownLeonardo
          id="konfigurationListFilterStatus"
          displayName="Status"
          value={status}
          getValue={handleStatusFilterChange}
          pleaseSelectValue={""}
          required={false}
          optionen={availableStatus}
        />
      </li>
    );
  }

  handleToggleFilter() {
    this.setState((state) => ({ toggleFilter: !state.toggleFilter }));
  }

  render() {
    const idFilter = this.getIdFilter(this.props.id, this.handleIdFilterChange);
    const nameFirstFilter = this.getFirstNameFilter(
      this.props.isMakler,
      this.handleNameFirstFilterChange,
      this.props.nameFirst
    );
    const nameLastFilter = this.getLastNameFitler(
      this.props.isMakler,
      this.handleNameLastFilterChange,
      this.props.nameLast
    );
    const nameKonfigurationFilter = this.getKonfigurationNameFilter(
      this.handleNameKonfigurationFilterChange,
      this.props.name
    );
    const wohnungsCodeFilter = this.getWohnungsCodeFilter(
      this.handleWohnungsCodeFilterChange,
      this.props.wohnungsCode
    );
    const wohnungsStatusFilter = this.getWohnungsStatusFilter(
      this.props.status,
      this.handleStatusFilterChange,
      this.props.availableStatus
    );
    const formStyle = this.state.toggleFilter
      ? { display: "block" }
      : { display: "none" };

    return (
      <Fragment>
        <div className="bk-configurations-filters columns large-12 medium-12 small-12 group">
          <button
            type="button"
            className="btn"
            id="bk-configurations-filters-toggle"
            onClick={this.handleToggleFilter}
          >
            Filter
          </button>
          <form style={formStyle} className="form group">
            <ol className="row group">
              {idFilter}
              {nameKonfigurationFilter}
              {nameFirstFilter}
              {nameLastFilter}
              {wohnungsCodeFilter}
              {wohnungsStatusFilter}
            </ol>
            <button
              type="button"
              className="btn"
              onClick={this.handleResetClick}
            >
              <svg
                className="svg-inline--fa fa-times fa-w-11"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                />
              </svg>
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterLeonardo);
