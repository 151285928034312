import * as config from '../App/common/config'
import apiInstance from '../App/common/api'

const URL = config.API_URL + '/' + config.API_VERSION + '/konfigurations/'

const getList = (isMakler, api = apiInstance) => {
  const suffix = isMakler ? 'makler' : ''
  return api.authGet(URL + suffix)
}

const headAsCopy = (id, api = apiInstance) => {
  return api.authPost(URL + encodeURIComponent(id) + '/konfigurationV')
}

const head = (id, api = apiInstance) => {
  return api.authGet(URL + encodeURIComponent(id) + '/head')
}

const copy = (id, api = apiInstance) => {
  return api.authPost(URL + encodeURIComponent(id))
}

const remove = (id, api = apiInstance) => {
  return api.authDelete(URL + encodeURIComponent(id))
}

const updateStatus = (id, type, api = apiInstance) => {
  return api.authPost(URL + encodeURIComponent(id) + '/status/' + encodeURIComponent(type))
}

const updateName = (id, name, api = apiInstance) => {
  return api.authPut(URL + encodeURIComponent(id), { name })
}

const fetchReport = (id, api = apiInstance) => {
  return api.authGet(URL + encodeURIComponent(id) +
    '/report', { responseType: 'blob' })
}

export default {
  getList, remove, updateStatus, updateName, headAsCopy, copy, fetchReport, head }
