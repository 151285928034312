import React, { Component } from 'react'
import { ROOT_URL } from '../../App/common/config'

class Share extends Component {
  getShareTexts () {
    return {
      shareSubject: 'Meine Traumwohnung vom Datahouse Wohnungskonfigurator',
      shareEmailTextBeforeLink: 'Guten Tag\n\n' +
      'So sieht meine Traumwohnung vom Datahouse Wohnungskonfigurator aus.\n',
      shareEmailTextAfterLink: '\n\nFreundliche Grüsse'
    }
  }

  getShareLinks (links, shareTexts) {
    links.twitter = links.twitter === undefined ? '' : links.twitter

    return {
      facebook: 'https://www.facebook.com/sharer.php?u=' + encodeURI(links.facebook),
      // https://developers.facebook.com/docs/sharing/messenger
      fbMessenger: 'fb-messenger://share/?link=' + encodeURI(links.fbmessenger),
      // twitter cuts hashtags (anchors) in url have to encode too
      twitter: 'https://twitter.com/intent/tweet?text=' +
        '&url=' + encodeURI(links.twitter.replace(/#/g, '%23')),
      whatsapp: 'whatsapp://send?text=' + encodeURI(links.whatsapp),
      email: 'mailto:?subject=' + encodeURI(shareTexts.shareSubject) + '&body=' +
        encodeURI(shareTexts.shareEmailTextBeforeLink + links.email + shareTexts.shareEmailTextAfterLink)
    }
  }

  createShareLinks (list) {
    if (list === undefined || list.length === 0) {
      return {}
    }

    const newLinks = {}
    list.forEach(item => {
      newLinks[item.media] = ROOT_URL + '/share/' + item.token
    })

    return newLinks
  }

  render () {
    const links = this.createShareLinks(this.props.shareToken)
    const shareTexts = this.getShareTexts()
    const shareLinks = this.getShareLinks(links, shareTexts)

    return (
      <div className='content'>
        <h1>Konfiguration teilen</h1>
        <p>
          <a href={shareLinks.email} className='m-r-lg' target='_self'>
            <i className='fa fa-envelope is-size-2' />
          </a>
          <a href={shareLinks.facebook} className='is-hidden-touch m-r-lg' target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-facebook-square is-size-2' />
          </a>
          <a href={shareLinks.twitter} className='is-hidden-touch m-r-lg' target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-twitter-square is-size-2' />
          </a>
          <a href={shareLinks.whatsapp} className='is-hidden-desktop m-r-lg' target='_self'>
            <i className='fa fa-whatsapp is-size-2' />
          </a>
          <a href={shareLinks.fbMessenger} className='is-hidden-desktop m-r-lg' target='_self'>
            <i className='fa fa-facebook-square is-size-2' />
          </a>
        </p>
      </div>
    )
  }
}

export default Share
