import apiInstance from '../api'
import * as config from '../config'

const OPTIONEN_URL = config.API_URL + '/' + config.API_VERSION + '/optionen/kategorien'

const fetchStaticVariations = (kategorieClasses, api = apiInstance) => {
  return api.get(OPTIONEN_URL + '?kategorieClasses=' + kategorieClasses)
}

export default {
  fetchStaticVariations
}
