import apiInstance from '../api'
import * as config from '../config'

const WOHNUNG_URL = config.API_URL + '/' + config.API_VERSION + '/wohnungen/'
const WOHNUNGS_GRUNDRISS_URL = config.API_URL + '/' + config.API_VERSION + '/wohnungsgrundrisse/'

const getListByWohnungId = (id, api = apiInstance) => {
  return api.get(WOHNUNG_URL + encodeURIComponent(id) + '/wohnungsgrundrisse')
}

const getListByWohnungsgrundrissId = (id, api = apiInstance) => {
  return api.get(WOHNUNGS_GRUNDRISS_URL + encodeURIComponent(id) + '/wohnungsgrundrisse')
}

const getWohnungsgrundriss = (id, api = apiInstance) => {
  return api.get(WOHNUNGS_GRUNDRISS_URL + encodeURIComponent(id))
}

export default {
  getListByWohnungId,
  getListByWohnungsgrundrissId,
  getWohnungsgrundriss
}
