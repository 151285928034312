import { combineReducers } from 'redux'
import * as actionType from './actionType'
import { extractDropdownItems, extractMinRange, extractMaxRange } from '../App/common/helper'

export function listReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_LIST:
      return [...action.payload]
    default:
      return state
  }
}

export function filteredListReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_FILTERED_LIST:
      return [...action.payload]
    default:
      return state
  }
}

export const initialFilterState = {
  availableGebaeudeCode: [],
  availableAnzZimmer: [],
  availableMinAnzZimmer: 0,
  availableMaxAnzZimmer: 1,
  availableEtageNummer: [],
  availableAusrichtungDisplay: [],
  availableMinPreis: 0,
  availableMaxPreis: 1,
  gebaeudeCode: -1,
  anzZimmer: -1,
  minAnzZimmer: 0,
  maxAnzZimmer: 1,
  minPreis: 0,
  maxPreis: 1,
  etageNummer: -1,
  ausrichtungDisplay: -1
}

export function filterReducer (state = initialFilterState, action) {
  switch (action.type) {
    case actionType.FILTER_INIT:
      const availableGebaeudeCode = extractDropdownItems(action.payload, 'gebaeudeCode')
      const availableMinPreis = extractMinRange(action.payload, 'preis')
      const availableMaxPreis = extractMaxRange(action.payload, 'preis')
      const availableAnzZimmer = extractDropdownItems(action.payload, 'anzZimmer')
      const availableMinAnzZimmer = extractMinRange(action.payload, 'anzZimmer')
      const availableMaxAnzZimmer = extractMaxRange(action.payload, 'anzZimmer')
      const availableEtageNummer = extractDropdownItems(action.payload, 'etageNummer')
      const availableAusrichtungDisplay = extractDropdownItems(action.payload, 'ausrichtungDisplay')
      const minPreis = availableMinPreis
      const maxPreis = availableMaxPreis
      const minAnzZimmer = availableMinAnzZimmer
      const maxAnzZimmer = availableMaxAnzZimmer

      return Object.assign({}, state, {
        availableGebaeudeCode,
        availableAnzZimmer,
        availableMinAnzZimmer,
        availableMaxAnzZimmer,
        availableMinPreis,
        availableMaxPreis,
        availableEtageNummer,
        availableAusrichtungDisplay,
        minPreis,
        maxPreis,
        minAnzZimmer,
        maxAnzZimmer
      })
    case actionType.FILTER_UPDATE:
      return Object.assign({}, state, action.payload)
    case actionType.FILTER_RESET:
      return Object.assign({}, state, {
        gebaeudeCode: -1,
        anzZimmer: -1,
        minAnzZimmer: state.availableMinAnzZimmer,
        maxAnzZimmer: state.availableMaxAnzZimmer,
        minPreis: state.availableMinPreis,
        maxPreis: state.availableMaxPreis,
        etageNummer: -1,
        ausrichtungDisplay: -1
      })
    default:
      return state
  }
}

export function sortReducer (state = { field: 'gebaeudeCode', order: 'ASC' }, action) {
  switch (action.type) {
    case actionType.SORT_UPDATE:
      return Object.assign({}, state, action.payload)
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  filteredList: filteredListReducer,
  filters: filterReducer,
  sort: sortReducer
})
