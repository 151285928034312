import React from "react";
import { cssTransition } from "react-toastify";
import Translate from "../../../lib/App/common/translator/components/translate";

const Toaster = (props) => {
  const translate = (
    <Translate id="toast/konfiguration-gespeichert">
      Konfiguration gespeichert
    </Translate>
  );

  return (
    <div className="success" style={{ color: "#fff" }}>
      <div className="columns large-4 medium-4 small-4 toaster">
        <i className="far fa-check-circle fa-2x" />
      </div>
      <div className="columns large-8 medium-8 small-8">{translate}</div>
    </div>
  );
};

export default Toaster;

export const Slide = cssTransition({
  enter: "Leonardo__slide-enter--top-center",
  exit: "Leonardo__slide-exit--top-center",
  appendPosition: false,
});
