import * as actionType from './actionType'

export function fetchList (queries) {
  return { type: actionType.FETCH_LIST, payload: queries }
}

export function initFilters (wohnungList) {
  return { type: actionType.FILTER_INIT, payload: wohnungList }
}

export function updateFilters (filters) {
  return { type: actionType.FILTER_UPDATE, payload: filters }
}

export function setResetFilter () {
  return { type: actionType.FILTER_RESET }
}

export function updateSort (field, order) {
  return { type: actionType.SORT_UPDATE, payload: { field, order } }
}
