import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './../action'
import Slider from 'rc-slider'
import { displayEtageNummer } from '../../App/common/helper'
import { Dropdown } from '../../App/common/Form'

export const createSliderWithTooltip = Slider.createSliderWithTooltip
export const Range = createSliderWithTooltip(Slider.Range)

export class Filter extends Component {
  constructor (props, context) {
    super(props, context)

    this.handleAnzZimmerFilterChange = this.handleAnzZimmerFilterChange.bind(this)
    this.handlePreisFilterChange = this.handlePreisFilterChange.bind(this)
    this.handleEtageNummerFilterChange = this.handleEtageNummerFilterChange.bind(this)
    this.handleAusrichtungDisplayFilterChange = this.handleAusrichtungDisplayFilterChange.bind(this)
    this.handleGebaeudeFilterChange = this.handleGebaeudeFilterChange.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
  }

  getMarkPreis () {
    let marksPreis = {}
    marksPreis[this.props.availableMinPreis] = this.props.availableMinPreis.toLocaleString('de-CH')
    marksPreis[this.props.availableMaxPreis] = this.props.availableMaxPreis.toLocaleString('de-CH')
    return marksPreis
  }

  getMarkAnzZimmer () {
    let marksAnzZimmer = {}
    marksAnzZimmer[this.props.availableMinAnzZimmer] = this.props.availableMinAnzZimmer
    marksAnzZimmer[this.props.availableMaxAnzZimmer] = this.props.availableMaxAnzZimmer
    return marksAnzZimmer
  }

  getStepPreis () {
    return this.props.availableMaxPreis === 1 ? 1 : 5000
  }

  getStepAnzZimmer () {
    return this.props.availableMaxAnzZimmer === 1 ? 1 : 0.5
  }

  handleAnzZimmerFilterChange (value) {
    this.props.actions.updateFilters({ minAnzZimmer: value[0] })
    this.props.actions.updateFilters({ maxAnzZimmer: value[1] })
  }

  handlePreisFilterChange (value) {
    this.props.actions.updateFilters({ minPreis: value[0] })
    this.props.actions.updateFilters({ maxPreis: value[1] })
  }

  handleEtageNummerFilterChange (value) {
    this.props.actions.updateFilters({ etageNummer: value })
  }

  handleAusrichtungDisplayFilterChange (value) {
    this.props.actions.updateFilters({ ausrichtungDisplay: value })
  }

  handleGebaeudeFilterChange (value) {
    this.props.actions.updateFilters({ gebaeudeCode: value })
  }

  handleResetClick () {
    this.props.actions.setResetFilter()
  }

  render () {
    const stepPreis = this.getStepPreis()
    const stepAnzZimmer = this.getStepAnzZimmer()
    const gebaeudeCode = this.getGebaeudeDropdownIfExists()
    const marksAnzZimmer = this.getMarkAnzZimmer()
    const marksPreis = this.getMarkPreis()

    return (
      <div className='content'>
        <div className='columns'>
          <div className='column is-two-thirds-widescreen'>
            <div className='columns filters'>
              {gebaeudeCode}
              <div className='column slider'>
                <div className='field'>
                  <label className='label' htmlFor='anzZimmerRange'>Zimmer</label>
                  <div className='control default-range'>
                    <Range
                      id='anzZimmerRange'
                      allowCross={false}
                      min={this.props.availableMinAnzZimmer}
                      max={this.props.availableMaxAnzZimmer}
                      marks={marksAnzZimmer}
                      step={stepAnzZimmer}
                      onChange={this.handleAnzZimmerFilterChange}
                      value={[this.props.minAnzZimmer, this.props.maxAnzZimmer]}
                    />
                  </div>
                </div>
              </div>
              <div className='column slider'>
                <div className='field'>
                  <label className='label' htmlFor='preisRange'>Preis (CHF)</label>
                  <div className='control preis-range'>
                    <Range
                      id='preisRange'
                      allowCross={false}
                      min={this.props.availableMinPreis}
                      max={this.props.availableMaxPreis}
                      marks={marksPreis}
                      step={stepPreis}
                      onChange={this.handlePreisFilterChange}
                      value={[this.props.minPreis, this.props.maxPreis]}
                      formatLabel={value => `${value}`} />
                  </div>
                </div>
              </div>
              <div className='column is-2'>
                <Dropdown
                  id='etageNummer'
                  displayName='Geschoss'
                  pleaseSelectTitle='Alle'
                  value={this.props.etageNummer}
                  getValue={this.handleEtageNummerFilterChange}
                  optionen={this.props.availableEtageNummer}
                  formatLabel={displayEtageNummer} />
              </div>
              <div className='column is-2'>
                <Dropdown
                  id='ausrichtungDisplay'
                  displayName='Ausrichtung'
                  pleaseSelectTitle='Alle'
                  value={this.props.ausrichtungDisplay}
                  getValue={this.handleAusrichtungDisplayFilterChange}
                  optionen={this.props.availableAusrichtungDisplay} />
              </div>
            </div>
            <div className='has-text-right'>
              <button className={this.props.buttonClasses}
                onClick={this.handleResetClick}>Zurücksetzen</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getGebaeudeDropdownIfExists () {
    if (this.props.availableGebaeudeCode.length > 0) {
      return (
        <div className='column is-2'>
          <Dropdown
            id='gebaeudeCode'
            displayName='Haus'
            pleaseSelectTitle='Alle'
            value={this.props.gebaeudeCode}
            getValue={this.handleGebaeudeFilterChange}
            optionen={this.props.availableGebaeudeCode} />
        </div>
      )
    }
  }
}

Filter.propTypes = {
  buttonClasses: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

Filter.defaultProps = {
  buttonClasses: 'button'
}

export function mapStateToProps (state) {
  return { ...state.wohnungList.filters }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
