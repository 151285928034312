import { combineReducers } from 'redux'
import * as actionType from './actionType'
import { deepCopy } from '../helper'

export function konfigurationReducer (state = {}, action) {
  switch (action.type) {
    case actionType.SET:
      return Object.assign({}, action.payload)
    default:
      return state
  }
}

export function konfigurationVReducer (state = {}, action) {
  switch (action.type) {
    case actionType.SET_KONFIGURATION_V:
      const lowPrio = action.payload.lowPrio || false
      if (!lowPrio) {
        return Object.assign({}, action.payload.konfigurationV)
      }
      if (lowPrio && Object.keys(state).length === 0) {
        return Object.assign({}, action.payload.konfigurationV)
      }

      return state
    case actionType.SET_KONFIGURATION_V_READ_ONLY:
      return Object.assign({}, state, { readOnly: true })
    case actionType.SET_KONFIGURATION_V_NAME:
      return state.konfigurationId === action.payload.id
        ? Object.assign({}, state, { name: action.payload.name })
        : state
    default:
      return state
  }
}

export function listReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_VARIATION_LIST:
      return [...action.payload]
    case actionType.SET_VARIATION:
      return setVariation(deepCopy(state), action.payload)
    case actionType.SET_FRONTEND_PROPERTY:
      return setFrontendProperty(deepCopy(state), action.payload)
    default:
      return state
  }
}

export function kategorieClassesListReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_KATEGORIE_CLASSES_LIST:
      return [...action.payload]
    default:
      return state
  }
}

export function shareTokenReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_SHARE_TOKEN:
      return [...action.payload]
    default:
      return state
  }
}

export function reportReducer (state = { generatePdf: false }, action) {
  switch (action.type) {
    case actionType.FETCH_REPORT:
      return Object.assign({}, { generatePdf: true })
    case actionType.FETCH_REPORT_FINISHED:
      return Object.assign({}, { generatePdf: false })
    default:
      return state
  }
}

export default combineReducers({
  current: konfigurationReducer,
  currentV: konfigurationVReducer,
  variationList: listReducer,
  kategorieClasses: kategorieClassesListReducer,
  shareToken: shareTokenReducer,
  report: reportReducer
})

function setVariation (state, id) {
  return state.map(kategorie => {
    if (kategorie.optionen !== undefined) {
      kategorie.optionen.map(option => {
        if (option.variationen !== undefined) {
          const found = option.variationen.find(variation => variation.variationId === id)
          if (found !== undefined) {
            option.variationen.map(
              variation => {
                variation.variationId === id
                  ? (variation.selected = true)
                  : (variation.selected = false)
                return variation
              })
          }
        }
        return option
      })
    }

    kategorie.kategorien = kategorie.kategorien !== undefined
      ? setVariation(kategorie.kategorien, id) : undefined

    return kategorie
  })
}

function setFrontendProperty (state, property) {
  const variationFn = variation => property.type === 'variation' &&
    property.id === variation.variationId
    ? (variation.frontendProperty = property.frontendProperty) : undefined
  const optionFn = option => property.type === 'option' &&
    property.id === option.optionId
    ? (option.frontendProperty = property.frontendProperty)
    : option.variationen !== undefined
      ? option.variationen.map(variationFn) : undefined
  const kategorieFn = kategorie => {
    if (property.type === 'kategorie' && property.id === kategorie.kategorieId) {
      kategorie.frontendProperty = property.frontendProperty

      return kategorie
    }

    if (kategorie.optionen !== undefined) {
      kategorie.optionen.map(optionFn)
    }

    return kategorie.kategorien === undefined ? kategorie : setFrontendProperty(kategorie.kategorien, property)
  }

  state.map(kategorieFn)

  return state
}
