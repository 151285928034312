import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import translations from "./translations";

import { Router } from "react-router-dom";
import RouteHandler from "./../lib/App/common/RouteHandler";
import history from "./../lib/App/common/history";
import Header from "./common/Header/Header";
import routes from "./routes";

import { ToastContainer } from "react-toastify";
import { Slide } from "./common/Toaster/Toaster";

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      translation: translations,
      languages: [
        { name: "German Default", code: "default" },
        { name: "English", code: "en" },
      ],
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage: "default",
        onMissingTranslation: ({ translationId, languageCode }) => {
          return `missing ${translationId} in ${languageCode}`;
        },
      },
    });
  }

  render() {
    return (
      <Router history={history}>
        <RouteHandler>
          <div data-reactroot className="app">
            <Header />
            <main id="content" className="main">
              {routes}
            </main>
          </div>
          <ToastContainer closeButton={false} transition={Slide} />
        </RouteHandler>
      </Router>
    );
  }
}

export default withLocalize(Main);
