import * as actionType from './actionType'
import update from 'immutability-helper'

export const initialSetState = {
  clickable: false,
  highlighted: false,
  activeTranslationIds: {}
}

export function staticTranslatorReducer (state = initialSetState, action) {
  switch (action.type) {
    case actionType.SELECT_TRANSLATION_ID:
      return update(state, {
        activeTranslationIds: { $set: { [action.payload.translationId]: true } }
      })
    case actionType.SELECT_TRANSLATION_IDS:
      return update(state, {
        activeTranslationIds: { $set: action.payload.translationIds }
      })
    case actionType.SET_CLICKABLE:
      if (action.payload === true) {
        return Object.assign({}, state, { clickable: true, highlighted: true })
      } else {
        return Object.assign({}, state, { clickable: false })
      }
    case actionType.SET_HIGHLIGHTED:
      if (action.payload === true) {
        return Object.assign({}, state, { highlighted: true })
      } else {
        return Object.assign({}, state, { highlighted: false, clickable: false })
      }
    default:
      return state
  }
}

export default staticTranslatorReducer
