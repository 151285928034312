import { combineReducers } from 'redux'
import * as actionType from './actionType'

export function listReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_VARIATION_LIST:
      return [...action.payload]
    default:
      return state
  }
}

export default combineReducers({
  variationList: listReducer
})
