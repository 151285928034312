import * as actionType from './actionType'

export const initialSetState = null

export function staticVariationsReducer (state = initialSetState, action) {
  switch (action.type) {
    case actionType.SET_STATIC_VARIATIONS:
      return Object.assign({}, action.payload)
    default:
      return state
  }
}

export default staticVariationsReducer
