import React from 'react'
import Content from './Content'

/* eslint-disable jsx-a11y/anchor-is-valid */

export const TabHeader = props => {
  const { currentKategorie, kategorien = [], handleTabClick } = props
  return (
    <div className='tabs is-medium is-fullwidth'>
      <ul>
        {kategorien.map(
          kategorie => {
            const isActive = currentKategorie.kategorieId === kategorie.kategorieId
              ? 'is-active' : ''
            return (
              <li className={isActive} key={kategorie.kategorieId}>
                <a onClick={event => handleTabClick(event, kategorie)}>{kategorie.name}</a>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export const TabContent = props => {
  const { currentKategorie } = props

  return (
    <div className='notification is-light'>
      <Content currentKategorie={currentKategorie} />
    </div>
  )
}
