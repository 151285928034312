import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as userActions from '../../User/action'
import { Link, withRouter } from 'react-router-dom'
import Translate from '../common/translator/components/translate'
import LanguageSelector from './LanguageSelector'

/* eslint-disable jsx-a11y/anchor-is-valid */

export class NavBar extends Component {
  constructor (props, context) {
    super(props, context)

    this.handleLogoutClick = this.handleLogoutClick.bind(this)
  }

  handleLogoutClick (event) {
    event.preventDefault()
    this.props.actions.logout()
  }

  getLink (url, name, className) {
    if (url === undefined) return null
    return (
      <Link to={url} className='navbar-item is-hidden-desktop-only'>
        {name}
      </Link>
    )
  }

  isKonfigurator () {
    return this.props.history.location.pathname.search('/konfigurator') > -1
  }

  hasUser () {
    return this.props.user !== null && Object.keys(this.props.user).length > 0
  }

  isMakler (hasUser) {
    return hasUser && this.props.user.role === 'makler'
  }

  getRegister (hasUser, className) {
    return !hasUser ? this.getLink('/registrieren', <Translate id='NavBar/Registrieren'>Registrieren</Translate>, className) : null
  }

  getLogin (hasUser, className) {
    return !hasUser ? this.getLink('/anmelden', <Translate id='NavBar/Anmelden'>Anmelden</Translate>, className) : null
  }

  getKonfigurations (hasUser, className) {
    return hasUser ? this.getLink('/konfigurationen', <Translate id='NavBar/Konfigurationen'>Konfigurationen</Translate>, className) : null
  }

  getMaklerKonfigurations (isMakler, className) {
    return isMakler ? this.getLink('/konfigurationen/makler', <Translate id='NavBar/Kundenkonfigurationen'>Kundenkonfigurationen</Translate>, className) : null
  }

  getProfil (hasUser, className) {
    return hasUser ? this.getLink('/profil', <Translate id='NavBar/Profil'>Profil</Translate>, className) : null
  }

  getLogout (hasUser) {
    return hasUser
      ? <a onClick={this.handleLogoutClick} className='navbar-item logout is-hidden-desktop-only'><Translate id='NavBar/Abmelden'>Abmelden</Translate></a>
      : null
  }

  getUnsaved (hasUser, className, isButton = true) {
    const linkText = <Translate id='NavBar/KonfigurationSpeichern'>Konfiguration speichern</Translate>
    const linkUrl = '/anmelden'

    return !hasUser &&
      (this.props.konfigurationV !== undefined && Object.keys(this.props.konfigurationV).length > 0)
      ? isButton
        ? <SaveKonfigurationItem linkText={linkText} linkUrl={linkUrl} />
        : this.getLink(linkUrl, linkText, className)
      : null
  }

  getBackToKonfigurator (isKonfigurator, className) {
    return !isKonfigurator
      ? this.props.konfigurationV.frontendProperty !== undefined
        ? this.getLink(this.props.konfigurationV.frontendProperty.lastUrl, <Translate id='NavBar/ZurueckZurKonfiguration'>Zurück zur Konfiguration</Translate>, className)
        : null
      : null
  }

  render () {
    const isKonfigurator = this.isKonfigurator()
    const hasUser = this.hasUser()
    const isMakler = this.isMakler(hasUser)
    // not logged in
    const register = this.getRegister(hasUser)
    const login = this.getLogin(hasUser)
    // logged in
    const konfigurations = this.getKonfigurations(hasUser)
    const maklerKonfigurations = this.getMaklerKonfigurations(isMakler)
    const profil = this.getProfil(hasUser)
    const logout = this.getLogout(hasUser)
    const unsaved = this.getUnsaved(hasUser)
    const backToKonfigurator = this.getBackToKonfigurator(isKonfigurator)

    return (
      <nav className='navbar is-info is-fixed-top'>
        <div className='navbar-start'>
          <Link to='/' className='navbar-item is-size-5 is-hidden-desktop-only'>
            <i className='fa fa-lg fa-home m-r-sm' /> <Translate id='NavBar/Title'>Wohnungskonfigurator</Translate>
          </Link>
        </div>
        <LanguageSelector />
        <div className='navbar-end'>
          {backToKonfigurator}
          {unsaved}
          {login}
          {register}
          {konfigurations}
          {maklerKonfigurations}
          {profil}
          {logout}
        </div>
      </nav>
    )
  }
}

export const SaveKonfigurationItem = props => (
  <div className='navbar-item'>
    <Link className='button is-danger'
      title='Sie haben ungespeicherte Konfigurationen'
      to={props.linkUrl}>{props.linkText}</Link>
  </div>
)

export function mapStateToProps (state) {
  return {
    user: state.user.current,
    konfigurationV: state.konfiguration.currentV
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))
