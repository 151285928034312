import { take, fork, put, call } from 'redux-saga/effects'
import * as actionType from './actionType'
import provider from '../App/common/konfigurator/provider'
import * as wohnungsgrundrissActionType from '../App/common/wohnungsgrundriss/actionType'
import * as konfiguratorActionType from '../App/common/konfigurator/actionType'
import * as errorActionType from '../App/common/error/actionType'

export function * init (api) {
  while (true) {
    try {
      const action = yield take(actionType.INIT)
      yield put({ type: wohnungsgrundrissActionType.INIT_BY_KONFIGURATIONV_ID })
      yield put({ type: actionType.FETCH_VARIATION_LIST, payload: action.payload })
      const response = yield call(api.fetchShareTokens, action.payload.konfigurationVId)
      yield put({ type: konfiguratorActionType.SET_SHARE_TOKEN, payload: response.data })
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * fetchVariationList (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_VARIATION_LIST)
      const response = yield call(
        api.fetchVariationList, action.payload.konfigurationVId,
        action.payload.kategorieClasses, true)
      yield put({ type: actionType.SET_VARIATION_LIST, payload: response.data.kategorien })
      yield put({ type: konfiguratorActionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
    } catch (error) {
      yield put({ type: konfiguratorActionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: actionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export const sagas = [
  fork(init, provider),
  fork(fetchVariationList, provider)
]
