import { combineReducers } from "redux";
import * as actionType from "../lib/WohnungList/actionType";
import * as BueroListActionType from "./actionType";
import {
  listReducer,
  filteredListReducer,
  sortReducer,
} from "../lib/WohnungList/reducer";

const ACTIVITY_BASED = 12;
const REDUCTION_SITZUNGSZIMMER = 30;

export const initialFilterState = {
  area: 0,
  staff: 0,
  lastToggled: "staff",
  initial: true,
  buerolayout: "blds",
  "desk-share-ratio": 100,
  sitzungszimmer: "sz-intern",
  "search-area": 0,
  "search-employee": 0,
  availableGebaeudeCode: "",
  activeBuero: 0,
  rerenderIsometrie: false,
  highlightedUnits: [],
  unitsBeforeHover: [],
};

export function filterReducer(state = initialFilterState, action) {
  switch (action.type) {
    case actionType.FILTER_INIT:
      const totalArea = calculateNeededArea(state);
      const totalStaff = calculateFittingStaff(state);
      return Object.assign({}, state, {
        "search-area": totalArea,
        "search-employee": totalStaff,
      });
    case actionType.FILTER_UPDATE:
      const newState = Object.assign({}, state, action.payload);
      const updatedTotalArea = calculateNeededArea(newState);
      const updatedTotalStaff = calculateFittingStaff(newState);
      const units =
        state["search-area"] !== updatedTotalArea ||
        state["search-employee"] !== updatedTotalStaff
          ? { activeBuero: 0, highlightedUnits: [], unitsBeforeHover: [] }
          : {};
      const stateWithTotals = Object.assign(
        {},
        newState,
        {
          "search-area": updatedTotalArea,
          "search-employee": updatedTotalStaff,
        },
        units
      );
      return Object.assign({}, state, stateWithTotals);
    case actionType.FILTER_RESET:
      return Object.assign({}, state, {
        "search-area": 0,
        "search-employee": 0,
      });
    case BueroListActionType.TOGGLE_ACTIVE_BUERO:
      return Object.assign({}, state, { activeBuero: action.payload });
    case BueroListActionType.SET_HIGHLIGHTED_UNITS:
      return Object.assign({}, state, { highlightedUnits: action.payload });
    case BueroListActionType.SET_UNITS_BEFORE_HOVER:
      return Object.assign({}, state, { unitsBeforeHover: action.payload });
    default:
      return state;
  }
}

const calculateFittingStaff = (state) => {
  if (state.lastToggled === "staff") {
    return Number(state.staff);
  }

  const baseAreaDivider = ACTIVITY_BASED;
  let area =
    state.buerolayout === "blds"
      ? state.area / (state["desk-share-ratio"] / 100)
      : state.area;

  if (state.sitzungszimmer === "sz-extern") {
    const multiplier = state.area / 550;
    const areaReduction = REDUCTION_SITZUNGSZIMMER * multiplier;
    area += areaReduction;
  }

  return Number(area / baseAreaDivider);
};

const calculateNeededArea = (state) => {
  if (state.lastToggled !== "staff") {
    return Number(state.area);
  }
  const baseAreaPerStaff = ACTIVITY_BASED;
  const baseAreaPerStaffDsr =
    state.buerolayout === "blds"
      ? baseAreaPerStaff * (state["desk-share-ratio"] / 100)
      : baseAreaPerStaff;

  let resultArea = baseAreaPerStaffDsr * state.staff;

  if (state.sitzungszimmer === "sz-extern") {
    const multiplier = resultArea / 550;
    const areaReduction = REDUCTION_SITZUNGSZIMMER * multiplier;
    resultArea -= areaReduction;
  }

  return resultArea;
};

export default combineReducers({
  list: listReducer,
  filteredList: filteredListReducer,
  filters: filterReducer,
  sort: sortReducer,
});
