import { combineReducers } from 'redux'
import * as actionType from './actionType'

export const initialSetState = {
  gebaeudeCode: undefined,
  wohnungsCodeReadable: undefined,
  etageNummer: undefined,
  bezeichnung: undefined,
  wohnungStatus: undefined,
  wohnungsgrundrissId: -1,
  wohnungId: undefined,
  isDefault: undefined,
  beschreibung: undefined,
  grundrissName: undefined,
  flaeche: undefined,
  flaecheAussen: undefined,
  anzZimmer: undefined,
  preis: undefined,
  imagePath: undefined
}

export function setReducer (state = initialSetState, action) {
  switch (action.type) {
    case actionType.SET:
      return Object.assign({}, action.payload)
    default:
      return state
  }
}

export function setListReducer (state = [initialSetState], action) {
  switch (action.type) {
    case actionType.SET_LIST:
      return [...action.payload]
    default:
      return state
  }
}

export default combineReducers({
  current: setReducer,
  list: setListReducer
})
