import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../action'
import { Link } from 'react-router-dom'
import Hero from '../../App/common/Hero'

export class Aktivierung extends Component {
  constructor (props, context) {
    super(props, context)

    const token = this.props.match.params.activationToken
    this.props.actions.activate(token)
  }

  getMessage () {
    return this.props.user === null
      ? <p>Einen Augenblick Bitte, Ihre Aktivierung erfolgt.</p>
      : <p>Die Aktivierung war erfolgreich Sie werden in wenigen Sekunden weitergeleitet. <Link to='/'>Weiter</Link></p>
  }

  getError () {
    return this.props.feedback.activation === false
      ? <p className='help is-danger is-size-5'>Es ist ein Fehler aufgetretten.</p>
      : null
  }

  render () {
    const message = this.getMessage()
    const error = this.getError()
    return (
      <div>
        <Hero
          title='Aktivierung'
          description='Sie haben Ihr Benutzerkonto erfolgreich aktiviert und können nun Konfigurationen abspeichern und später wieder aufrufen.' />
        <div className='section'>
          <div className='container'>
            <div className='content'>
              <div className='columns'>
                <div className='column is-half is-offset-one-quarter'>
                  {error}
                  {message}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return { user: state.user.current, feedback: state.user.feedback }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Aktivierung)
