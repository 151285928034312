import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { NumberFormat } from '../../App/common/ValueFormatter'
import { displayEtageNummer } from '../../App/common/helper'

export class Table extends Component {
  componentWillReceiveProps (nextProps) {
    if (this.props.hasGebaeudeCode !== nextProps.hasGebaeudeCode) {
      this.setDefaultSort(nextProps.hasGebaeudeCode)
    }
  }

  setDefaultSort (hasGebaeudeCode) {
    if (!hasGebaeudeCode) {
      this.props.actions.updateSort('anzZimmer', 'ASC')
    } else {
      this.props.actions.updateSort('gebaeudeCode', 'ASC')
    }
  }

  initRows () {
    return this.props.rows || []
  }

  getGebaeudeCode (label, title, sort) {
    return this.props.hasGebaeudeCode ? this.getTableTitle(label, title, sort) : undefined
  }

  getTableTitle (label, title, sort) {
    const { sortIcon, sortOrder } = sort.field === label
      ? sort.order === 'ASC'
        ? { sortIcon: <i className='fa fa-sort-amount-asc' />, sortOrder: 'DESC' }
        : { sortIcon: <i className='fa fa-sort-amount-desc' />, sortOrder: 'ASC' }
      : { sortIcon: null, sortOrder: 'ASC' }
    return (
      <th>
        <span className='is-clickable'
          onClick={() => this.props.actions.updateSort(label, sortOrder)}>{title} {sortIcon}</span>
      </th>
    )
  }

  render () {
    const { sort } = this.props
    const rows = this.initRows()
    const gebaeudeCode = this.getGebaeudeCode('gebaeudeCode', 'Haus', sort)
    const anzZimmer = this.getTableTitle('anzZimmer', 'Zimmer', sort)
    const bezeichnung = this.getTableTitle('bezeichnung', 'Bezeichnung', sort)
    const flaeche = this.getTableTitle('flaeche', 'Wohnfläche', sort)
    const etageNummer = this.getTableTitle('etageNummer', 'Geschoss', sort)
    const ausrichtungDisplay = this.getTableTitle('ausrichtungDisplay', 'Ausrichtung', sort)
    const preis = this.getTableTitle('preis', 'Preis (CHF)', sort)

    return (
      <table className='table is-striped is-hoverable'>
        <thead>
          <tr>
            {gebaeudeCode}
            {anzZimmer}
            {bezeichnung}
            {flaeche}
            {etageNummer}
            {ausrichtungDisplay}
            {preis}
            <th />
          </tr>
        </thead>
        <tbody>
          {rows.map(row => <Row mouseEnter={this.props.mouseEnter}
            mouseLeave={this.props.mouseLeave} key={row.wohnungId}
            {...row} hasGebaeudeCode={this.props.hasGebaeudeCode} />)}
          {rows.length === 0 ? <NoEntries hasGebaeudeCode={this.props.hasGebaeudeCode} /> : null}
        </tbody>
      </table>
    )
  }
}

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      wohnungId: PropTypes.number.isRequired,
      defaultWohnungsgrundrissId: PropTypes.number.isRequired,
      gebaeudeCode: PropTypes.string,
      anzZimmer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      flaeche: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      etageNummer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      ausrichtungDisplay: PropTypes.string.isRequired,
      bezeichnung: PropTypes.string.isRequired,
      preis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  ).isRequired,
  sort: PropTypes.object
}

Table.defaultProps = {
  sort: { field: undefined, order: undefined }
}

export default Table

export const NoEntries = props => {
  const cols = props.hasGebaeudeCode ? 7 : 6
  return (
    <tr>
      <td colSpan={cols}><strong>Keine Wohnungen gefunden.</strong></td>
    </tr>
  )
}

export class Row extends Component {
  isFree () {
    return this.props.wohnungStatus === 'frei'
  }

  getPrice (isFree) {
    return isFree ? <NumberFormat value={this.props.preis} />
      : this.props.wohnungStatus
  }

  getLastColumn (isFree) {
    return isFree
      ? <Link to={'/wohnungsgrundriss/' + this.props.defaultWohnungsgrundrissId}>
        <i className='fa fa-chevron-right' />
      </Link>
      : undefined
  }

  getGebaeudeCode () {
    return this.props.hasGebaeudeCode ? <td>{this.props.gebaeudeCode}</td> : undefined
  }

  displayEtageNummer () {
    return displayEtageNummer(this.props.etageNummer)
  }

  render () {
    const isFree = this.isFree()
    const price = this.getPrice(isFree)
    const lastColumn = this.getLastColumn(isFree)
    const gebaeudeCode = this.getGebaeudeCode()
    const etageNummer = this.displayEtageNummer()
    const dataIsometryId = this.props.wohnungsCode

    return (
      <tr onMouseEnter={() => this.props.mouseEnter(dataIsometryId)}
        onMouseLeave={() => this.props.mouseLeave(dataIsometryId)}>
        {gebaeudeCode}
        <td>{this.props.anzZimmer}</td>
        <td>{this.props.bezeichnung}</td>
        <td>{this.props.flaeche}</td>
        <td>{etageNummer}</td>
        <td>{this.props.ausrichtungDisplay}</td>
        <td>{price}</td>
        <td>{lastColumn}</td>
      </tr>
    )
  }
}
