import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../action'
import Form, { Input } from '../../App/common/Form'
import Hero from '../../App/common/Hero'
import { Link } from 'react-router-dom'

const Anmeldung = props => {
  return (
    <div>
      <Hero
        title='Anmeldung'
        description='Melden Sie sich mit Ihrem Login oder mit Ihrem Exklusiv-Zugang an.' />
      <div className='section'>
        <div className='container'>
          <div className='content'>
            <div className='columns'>
              <AnmeldungForm history={props.history} />
              <AnmeldungCodeForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Anmeldung

export class AnmeldungForm_ extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      formSend: false,
      form: {
        showError: false,
        isValid: { email: false, password: false },
        values: { email: undefined, password: undefined }
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.user !== null) {
      this.props.history.push('/')
    }
  }

  handleSubmit (event) {
    this.setState(state => ({ formSend: true }))
    super.handleSubmit(event, this.props.actions.login, this.state.form.values)
  }

  getError () {
    return this.props.feedback.login === false && this.state.formSend
      ? <p className='help is-danger is-size-5'>Ungültiges Login.</p>
      : null
  }

  getForm () {
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('email', isValid)}
          getValue={value => this.updateValue('email', value)}
          showError={this.state.form.showError}
          id='loginEmail'
          displayName='E-Mail'
          type='email'
          title='Bitte geben Sie eine gültige E-Mail Adresse ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('password', isValid)}
          getValue={value => this.updateValue('password', value)}
          showError={this.state.form.showError}
          id='loginPassword'
          displayName='Passwort'
          type='password'
          title='Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen'
          pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
          required />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary' onClick={this.handleSubmit}>Anmelden</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const error = this.getError()
    const form = this.getForm()
    return (
      <div className='column'>
        <h1>Mit E-Mail</h1>
        {error}
        {form}
        <p className='m-t-xl'><Link to='/passwort/wiederherstellen'>Passwort vergessen?</Link></p>
        <p className='m-t-sm'><Link to='/registrieren'>Haben Sie noch kein Account?</Link></p>
      </div>
    )
  }
}

export function anmeldungsFormMapStateToProps (state) {
  return {
    user: state.user.current,
    feedback: state.user.feedback
  }
}

export function anmeldungsFormMapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

export const AnmeldungForm =
  connect(anmeldungsFormMapStateToProps, anmeldungsFormMapDispatchToProps)(AnmeldungForm_)

export class AnmeldungCodeForm_ extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      formSend: false,
      form: {
        showError: false,
        isValid: { code: false },
        values: { code: undefined }
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event) {
    this.setState(state => ({ formSend: true }))
    super.handleSubmit(event, this.props.actions.login, this.state.form.values)
  }

  getError () {
    return this.props.feedback.login === false && this.state.formSend
      ? <p className='help is-danger is-size-5'>Ungülter Code.</p>
      : null
  }

  getForm () {
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('code', isValid)}
          getValue={value => this.updateValue('code', value)}
          showError={this.state.form.showError}
          id='loginiCode'
          displayName='Zugangscode'
          title='Bitte geben Sie einen gültigen Zugangscode ein'
          required />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary' onClick={this.handleSubmit}>Anmelden</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const form = this.getForm()
    const error = this.getError()
    return (
      <div className='column'>
        <h1>Mit Exklusiv-Zugang</h1>
        {error}
        {form}
      </div>
    )
  }
}

export function anmeldungCodeFormMapStateToProps (state) {
  return { feedback: state.user.feedback }
}

export function anmeldungCodeFormMapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

export const AnmeldungCodeForm =
  connect(anmeldungCodeFormMapStateToProps, anmeldungCodeFormMapDispatchToProps)(AnmeldungCodeForm_)
