export const SET_LIST = 'KONFIGURATION_LIST_SET'
export const SET_FILTERED_LIST = 'KONFIGURATION_LIST_FILTERED_SET'
export const FETCH_LIST = 'KONFIGURATION_LIST_FETCH'
export const FETCH_REPORT = 'KONFIGURATOR_FETCH_KONFIGURATION_REPORT'
export const FETCH_REPORT_FINISHED = 'KONFIGURATOR_FETCH_KONFIGURATION_REPORT_FINISHED'

export const FILTER_INIT = 'KONFIGURATION_LIST_FILTER_INIT'
export const FILTER_UPDATE = 'KONFIGURATION_LIST_FILTER_UPDATE'
export const FILTER_UPDATE_WITHOUT_AVAILABLE = 'KONFIGURATION_LIST_FILTER_UPDATE_WITHOUT_AVAILABLE'
export const FILTER_RESET = 'KONFIGURATION_LIST_FILTER_RESET'

export const GOTO = 'KONFIGURATION_LIST_GOTO_KONFIGURATION'
export const GOTO_READ_ONLY = 'KONFIGURATION_LIST_GOTO_READ_ONLY_KONFIGURATION'
export const COPY = 'KONFIGURATION_LIST_COPY_KONFIGURATION'
export const REMOVE = 'KONFIGURATION_LIST_REMOVE_KONFIGURATION'
export const UPDATE_NAME_KONFIGURATION = 'KONFIGURATION_LIST_UPDATE_NAME_KONFIGURATION'

export const UPDATE_STATUS = 'KONFIGURATION_LIST_UPDATE_STATUS_KONFIGURATION'
export const SET_STATUS = 'KONFIGURATION_LIST_SET_STATUS_KONFIGURATION'
