import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Translate from "../../../../lib/App/common/translator/components/translate";

export class KonfiguratorNavigation extends Component {
  getNext() {
    return this.props.nextLink !== undefined &&
      this.props.nextTitle !== undefined
      ? {
          title: this.props.nextTitle,
          link: this.props.nextLink,
          onClick: this.props.nextOnClick,
        }
      : undefined;
  }

  getPrevious() {
    return this.props.previousLink !== undefined &&
      this.props.previousTitle !== undefined
      ? {
          title: this.props.previousTitle,
          link: this.props.previousLink,
          onClick: this.props.previousOnClick,
        }
      : undefined;
  }

  getNavItems() {
    const next = this.getNext();
    const previous = this.getPrevious();
    return [previous, next];
  }

  render() {
    const navItems = this.getNavItems();

    return (
      <fieldset className="bk-options-next group row">
        {navItems.map((item, index) =>
          item !== undefined ? (
            <NavItem key={index} {...item} />
          ) : (
            <EmptyNavItem key={index} />
          )
        )}
      </fieldset>
    );
  }
}

KonfiguratorNavigation.propTypes = {
  nextLink: PropTypes.string,
  nextTitle: PropTypes.string,
  nextOnClick: PropTypes.func,
  nextOnClickValue: PropTypes.any,
  previousLink: PropTypes.string,
  previousTitle: PropTypes.string,
  previousOnClick: PropTypes.func,
  previousOnClickValue: PropTypes.any,
};

export default KonfiguratorNavigation;

export const EmptyNavItem = (props) => (
  <div className="group columns large-6 medium-6 small-12">&nbsp;</div>
);

export class NavItem extends Component {
  getClickHander() {
    return (event) => {
      event.preventDefault();
      this.props.onClick(this.props.link, this.props.value);
    };
  }

  getClickable(handleClick) {
    const translateTitle = (
      <Translate
        id={"KonfiguratorNavigation/" + this.props.title.replace(" ", "-")}
      >
        {this.props.title}
      </Translate>
    );

    return this.props.onClick !== undefined ? (
      <a className="btn" onClick={handleClick}>
        {translateTitle}
      </a>
    ) : (
      <Link className="btn" to={this.props.link}>
        {translateTitle}
      </Link>
    );
  }

  render() {
    const handleClick = this.getClickHander();
    const clickable = this.getClickable(handleClick);

    return (
      <div className="group columns large-6 medium-6 small-12">{clickable}</div>
    );
  }
}
