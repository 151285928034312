import React, { Component } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

/**
 * Abstract Class please extend. This class has some helper methods
 */
class Form extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = { form: { showError: false, isValid: {}, values: {} } }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /**
   * Override handleSubmit and call it with the action method
   */
  handleSubmit (event, action, formValues) {
    event.preventDefault()
    const isValid = this.isValid()
    if (isValid) {
      action(formValues)
    }

    this.setState(state => update(state, { form: { showError: { $set: !isValid } } }))
  }

  isValid () {
    const { isValid: isValidList } = this.state.form
    return Object.keys(isValidList).reduce((isValid, current) => isValid && isValidList[current], true)
  }

  updateIsValid (field, isValid) {
    this.setState(state => update(state, { form: { isValid: { [field]: { $set: isValid } } } }))
  }

  updateValue (field, value) {
    this.setState(state => update(state, { form: { values: { [field]: { $set: value } } } }))
  }

  patternValueEscape (patternValue) {
    const pattern = patternValue || ''
    return pattern.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  /**
   * Override render
   */
  render () {
    return (<div />)
  }
}

export default Form

export class Input extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      value: props.value,
      showError: props.showError,
      pattern: props.pattern,
      isValid: false
    }
    this.inputRef = undefined
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handlePatternChange = this.handlePatternChange.bind(this)
    this.isValid = this.isValid.bind(this)
  }

  componentDidMount () {
    this.setState(state => ({ isValid: this.isValid() }))
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.showError === true && !this.state.isValid) {
      this.setState(state => ({ showError: nextProps.showError }))
    }

    if (nextProps.pattern !== this.state.pattern) {
      this.setState(state => ({ pattern: nextProps.pattern }), this.handlePatternChange)
    }

    if (nextProps.value !== this.props.value) {
      this.setState(state => ({ value: nextProps.value }), this.isValid)
    }
  }

  handlePatternChange () {
    const isValid = this.isValid()
    if (this.state.value !== undefined && this.state.value !== '') {
      this.setState(state => ({ isValid, showError: !isValid }))
    }
  }

  handleChange (event) {
    const value = event.target.value
    const isValid = this.isValid(value)
    this.props.getValue(value)
    if (this.state.showError && isValid) {
      this.setState(state => ({ value, isValid, showError: false }))
    } else {
      this.setState(state => ({ value, isValid }))
    }
  }

  handleBlur () {
    const showError = !this.state.isValid
    this.setState(state => ({ showError }))
  }

  isValid () {
    const isValid = this.inputRef.checkValidity()
    this.props.isValid(isValid)
    return isValid
  }

  getError () {
    return this.state.showError
      ? <p className={this.props.errorClass}>{this.props.title}</p> : undefined
  }

  render () {
    const error = this.getError()
    return (
      <div className={this.props.fieldClass}>
        <label className='label' htmlFor={this.props.id}>{this.props.displayName}</label>
        <div className='control'>
          <input
            ref={input => (this.inputRef = input)}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            className='input'
            type={this.props.type}
            id={this.props.id}
            name={this.props.id}
            pattern={this.state.pattern}
            title={this.props.title}
            required={this.props.required}
            disabled={this.props.disabled}
            value={this.state.value} />
          {error}
        </div>
      </div>
    )
  }
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  pattern: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  fieldClass: PropTypes.string,
  errorClass: PropTypes.string
}

Input.defaultProps = {
  type: 'text',
  required: false,
  showError: false,
  disabled: false,
  title: '',
  value: '',
  isValid: isValid => {},
  fieldClass: 'field',
  errorClass: 'help is-danger'
}

export class Dropdown extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      value: props.value,
      showError: props.showError,
      isValid: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidMount () {
    this.setState(state => ({ isValid: this.isValid(this.state.value) }))
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.showError === true && !this.state.isValid) {
      this.setState(state => ({ showError: nextProps.showError }))
    }

    if (nextProps.value !== this.props.value) {
      this.setState(
        state => ({ value: nextProps.value }), () => this.isValid(nextProps.value))
    }
  }

  handleChange (event) {
    const value = event.target.value
    const isValid = this.isValid(value)
    this.props.getValue(value)
    if (this.state.showError && isValid) {
      this.setState(state => ({ value, isValid, showError: false }))
    } else {
      this.setState(state => ({ value, isValid }))
    }
  }

  handleBlur () {
    const showError = !this.state.isValid
    this.setState(state => ({ showError }))
  }

  isValid (value) {
    const isValid = this.props.required &&
      value + '' !== this.props.pleaseSelectValue + ''
    this.props.isValid(isValid)
    return isValid
  }

  getError () {
    return this.state.showError
      ? <p className={this.props.errorClass}>{this.props.title}</p> : undefined
  }

  getFormatLabel () {
    return typeof this.props.formatLabel === 'function' ? this.props.formatLabel : v => v
  }

  render () {
    const error = this.getError()
    const formatLabel = this.getFormatLabel()
    return (
      <div className={this.props.fieldClass}>
        <label className='label' htmlFor={this.props.id}>{this.props.displayName}</label>
        <div className='control'>
          <div className='select is-fullwidth'>
            <select
              id={this.props.id}
              title={this.props.title}
              name={this.props.id}
              value={this.state.value}
              required={this.props.required}
              onChange={this.handleChange}
              onBlur={this.handleBlur}>
              <option value={this.props.pleaseSelectValue}>{this.props.pleaseSelectTitle}</option>
              {this.props.optionen.map(option => <option key={option.value} value={option.value}>{formatLabel(option.text)}</option>)}
            </select>
          </div>
          {error}
        </div>
      </div>
    )
  }
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  pleaseSelectTitle: PropTypes.string,
  pleaseSelectValue: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  isValid: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  optionen: PropTypes.array.isRequired,
  formatLabel: PropTypes.func,
  fieldClass: PropTypes.string,
  errorClass: PropTypes.string
}

const defaultValue = -1
Dropdown.defaultProps = {
  pleaseSelectTitle: 'Bitte wählen',
  pleaseSelectValue: defaultValue,
  required: false,
  showError: false,
  disabled: false,
  title: '',
  value: defaultValue,
  isValid: isValid => {},
  optionen: [],
  fieldClass: 'field',
  errorClass: 'help is-danger'
}
