export function kategorienSwitch(kategorie, components) {
  const {
    EmphasizedKategorie,
    PackageVariationKategorie,
    PackageKategorie,
    Kategorie,
  } = components;
  if (kategorie.classes.indexOf("emphasized") > -1) {
    return EmphasizedKategorie;
  } else if (~kategorie.classes.indexOf("package_variation")) {
    return PackageVariationKategorie;
  } else if (~kategorie.classes.indexOf("package")) {
    return PackageKategorie;
  } else {
    return Kategorie;
  }
}
