import React from 'react'
import { cssTransition } from 'react-toastify'
import './toaster.css'

const Toaster = props => {
  return (
    <div className='columns is-mobile'>
      <div className='column is-one-quarters'><i className='m-t-sm fa fa-check fa-2x' /></div>
      <div className='column is-three-quarters'>Konfiguration gespeichert</div>
    </div>
  )
}

export default Toaster

export const Slide = cssTransition({
  enter: 'lib-konfigurator__slide-enter--top-center',
  exit: 'lib-konfigurator__slide-exit--top-center',
  duration: 350,
  appendPosition: false
})
