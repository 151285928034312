import React from 'react'
import Collapsable, { CollapsableItem } from '../../collapsable/Collapsable'
import Rendering from './Rendering'
import { TreeableKategorie, getKategorieRendering, getKategorieSize } from './Kategorien'
import OptionenSwitch from './Optionen'
import VariationenSwitch from './Variationen'
import Translate from '../../translator/components/translate'

class CollapsableKategorie extends TreeableKategorie {
  render () {
    const subKategorien = this.getSubKategorien(this.props)
    const optionen = <CollapsableOptionen {...this.props} />
    return this.props.isSubset
      ? <CollapsableSubsetKategorie {...this.props} kategorien={subKategorien} optionen={optionen} />
      : <CollapsableRootKategorie {...this.props} kategorien={subKategorien} optionen={optionen} />
  }
}

export default CollapsableKategorie

const CollapsableOptionen = props => {
  const { optionen } = props
  if (optionen === undefined || optionen.length === 0) {
    return null
  }

  const ownOptionComponent = CollapsableOption
  return <OptionenSwitch {...props} ownOptionComponent={ownOptionComponent} firstOption={optionen[0]} />
}

const CollapsableRootKategorie = props => {
  const rendering = getKategorieRendering(props, Rendering)
  const size = getKategorieSize(props)

  return (
    <div className='box m-b-lg'>
      <h1 className='title'><Translate id={'kategorie/' + props.kategorieCode + '/name'}>{props.name}</Translate></h1>
      <div className='columns'>
        <div className={'column' + size}>
          <p>{props.beschreibung &&
          <Translate id={'kategorie/' + props.kategorieCode + '/beschreibung'}>{props.beschreibung}</Translate>
          }</p>
          <Collapsable>
            {props.kategorien}
            {props.optionen}
          </Collapsable>
        </div>
        {rendering}
      </div>
    </div>
  )
}

CollapsableRootKategorie.defaultProps = {
  rendering: { imagePath: undefined }
}

const CollapsableSubsetKategorie = props => {
  const rendering = getKategorieRendering(props, Rendering)
  const size = getKategorieSize(props)

  return (
    <div className='m-b-lg'>
      <div className='columns'>
        <div className={'column' + size}>
          <h1 className='subtitle'><Translate id={'kategorie/' + props.kategorieCode + '/name'}>{props.name}</Translate>
          </h1>
          <p>{props.beschreibung &&
          <Translate id={'kategorie/' + props.kategorieCode + '/beschreibung'}>{props.beschreibung}</Translate>}</p>
          <Collapsable>
            {props.kategorien}
            {props.optionen}
          </Collapsable>
        </div>
        {rendering}
      </div>
    </div>
  )
}

const CollapsableOption = props => {
  const classes = props.classes || []
  const isOpen = props.frontendProperty.isOpen !== undefined
    ? props.frontendProperty.isOpen
    : classes.indexOf('default_open') > -1
  const handleIsOpen = () =>
    props.handleFrontendPropertyChange({ isOpen: !isOpen }, 'option', props.optionId, props.frontendProperty)
  return (
    <CollapsableItem key={props.optionId} isOpen={isOpen} isOpenHandle={handleIsOpen}
      title={<Translate id={'optionen/' + props.optionCode + '/name'}>{props.name}</Translate>}>
      <div className='variationen'>
        <div className='variation-header'>{props.beschreibung && <Translate
          id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate>}</div>
        <VariationenSwitch {...props} />
      </div>
    </CollapsableItem>
  )
}

CollapsableOption.defaultProps = {
  frontendProperty: { isOpen: undefined },
  firstOption: { optionId: undefined }
}
