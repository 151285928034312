import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './../action'
import Form, { Input, Dropdown } from '../../App/common/Form'

export class Filter extends Form {
  constructor (props, context) {
    super(props, context)

    this.handleIdFilterChange = this.handleIdFilterChange.bind(this)
    this.handleNameFirstFilterChange = this.handleNameFirstFilterChange.bind(this)
    this.handleNameLastFilterChange = this.handleNameLastFilterChange.bind(this)
    this.handleNameKonfigurationFilterChange = this.handleNameKonfigurationFilterChange.bind(this)
    this.handleWohnungsCodeFilterChange =
      this.handleWohnungsCodeFilterChange.bind(this)
    this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this)
    this.handleResetClick = this.handleResetClick.bind(this)
  }

  handleIdFilterChange (value) {
    this.props.actions.updateFilters({ id: value })
  }

  handleNameFirstFilterChange (value) {
    this.props.actions.updateFilters({ nameFirst: value })
  }

  handleNameLastFilterChange (value) {
    this.props.actions.updateFilters({ nameLast: value })
  }

  handleNameKonfigurationFilterChange (value) {
    this.props.actions.updateFilters({ name: value })
  }

  handleWohnungsCodeFilterChange (value) {
    this.props.actions.updateFilters({ wohnungsCode: value })
  }

  handleStatusFilterChange (value) {
    this.props.actions.updateFilters({ status: value })
  }

  handleResetClick () {
    this.props.actions.setResetFilter()
  }

  getIdFilter (id, handleIdFilterChange) {
    return (
      <div className='column'>
        <Input
          getValue={handleIdFilterChange}
          id='konfigurationListFilterKonfigurationId'
          value={id}
          displayName='ID' />
      </div>
    )
  }

  getFirstNameFilter (isMakler, handleNameFirstFilterChange, nameFirst) {
    return isMakler
      ? <div className='column'>
        <Input
          getValue={handleNameFirstFilterChange}
          id='konfigurationListFilterNameFirst'
          value={nameFirst}
          displayName='Vorname Interessent' />
      </div> : null
  }

  getLastNameFitler (isMakler, handleNameLastFilterChange, nameLast) {
    return isMakler
      ? <div className='column'>
        <Input
          getValue={handleNameLastFilterChange}
          id='konfigurationListFilterNameLast'
          value={nameLast}
          displayName='Nachname Interessent' />
      </div> : null
  }

  getKonfigurationNameFilter (handleNameKonfigurationFilterChange, name) {
    return (
      <div className='column'>
        <Input
          getValue={handleNameKonfigurationFilterChange}
          id='konfigurationListFilterNameKonfiguration'
          value={name}
          displayName='Name Konfiguration' />
      </div>
    )
  }

  getWohnungsCodeFilter (handleWohnungsCodeFilterChange, wohnungsCode) {
    return (
      <div className='column'>
        <Input
          getValue={handleWohnungsCodeFilterChange}
          id='konfigurationListFilterWohnungsCode'
          value={wohnungsCode}
          displayName='Wohnungscode' />
      </div>
    )
  }

  getWohnungsStatusFilter (status, handleStatusFilterChange, availableStatus) {
    return (
      <div className='column'>
        <Dropdown
          id='konfigurationListFilterStatus'
          displayName='Status'
          value={status}
          getValue={handleStatusFilterChange}
          pleaseSelectValue={''}
          optionen={availableStatus} />
      </div>
    )
  }

  render () {
    const idFilter = this.getIdFilter(this.props.id, this.handleIdFilterChange)
    const nameFirstFilter = this.getFirstNameFilter(
      this.props.isMakler, this.handleNameFirstFilterChange, this.props.nameFirst)
    const nameLastFilter = this.getLastNameFitler(
      this.props.isMakler, this.handleNameLastFilterChange, this.props.nameLast)
    const nameKonfigurationFilter = this.getKonfigurationNameFilter(
      this.handleNameKonfigurationFilterChange, this.props.name)
    const wohnungsCodeFilter = this.getWohnungsCodeFilter(
      this.handleWohnungsCodeFilterChange, this.props.wohnungsCode)
    const wohnungsStatusFilter = this.getWohnungsStatusFilter(
      this.props.status, this.handleStatusFilterChange, this.props.availableStatus)

    return (
      <div className='content'>
        <div className='columns'>
          {idFilter}
          {nameFirstFilter}
          {nameLastFilter}
          {nameKonfigurationFilter}
          {wohnungsCodeFilter}
          {wohnungsStatusFilter}
        </div>
        <div>
          <button className='button is-light' onClick={this.handleResetClick}>Zurücksetzen</button>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return { ...state.konfigurationList.filters }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
