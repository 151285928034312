import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { connect } from 'react-redux'
import * as actions from './action'
import * as optionenActions from './../App/common/optionen/action'
import Hero from '../App/common/Hero'

import Filter from './components/Filter'
import Table from './components/Table'
import Isometrie from './components/Isometrie'

export class WohnungList extends Component {
  componentDidMount () {
    const queries = this.handleQueryParamFilter(queryString.parse(this.props.location.search))
    this.props.actions.fetchList(queries)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
  }

  handleQueryParamFilter (query) {
    const numbers = ['minZimmer', 'maxZimmer', 'minPreis', 'maxPreis']
    const keyMaps = { etage: 'etageNummer',
      minZimmer: 'minAnzZimmer',
      maxZimmer: 'maxAnzZimmer',
      ausrichtung: 'ausrichtungDisplay',
      gebaeude: 'gebaeudeCode' }
    return Object.entries(query)
      .map(item => numbers.indexOf(item[0]) > -1 ? [ item[0], Number(item[1]) ] : item)
      .map(item => keyMaps[item[0]] !== undefined ? [ keyMaps[item[0]], item[1] ] : item)
      .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {})
  }

  getList () {
    return this.props.list || []
  }

  handleMouseEnter (id) {
    this.refs.isometrie.openLevel(id)
  }

  handleMouseLeave (id) {
    this.refs.isometrie.closeLevel(id)
    this.refs.isometrie.closeAll(id)
  }

  render () {
    const list = this.getList()
    return (
      <div>
        <Hero
          title='Wählen Sie Ihre Traumwohnung'
          description='Stellen Sie sich in wenigen Schritten Ihr neues Zuhause zusammen: Wählen Sie unten Ihre Wohnung. Danach können Sie je nach Wohnung den Grundriss definieren, die Interieur-Linie bestimmen und mögliche Zusatzoptionen auswählen.' />
        <div className='section'>
          <div className='container'>
            <Filter buttonClasses='button is-light' />
            <div className='content'>
              <div className='columns'>
                <div className='column'>
                  <Table actions={this.props.actions} rows={list} sort={this.props.sort}
                    mouseEnter={this.handleMouseEnter} mouseLeave={this.handleMouseLeave}
                    hasGebaeudeCode={this.props.hasGebaeudeCode} />
                </div>
                <div className='column is-one-third is-hidden-touch is-hidden-desktop-only'>
                  <Isometrie ref='isometrie' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return {
    list: state.wohnungList.filteredList,
    hasGebaeudeCode: state.wohnungList.filters.availableGebaeudeCode.length > 0,
    staticVariationOptions: state.staticVariations ? state.staticVariations[0].optionen : [],
    sort: state.wohnungList.sort
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    optionenActions: bindActionCreators(optionenActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WohnungList)
