import * as config from '../App/common/config'
import apiInstance from '../App/common/api'

const URL = config.API_URL + '/' + config.API_VERSION + '/wohnungen'

const getList = (api = apiInstance) => {
  return api.get(URL)
}

export default { getList }
