import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  KonfigurationName,
  mapDispatchToProps,
} from "../../../lib/App/common/KonfigurationName";
import Translate from "../../../lib/App/common/translator/components/translate";

export const Modal = (props) => {
  const isActive = props.isActive ? " is-active" : "";
  return (
    <div className={"modal " + isActive}>
      <div className="modal-background" onClick={props.toggleModal} />
      <div className="modal-content-wrapper">
        <div className="modal-content">
          {props.children}
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={props.toggleModal}
          />
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export const ConfirmModal = (props) => {
  const translateConfirmTitle = (
    <Translate id={props.translateId + "titel"}>{props.title}</Translate>
  );
  const translateConfirmDesc = (
    <Translate
      id={props.translateId + "beschreibung"}
      data={{ name: props.name }}
    >
      {props.description}
    </Translate>
  );
  const translateConfirmSubmit = (
    <Translate id={props.translateId + "bestaetigen"}>
      {props.buttonText}
    </Translate>
  );
  const translateConfirmCancel = (
    <Translate id={props.translateId + "abbrechen"}>Abbrechen</Translate>
  );

  return (
    <Modal isActive={props.isActive} toggleModal={props.toggleModal}>
      <h2>{translateConfirmTitle}</h2>
      <p>{translateConfirmDesc}</p>
      <form className="group">
        <fieldset className="bk-options-next group row">
          <div
            className="group columns large-6 medium-6 small-12"
            style={{ textAlign: "center" }}
          >
            <button onClick={props.confirm} type="button" className="btn">
              {translateConfirmSubmit}
            </button>
          </div>
          <div
            className="group columns large-6 medium-6 small-12"
            style={{ textAlign: "center" }}
          >
            <button onClick={props.toggleModal} type="button" className="btn">
              {translateConfirmCancel}
            </button>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export const SaveKonfigurationNameModal = (props) => {
  const translateSaveTitle = (
    <Translate id="modal/speichern/titel">Konfiguration</Translate>
  );
  const translateSaveDesc = (
    <Translate id="modal/speichern/beschreibung">
      Konfiguration benennen und abspeichern.
      <br />
      Diese wird in 'Mein Konto' gespeichert.
    </Translate>
  );
  const translateSaveBtn = (
    <Translate id="modal/speichern/speichern">Speichern</Translate>
  );
  const translateSaveName = (
    <Translate id="modal/speichern/name">Speichern</Translate>
  );

  return (
    <Modal isActive={props.isActive} toggleModal={props.handleChangeNameCancel}>
      <h2>{translateSaveTitle}</h2>
      <p>{translateSaveDesc}</p>
      <form>
        <ol className="group">
          <li
            style={{ float: "none", borderStyle: "none", paddingLeft: 0 }}
            ref={(el) => {
              // https://www.crowdsync.io/blog/2018/03/22/how-to-important-inline-styles-in-react/
              // react dont support style with !important... wtf?
              if (el) {
                el.style.setProperty("padding-right", "24px", "important");
                el.style.setProperty("padding-bottom", "1rem", "important");
              }
            }}
          >
            <label className="label" htmlFor="config-name">
              {translateSaveName}
            </label>
            <input
              type="text"
              className="input"
              id="config-name"
              onChange={props.handleChangeName}
              value={props.konfigurationName}
            />
          </li>
          <li style={{ float: "none" }}>
            <button
              className="btn"
              type="button"
              onClick={props.handleChangeNameSave}
            >
              <svg
                className="svg-inline--fa fa-save fa-w-14"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="save"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
                />
              </svg>{" "}
              {translateSaveBtn}
            </button>
          </li>
        </ol>
      </form>
    </Modal>
  );
};

SaveKonfigurationNameModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  konfigurationName: PropTypes.string,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeNameCancel: PropTypes.func.isRequired,
  handleChangeNameSave: PropTypes.func.isRequired,
};

class RenameKonfigurationHighlightModal extends KonfigurationName {
  render() {
    return (
      <Fragment>
        <a onClick={this.handleChangeNameToggle}>{this.props.name}</a>
        <SaveKonfigurationNameModal
          isActive={this.state.showChangeNameForm}
          toggleModal={this.handleChangeNameToggle}
          konfigurationId={this.props.konfigurationId}
          konfigurationName={this.state.nameKonfiguration}
          handleChangeName={this.handleChangeName}
          handleChangeNameSave={this.handleChangeNameSave}
          handleChangeNameCancel={this.handleChangeNameCancel}
        />
      </Fragment>
    );
  }
}

export const RenameKonfigurationHighlightLinkModal = connect(
  undefined,
  mapDispatchToProps
)(RenameKonfigurationHighlightModal);

class RenameKonfigurationLinkBarModal extends KonfigurationName {
  render() {
    const translateSave = (
      <Translate id="Zusammenfassung/LinkBar/save">Speichern</Translate>
    );

    return (
      <Fragment>
        <button
          className="btn"
          type="button"
          onClick={this.handleChangeNameToggle}
        >
          <i className="fas fa-save" /> {translateSave}
        </button>
        <SaveKonfigurationNameModal
          isActive={this.state.showChangeNameForm}
          toggleModal={this.handleChangeNameToggle}
          konfigurationId={this.props.konfigurationId}
          konfigurationName={this.state.nameKonfiguration}
          handleChangeName={this.handleChangeName}
          handleChangeNameSave={this.handleChangeNameSave}
          handleChangeNameCancel={this.handleChangeNameCancel}
        />
      </Fragment>
    );
  }
}

export const RenameKonfigurationLinkBarButtonModal = connect(
  undefined,
  mapDispatchToProps
)(RenameKonfigurationLinkBarModal);
