import React, { Fragment } from "react";
import { Input, Dropdown } from "../../../lib/App/common/Form";
import Translate from "../../../lib/App/common/translator/components/translate";

export class InputLeonardo extends Input {
  getError() {
    const translateId = this.props.translateId
      ? this.props.translateId + this.props.id
      : this.props.id;
    const translateError = this.props.translateId ? (
      <Translate id={translateId + "/error"}>{this.props.title}</Translate>
    ) : (
      this.props.title
    );

    return this.state.showError ? (
      <div className={this.props.errorClass}>
        <i className="fas fa-bell" /> {translateError}
      </div>
    ) : null;
  }

  render() {
    const error = this.getError();
    const required = this.props.required ? <em>*</em> : null;

    const translateId = this.props.translateId
      ? this.props.translateId + this.props.id
      : this.props.id;
    const translateName = this.props.displayName ? (
      <Translate id={translateId}>{this.props.displayName}</Translate>
    ) : (
      this.props.displayName
    );

    return (
      <li className={this.props.fieldClass}>
        <label htmlFor={this.props.id}>
          {translateName} {required}
        </label>
        <input
          ref={(input) => (this.inputRef = input)}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          type={this.props.type}
          id={this.props.id}
          name={this.props.id}
          pattern={this.state.pattern}
          title={this.props.title}
          required={this.props.required}
          disabled={this.props.disabled}
          value={this.state.value}
        />
        {error}
      </li>
    );
  }
}

Input.defaultProps = {
  type: "text",
  required: false,
  showError: false,
  disabled: false,
  title: "",
  value: "",
  isValid: (isValid) => {},
  fieldClass: "",
  errorClass: "error",
};

export class DropdownLeonardo extends Dropdown {
  getError() {
    const translateId = this.props.translateId
      ? this.props.translateId + this.props.id
      : this.props.id;
    const translateError = this.props.translateId ? (
      <Translate id={translateId + "/error"}>{this.props.title}</Translate>
    ) : (
      this.props.title
    );

    return this.state.showError && this.props.required ? (
      <div className={this.props.errorClass}>
        <i className="fas fa-bell" /> {translateError}
      </div>
    ) : null;
  }

  render() {
    const error = this.getError();
    const formatLabel = this.getFormatLabel();

    return (
      <Fragment>
        <div className="custom-select">
          <select
            id={this.props.id}
            title={this.props.title}
            name={this.props.id}
            value={this.state.value}
            required={this.props.required}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
          >
            <option value={this.props.pleaseSelectValue}>
              {this.props.pleaseSelectTitle}
            </option>
            {this.props.optionen.map((option) => (
              <option key={option.value} value={option.value}>
                {formatLabel(option.text)}
              </option>
            ))}
          </select>
        </div>
        {error}
      </Fragment>
    );
  }
}
