import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionType from '../../KonfigurationList/actionType'

const actions = {
  updateNameKonfiguration: (id, name) => {
    return { type: actionType.UPDATE_NAME_KONFIGURATION, payload: { id, name } }
  }
}

export class KonfigurationName extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      showChangeNameForm: false,
      nameKonfiguration: props.name
    }

    this.handleChangeNameToggle = this.handleChangeNameToggle.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeNameSave = this.handleChangeNameSave.bind(this)
    this.handleChangeNameCancel = this.handleChangeNameCancel.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    this.setState(state => ({ nameKonfiguration: nextProps.name }))
  }

  handleChangeNameToggle () {
    this.setState(state => ({ showChangeNameForm: !this.state.showChangeNameForm }))
  }

  handleChangeName (event) {
    const value = event.target.value
    this.setState(state => ({ nameKonfiguration: value }))
  }

  handleChangeNameSave () {
    this.props.actions.updateNameKonfiguration(
      this.props.konfigurationId, this.state.nameKonfiguration)
    this.setState(state => ({ showChangeNameForm: !this.state.showChangeNameForm }))
  }

  handleChangeNameCancel () {
    this.setState(state => ({
      nameKonfiguration: this.props.name,
      showChangeNameForm: !this.state.showChangeNameForm
    }))
  }

  render () {
    const isNameKonfigurationHidden = this.state.showChangeNameForm ? ' is-hidden' : ''
    const isNameKonfigurationFormHidden = !this.state.showChangeNameForm ? 'is-hidden' : ''
    const isCancelNameKonfigurationInvisibility = this.state.nameKonfiguration.length === 0
      ? ' is-invisible' : ''

    return (
      <div>
        <span onClick={this.handleChangeNameToggle}
          className={'is-clickable' + isNameKonfigurationHidden}>{this.state.nameKonfiguration}</span>
        <span className={'inline-edit ' + isNameKonfigurationFormHidden}>
          <input type='text' onChange={this.handleChangeName} value={this.state.nameKonfiguration} />
          <i onClick={this.handleChangeNameSave}
            className={'fa fa-check is-clickable m-l-md is-size-5 has-text-success' +
              isCancelNameKonfigurationInvisibility} />
          <i onClick={this.handleChangeNameCancel}
            className='fa fa-times is-clickable m-l-sm is-size-5 has-text-danger' />
        </span>
      </div>
    )
  }
}

KonfigurationName.propTypes = {
  konfigurationId: PropTypes.number,
  name: PropTypes.string
}

KonfigurationName.defaultProps = {
  konfigurationId: 0,
  name: ''
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

export default connect(undefined, mapDispatchToProps)(KonfigurationName)
