import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Steps = props => {
  const { step, steps, highestStep } = props

  return (
    <div className='steps'>
      {steps.map(item => <Step key={item.step}
        isNewKonfiguration={props.isNewKonfiguration}
        wohnungsgrundrissId={props.wohnungsgrundrissId}
        konfigurationVId={props.konfigurationVId}
        highestStep={highestStep}
        currentStep={step} {...item} />)}
    </div>
  )
}

Steps.propTypes = {
  step: PropTypes.number.isRequired,
  highestStep: PropTypes.number,
  wohnungsgrundrissId: PropTypes.number,
  konfigurationVId: PropTypes.number,
  isNewKonfiguration: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    step: PropTypes.number.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.func,
    description: PropTypes.string
  }))
}

Steps.defaultProps = {
  isNewKonfiguration: false,
  steps: [
    {
      step: 1,
      icon: '1',
      title: 'Grundrisswahl',
      description: 'Bestimmen Sie Ihren Grundriss',
      link: (konfigurationVId, wohnungsgrundrissId, isNewKonfiguration) =>
        isNewKonfiguration
          ? '/wohnungsgrundriss/' + wohnungsgrundrissId
          : '/konfigurator/' + konfigurationVId + '/wohnungsgrundriss/' + wohnungsgrundrissId
    }, {
      step: 2,
      icon: '2',
      title: 'Interieur bestimmen',
      description: 'Interieur-Linie und Ausbau wählen',
      link: (konfigurationVId, wohnungsgrundrissId) => '/konfigurator/' + konfigurationVId +
        '/interieur'
    }, {
      step: 3,
      icon: '3',
      title: 'Zusatzoptionen',
      description: 'Kostenpflichtige Zusatzoptionen',
      link: (konfigurationVId, wohnungsgrundrissId) => '/konfigurator/' + konfigurationVId +
        '/zusatzoptionen'
    }, {
      step: 4,
      icon: <span className='icon'><i className='fa fa-flag' /></span>,
      title: 'Zusammenfassung',
      description: 'Ihre Wohnung auf einen Blick',
      link: (konfigurationVId, wohnungsgrundrissId) => '/konfigurator/' + konfigurationVId +
        '/zusammenfassung'
    }
  ]
}

export default Steps

const checkIcon = <span className='icon'><i className='fa fa-check' /></span>

const Step = props => {
  const { currentStep, step, icon, title, highestStep,
    description, link, konfigurationVId, wohnungsgrundrissId, isNewKonfiguration } = props
  const myStep = highestStep !== undefined && highestStep > currentStep ? highestStep : currentStep
  const isCompleted = myStep > step ? ' is-completed is-success' : ''
  const isActive = currentStep === step ? ' is-active' : ''
  const markerIcon = isCompleted !== ''
    ? <Link to={link(konfigurationVId, wohnungsgrundrissId, isNewKonfiguration)}>{checkIcon}</Link>
    : myStep >= step
      ? <Link to={link(konfigurationVId, wohnungsgrundrissId, isNewKonfiguration)}>{icon}</Link>
      : icon

  return (
    <div className={'step-item' + isCompleted + isActive}>
      <div className='step-marker'>{markerIcon}</div>
      <div className='step-details'>
        <div className='step-title'>{title}</div>
        <div>{description}</div>
      </div>
    </div>
  )
}
