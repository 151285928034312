import { combineReducers } from 'redux'
import * as actionType from './actionType'
import { extractDropdownItems } from '../App/common/helper'

export function listReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_LIST:
      return [...action.payload]
    case actionType.REMOVE:
      return [...removeItem(state, action.payload)]
    case actionType.SET_STATUS:
      return updateItem(state, action.payload.konfigurationId, action.payload.status)
    default:
      return state
  }
}

export function filteredListReducer (state = [], action) {
  switch (action.type) {
    case actionType.SET_FILTERED_LIST:
      return [...action.payload]
    case actionType.SET_STATUS:
      return updateItem(state, action.payload.konfigurationId, action.payload.status)
    default:
      return state
  }
}

export function reportReducer (state = { generatePdf: false, konfiguration: undefined }, action) {
  switch (action.type) {
    case actionType.FETCH_REPORT:
      return Object.assign({}, { generatePdf: true, konfiguration: action.payload })
    case actionType.FETCH_REPORT_FINISHED:
      return Object.assign({}, { generatePdf: false, konfiguration: undefined })
    default:
      return state
  }
}

export const intialFilterState = {
  availableId: '',
  availableNameFirst: '',
  availableNameLast: '',
  availableName: '',
  availableWohnungsCode: '',
  availableStatus: [],
  id: '',
  nameFirst: '',
  nameLast: '',
  name: '',
  wohnungsCode: '',
  status: ''
}

export function filterReducer (state = intialFilterState, action) {
  switch (action.type) {
    case actionType.FILTER_INIT:
      const availableStatus = extractDropdownItems(action.payload, 'status')
      return Object.assign({}, intialFilterState, { availableStatus })
    case actionType.FILTER_UPDATE:
      return Object.assign({}, state, action.payload)
    case actionType.FILTER_UPDATE_WITHOUT_AVAILABLE:
      const { id, nameFirst, nameLast, name, wohnungsCode, status } = action.payload
      const selectiveFilters = { id, nameFirst, nameLast, name, wohnungsCode, status }
      return Object.assign({}, state, selectiveFilters)
    case actionType.FILTER_RESET:
      return Object.assign({}, state, {
        id: '',
        nameFirst: '',
        nameLast: '',
        name: '',
        wohnungsCode: '',
        status: ''
      })
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  filteredList: filteredListReducer,
  filters: filterReducer,
  report: reportReducer
})

function removeItem (list, id) {
  return list.filter(item => item.konfigurationId !== id)
}

function updateItem (list, id, state) {
  return list.map(item => {
    if (item.konfigurationId !== id) {
      return item
    }

    return Object.assign({}, item, { status: state.status, possibleStatus: state.possibleStatus })
  })
}
