import * as actionType from './actionType'

export function fetchList (isMakler) {
  return { type: actionType.FETCH_LIST, payload: isMakler }
}

export function updateFilters (filters) {
  return { type: actionType.FILTER_UPDATE, payload: filters }
}

export function setResetFilter () {
  return { type: actionType.FILTER_RESET }
}

export function goto (konfigurationId) {
  return { type: actionType.GOTO, payload: konfigurationId }
}

export function gotoReadOnly (konfigurationId) {
  return { type: actionType.GOTO_READ_ONLY, payload: konfigurationId }
}

export function copy (konfigurationId) {
  return { type: actionType.COPY, payload: konfigurationId }
}

export function remove (id) {
  return { type: actionType.REMOVE, payload: id }
}

export function updateStatus (id, type) {
  return { type: actionType.UPDATE_STATUS, payload: { id, type } }
}

export function fetchReport (konfigurationId, filename) {
  return { type: actionType.FETCH_REPORT, payload: { konfigurationId, filename } }
}
