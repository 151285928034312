import { call, fork, take } from 'redux-saga/effects'
import * as actionType from './actionType'
import history from './../history'
import localStore from '../../localStore'

export function * error () {
  while (true) {
    const action = yield take(actionType.ERROR)
    const historyPush = history.push.bind(history)
    yield call(errorHandling, action.payload, historyPush, localStore)
  }
}

export function * errorHandling (error, historyPush, localStore_) {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 400:
        // do nothing form errors
        break
      case 401:
        const removeItemOfLocalStore = localStore_.removeItem.bind(localStore_)
        yield call(removeItemOfLocalStore, 'renewToken')
        yield call(removeItemOfLocalStore, 'accessToken')
        yield call(historyPush, '/')
        break
      case 403:
      case 404:
        yield call(historyPush, '/fehler/' + error.response.status)
        break
      default:
        yield call(historyPush, '/fehler')
        break
    }
  }
}

export const sagas = [
  fork(error)
]
