import React from 'react'
import { ReadOnlyVariation, ReadOnlyBooleanVariation }
  from '../konfigurator/compontents/Variationen'
import Image from '../Image'
import { CurrencyFormat } from '../ValueFormatter'

const Content = props => {
  const { currentKategorie } = props
  const optionen = currentKategorie.classes.indexOf('package') > -1
    ? currentKategorie.optionen.slice(1) : currentKategorie.optionen
  const variationen = optionen !== undefined
    ? optionen.map(option => {
      if (option.classes.indexOf('boolean') > -1) {
        return <ReadOnlyBooleanVariation {...option} key={option.optionId} />
      } else if (option.classes.indexOf('text_only') > -1 || option.classes.indexOf('info') > -1) {
        return <TextOnlyVariation {...option} key={option.optionId} />
      } else if (option.classes.indexOf('number') > -1) {
        return <NumberOption {...option} key={option.optionId} />
      } else if (option.classes.indexOf('text') > -1) {
        return <TextOption {...option} key={option.optionId} />
      } else {
        const variationen = option.variationen || []
        return variationen.map(variation => {
          return <ReadOnlyVariation {...variation} key={variation.variationId} showSelected={false} />
        })
      }
    }) : null
  const subkategorien = currentKategorie.kategorien !== undefined
    ? currentKategorie.kategorien.map(
      kategorie => <Content key={kategorie.kategorieId} currentKategorie={kategorie} />)
    : null
  const rendering = currentKategorie.rendering !== undefined &&
    currentKategorie.rendering.imagePath !== undefined
    ? <Image src={currentKategorie.rendering.imagePath} alt='kategorie rendering'
      ratio='r1.60' width='1344w' tabletWidth='960w' mobileWidth='720w' />
    : null

  return (
    <div>
      <h2 className='subtitle m-t-lg'>{currentKategorie.name}</h2>
      <div className='has-text-centered m-b-md'>
        {rendering}
      </div>
      <div className='variationen'>
        <div className='variation-body'>
          {variationen}
        </div>
      </div>
      {subkategorien}
    </div>
  )
}

export default Content

const TextOnlyVariation = props => {
  const charge = props.aufpreis !== undefined && props.aufpreis !== 0
    ? <p><CurrencyFormat value={props.aufpreis} currency='CHF' /></p> : undefined
  return (
    <div className='variation-item'>
      <div className='card'>
        <div className='card-content'>
          <div className='content'>
            <p><strong>{props.name}</strong></p>
            <hr />
            <p>{props.beschreibung}</p>
            {charge}
          </div>
        </div>
      </div>
    </div>
  )
}

const NumberOption = props => {
  const charge = props.aufpreis !== undefined && props.aufpreis !== 0
    ? <p><CurrencyFormat value={props.aufpreis} currency='CHF' /></p> : undefined
  return (
    <div className='variation-item'>
      <div className='card'>
        <div className='card-content'>
          <div className='content'>
            <p><strong>{props.name}</strong></p>
            <p>{props.beschreibung}</p>
            <hr />
            {props.number.value} {props.number.unit}
            {charge}
          </div>
        </div>
      </div>
    </div>
  )
}

const TextOption = props => {
  const charge = props.aufpreis !== undefined && props.aufpreis !== 0
    ? <p><CurrencyFormat value={props.aufpreis} currency='CHF' /></p> : undefined
  return (
    <div className='variation-item'>
      <div className='card'>
        <div className='card-content'>
          <div className='content'>
            <p><strong>{props.name}</strong></p>
            <p>{props.beschreibung}</p>
            <hr />
            {props.text.value}
            {charge}
          </div>
        </div>
      </div>
    </div>
  )
}
