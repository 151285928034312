import React from "react";
import Slider from "rc-slider";
import Columns from "./Columns";
import { Tooltip } from "../../../../App/common/InfoButton/InfoButton";
import "./tooltip.css";

const stepStaff = 100;

const StaffSearch = (props) => {
  let marks = {};
  marks[props.minStaff] = props.minStaff;
  for (let i = 2 * props.minStaff; i < props.marksStaff; i += props.minStaff) {
    marks[i] = "";
  }
  marks[props.maxStaff] = props.maxStaff;

  const groupClasses =
    props.lastToggled === "area" && !props.initial ? "group disabled" : "group";

  return (
    <Columns large={6} medium={6} small={12}>
      <div className={groupClasses}>
        <label htmlFor="bk-search-staff">
          <img src={props.image} alt={"Anzahl Mitarbeiter"} />
        </label>

        <Slider
          name={"staff"}
          dots
          step={stepStaff}
          min={props.minStaff}
          max={props.maxStaff}
          value={props.staff}
          onChange={props.handleStaffChanged}
          marks={marks}
        />
        <label className={"label"} htmlFor={"bk-search-staff"}>
          <input
            type="number"
            value={props.staff}
            min={props.minStaff}
            max={props.maxStaff}
            onFocus={(event) => {
              props.handleFocusChanged(event, "staff");
            }}
            onClick={(event) => {
              props.handleFocusChanged(event, "staff");
            }}
            onChange={(event) => {
              props.handleStaffChanged(event.target.value);
            }}
            id="bk-search-staff"
            placeholder="Anzahl Mitarbeiter"
          />
          <Tooltip
            toolTip={"Suche nach Anzahl Mitarbeiter"}
            translateId={"BueroList/Search/Staff/tooltip"}
            tooltipToLeft
            outerStyle={{ display: "inline-block", top: "10px" }}
            innerStyle={{ display: "block" }}
          />
        </label>
      </div>
    </Columns>
  );
};

export default StaffSearch;
