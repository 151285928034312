import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../action'
import Form, { Input } from '../../App/common/Form'
import Hero from '../../App/common/Hero'

export const PasswortZuruecksetzung = props => {
  return (
    <div>
      <Hero
        title='Passwort vergessen'
        description='Sie können nun ein neues Passwort setzen.' />
      <div className='section'>
        <div className='container'>
          <div className='content'>
            <div className='columns' />
            <PasswortZuruecksetzungForm match={props.match} history={props.history} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswortZuruecksetzung

export class PasswortZuruecksetzungForm_ extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      form: {
        showError: false,
        isValid: { password: false, passwordConfirm: false },
        values: { password: undefined, passwordConfirm: undefined }
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.user !== null) {
      this.props.history.push('/')
    }
  }

  handleSubmit (event) {
    super.handleSubmit(
      event, this.props.actions.resetPassword,
      Object.assign({},
        this.state.form.values,
        { token: this.props.match.params.passwordResetToken }))
  }

  getError () {
    return this.props.feedback.passwordReset === false
      ? <p className='help is-danger is-size-5'>Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben.</p>
      : null
  }

  getForm () {
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('password', isValid)}
          getValue={value => this.updateValue('password', value)}
          showError={this.state.form.showError}
          id='passwordResetPassword'
          displayName='Neues Passwort'
          type='password'
          title='Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen'
          pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
          required />
        <Input
          isValid={isValid => this.updateIsValid('passwordConfirm', isValid)}
          getValue={value => this.updateValue('passwordConfirm', value)}
          showError={this.state.form.showError}
          id='passwordResetPasswordConfirm'
          displayName='Neues Passwort bestätigen'
          type='password'
          title='Das Passwort stimmt nicht überein'
          pattern={'^' + this.patternValueEscape(this.state.form.values.password) + '$'}
          required />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary' onClick={this.handleSubmit}>Zurücksetzen</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const error = this.getError()
    const form = this.getForm()
    return (
      <div className='column is-half is-offset-one-quarter'>
        {error}
        {form}
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return {
    user: state.user.current,
    feedback: state.user.feedback
  }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

const PasswortZuruecksetzungForm =
  connect(mapStateToProps, mapDispatchToProps)(PasswortZuruecksetzungForm_)
