import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber, FormattedDate } from 'react-intl'

export const IntlFormats = {
  number: {
    CHF: {
      style: 'currency',
      currency: 'CHF'
    },
    USD: {
      style: 'currency',
      currency: 'USD'
    },
    EUR: {
      style: 'currency',
      currency: 'EUR'
    },
    default: {
      style: 'decimal'
    }
  }
}

export const CurrencyFormat = props => {
  // changed this because FormattedNumber with format put two decimal places to the value
  return (
    <span>{props.currency} <NumberFormat value={props.value} /></span>
  )
}

CurrencyFormat.defaultProps = { value: 0, currency: 'CHF' }

CurrencyFormat.propTypes = {
  value: PropTypes.number,
  // e.g. USD, EUR, CHF
  currency: PropTypes.string.isRequired
}

export const DateFormat = props => (
  <FormattedDate value={props.value} />
)

DateFormat.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired
}

export const NumberFormat = props => (
  <FormattedNumber value={props.value} />
)

NumberFormat.propTypes = {
  value: PropTypes.number.isRequired
}
