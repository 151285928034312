import { take, put, call, fork, select, takeLatest } from 'redux-saga/effects'
import provider from './provider'
import * as actionType from './actionType'
import * as konfiguratorActionType from '../konfigurator/actionType'
import * as errorActionType from '../error/actionType'

export function * fetch (api, id, wohnungsgrundrissId) {
  const wohnungsgrundriss = yield select(state => state.wohnungsgrundriss)
  yield call(fetchIfNotExists, id, wohnungsgrundrissId, wohnungsgrundriss, api)
}

export function * fetchIfNotExists (id, wohnungsgrundrissId, wohnungsgrundriss, api) {
  try {
    if (
      wohnungsgrundriss.current.wohnungId === undefined ||
      wohnungsgrundriss.list.length === 0 ||
      wohnungsgrundriss.list.filter(item => item.wohnungsgrundrissId === wohnungsgrundrissId).length === 0 ||
      (wohnungsgrundriss.current.wohnungId !== id && wohnungsgrundriss.current.wohnungsgrundrissId !== id)) {
      const response = yield call(api, id)
      yield put({ type: actionType.SET_LIST, payload: response.data })
      const wohnungsgrundrissCurrent = yield call(
        filterWohnungsgrundrissOrGetDefault, response.data, wohnungsgrundrissId)
      yield put({ type: actionType.SET, payload: wohnungsgrundrissCurrent })
    }
  } catch (error) {
    yield put({ type: actionType.SET, payload: {} })
    yield put({ type: actionType.SET_LIST, payload: [] })
    yield put({ type: errorActionType.ERROR, payload: error })
  }
}

export function * fetchByWohnungId (api) {
  while (true) {
    const action = yield take(actionType.FETCH_LIST_BY_WOHNUNG_ID)
    yield call(fetch, api.getListByWohnungId, action.payload)
  }
}

export function * fetchByWohnungsgrundrissId (api) {
  while (true) {
    const action = yield take(actionType.FETCH_LIST_BY_WOHNUNG_GRUNDRISS_ID)
    yield call(fetch, api.getListByWohnungsgrundrissId, action.payload, action.payload)
  }
}

export function * fetchWohnungsgrundriss (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH)
      const response = yield call(api.getWohnungsgrundriss, action.payload)
      yield put({ type: actionType.SET, payload: response.data })
    } catch (error) {
      yield put({ type: actionType.SET, payload: {} })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * initByKonfigurationVId () {
  while (true) {
    yield take(konfiguratorActionType.SET_KONFIGURATION_V)
    const wohnungsgrundrisse = yield select(state => ({
      wohnungsgrundrissId: state.wohnungsgrundriss.current.wohnungsgrundrissId,
      konfigurationWohnungsgrundrissId: state.konfiguration.currentV.wohnungsgrundrissId
    }))
    if (
      wohnungsgrundrisse.wohnungsgrundrissId !==
      wohnungsgrundrisse.konfigurationWohnungsgrundrissId &&
        wohnungsgrundrisse.konfigurationWohnungsgrundrissId !== undefined) {
      yield put(
        { type: actionType.FETCH, payload: wohnungsgrundrisse.konfigurationWohnungsgrundrissId })
    }
  }
}

export function * initByKonfigurationVIdWithGrundrisse () {
  while (true) {
    const action = yield take(actionType.INIT_BY_KONFIGURATIONV_ID_WITH_WOHNUNGSGRUNDRISSE)
    const konfigurationV = yield select(state => state.konfiguration.currentV)
    yield call(initByKonfigurationVIdWithGrundrisseDecision, action, konfigurationV)
    const wohnungsgrundrissId = yield select(state => state.konfiguration.currentV.wohnungsgrundrissId)
    yield put({ type: actionType.FETCH_LIST_BY_WOHNUNG_GRUNDRISS_ID, payload: wohnungsgrundrissId })
  }
}

export function * initByKonfigurationVIdWithGrundrisseDecision (action, konfiguration) {
  if (konfiguration === undefined || Object.keys(konfiguration).length === 0) {
    yield put({ type: konfiguratorActionType.FETCH, payload: action.payload })
    yield take(konfiguratorActionType.SET_KONFIGURATION_V)
  }
}

export const sagas = [
  fork(fetchByWohnungId, provider),
  fork(fetchByWohnungsgrundrissId, provider),
  fork(fetchWohnungsgrundriss, provider),
  takeLatest(actionType.INIT_BY_KONFIGURATIONV_ID, initByKonfigurationVId),
  fork(initByKonfigurationVIdWithGrundrisse, provider)
]

function filterWohnungsgrundrissOrGetDefault (wohnungsgrundrisse, id) {
  if (id === undefined) {
    return wohnungsgrundrisse.filter(item => item.isDefault === true)[0]
  }

  const grundriss = wohnungsgrundrisse.filter(item => Number(item.wohnungsgrundrissId) === Number(id))[0]
  if (grundriss === undefined) {
    return wohnungsgrundrisse[0]
  }

  return grundriss
}
