import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CurrencyFormat } from './ValueFormatter'
import KonfigurationName from './KonfigurationName'
import { displayEtageNummer } from './helper'
import Translate from '../common/translator/components/translate'

export class Highlights extends Component {
  constructor (props, context) {
    super(props, context)

    this.handleChangeNameSave = this.handleChangeNameSave.bind(this)
  }

  handleChangeNameSave (name) {
    this.props.actions.updateNameKonfiguration(this.props.konfigurationId, name)
  }

  getWohnungsgrundrissName (wohnungsgrundrissName, showWohnungsgrundriss) {
    return wohnungsgrundrissName !== undefined && showWohnungsgrundriss
      ? <Box title='Grundriss' value={wohnungsgrundrissName} /> : null
  }

  getGebaeudeCode (gebaeudeCode) {
    return gebaeudeCode !== undefined ? <Box title={<Translate id='highlights/gebaeude'>Gebäude</Translate>} value={gebaeudeCode} /> : null
  }

  getAufpreis (aufpreis) {
    return aufpreis !== undefined ? <Box title={<Translate id='highlights/zusatzkosten'>Zusatzkosten</Translate>} value={aufpreis} isCurrency /> : null
  }

  getTotalPreis (totalpreis) {
    return totalpreis !== undefined ? <Box title={<Translate id='highlights/total'>Total</Translate>} value={totalpreis} isCurrency isHighlight /> : null
  }

  getAusstattungslinie (ausstattungslinie) {
    return ausstattungslinie !== undefined
      ? <Box title={<Translate id='highlights/ausstattungslinie'>Ausstattungslinie</Translate>} value={ausstattungslinie.name} /> : null
  }

  getEtageNummer (etageNummer) {
    return displayEtageNummer(etageNummer)
  }

  getShowSaveButton (showSaveButton, name, isButton = true) {
    const title = <Translate id='highlights/konfiguration'>Konfiguration</Translate>
    const nameComponent = this.props.editKonfigurationName
      ? <Box title={title}
        value={<KonfigurationName konfigurationId={this.props.konfigurationId} name={name} />} />
      : <Box title={title} value={name} />
    const buttonClasses = isButton ? 'button' : ''

    return showSaveButton
      ? <Box title={title}
        value={<Link className={buttonClasses} to='/anmelden'>
          <Translate id='buttons/speichern'>Speichern</Translate>
        </Link>} />
      : name !== undefined
        ? nameComponent
        : null
  }

  render () {
    const wohnungsgrundrissName = this.getWohnungsgrundrissName(this.props.wohnungsgrundrissName)
    const gebaeudeCode = this.getGebaeudeCode(this.props.gebaeudeCode)
    const aufpreis = this.getAufpreis(this.props.aufpreis)
    const totalpreis = this.getTotalPreis(this.props.totalpreis)
    const ausstattungslinie = this.getAusstattungslinie(this.props.highlights.ausstattungslinie)
    const etageNummer = this.getEtageNummer(this.props.etageNummer)
    const name = this.getShowSaveButton(this.props.showSaveButton, this.props.konfigurationName)

    return (
      <div>
        <div className='columns'>
          {gebaeudeCode}
          <div className='column'>
            <div className='has-text-centered'>
              <div className='is-uppercase'><Translate id='highlights/geschoss'>Geschoss</Translate></div>
              <div className='has-text-weight-bold is-size-3'>{etageNummer}</div>
            </div>
          </div>
          <div className='column'>
            <div className='has-text-centered'>
              <div className='is-uppercase'><Translate id='highlights/ausrichtung'>Ausrichtung</Translate></div>
              <div className='has-text-weight-bold is-size-3'>{this.props.ausrichtungDisplay}</div>
            </div>
          </div>
          {name}
        </div>
        <hr />
        <div className='columns'>
          {wohnungsgrundrissName}
          <div className='column'>
            <div className='has-text-centered'>
              <div className='is-uppercase'><Translate id='highlights/zimmer/title'>Zimmer</Translate></div>
              <div className='has-text-weight-bold is-size-3'>{this.props.anzZimmer} <Translate id='highlights/zimmer/unit'>Zimmer</Translate></div>
            </div>
          </div>
          <div className='column'>
            <div className='has-text-centered'>
              <div className='is-uppercase'><Translate id='highlights/flaeche'>Wohnfläche</Translate></div>
              <div className='has-text-weight-bold is-size-3'>{this.props.flaeche} m<sup>2</sup></div>
            </div>
          </div>
          <div className='column'>
            <div className='has-text-centered'>
              <div className='is-uppercase'><Translate id='highlights/flaecheAussen'>Aussenfläche</Translate></div>
              <div className='has-text-weight-bold is-size-3'>{this.props.flaecheAussen} m<sup>2</sup></div>
            </div>
          </div>
        </div>
        <hr />
        <div className='columns'>
          {ausstattungslinie}
          <div className='column'>
            <div className='has-text-centered'>
              <div className='is-uppercase'><Translate id='highlights/preis'>Preis</Translate></div>
              <div className='has-text-weight-bold is-size-3'><CurrencyFormat value={this.props.preis} currency='CHF' /></div>
            </div>
          </div>
          {aufpreis}
        </div>
        <div className='columns m-t-md'>
          {totalpreis}
        </div>
      </div>
    )
  }
}

Highlights.propTypes = {
  flaeche: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  flaecheAussen: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  etageNummer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  anzZimmer: PropTypes.number.isRequired,
  ausrichtungDisplay: PropTypes.string.isRequired,
  preis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wohnungsgrundrissName: PropTypes.string,
  interieur: PropTypes.string,
  aufpreis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalpreis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  konfigurationName: PropTypes.string,
  konfigurationId: PropTypes.number,
  editKonfigurationName: PropTypes.bool,
  showSaveButton: PropTypes.bool,
  highlights: PropTypes.shape({
    ausstattungslinie: PropTypes.shape({
      beschreibung: PropTypes.string,
      imagePath: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired
}

Highlights.defaultProps = {
  flaeche: 0,
  flaecheAussen: 0,
  etageNummer: 0,
  anzZimmer: 0,
  ausrichtungDisplay: '-',
  preis: 0,
  aufpreis: 0,
  editKonfigurationName: true,
  showSaveButton: false,
  highlights: {
    ausstattungslinie: undefined
  }
}

export default Highlights

export const Box = props => {
  const val = props.isCurrency ? <CurrencyFormat value={props.value} currency='CHF' /> : props.value
  const isHighlighted = props.isHighlight ? ' notification is-primary' : ''
  return (
    <div className={'column' + isHighlighted}>
      <div className='has-text-centered'>
        <div className='is-uppercase'>{props.title}</div>
        <div className='has-text-weight-bold is-size-3'>{val}</div>
      </div>
    </div>
  )
}

Box.propTypes = {
  isCurrency: PropTypes.bool.isRequired,
  isHeighlight: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
}

Box.defaultProps = {
  isCurrency: false,
  isHeighlight: false
}
