import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../action'
import Form, { Input } from '../../App/common/Form'
import Hero from '../../App/common/Hero'

export const PasswortWiederherstellung = props => {
  return (
    <div>
      <Hero
        title='Passwort vergessen?'
        description='Bitte geben Sie Ihre E-Mail Adresse ein. Sie erhalten anschliessend eine E-Mail mit einem Link. Klicken Sie auf diesen Link um das Passwort neu zu setzen.' />
      <div className='section'>
        <div className='container'>
          <div className='content'>
            <div className='columns' />
            <PasswortWiederherstellungForm history={props.history} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswortWiederherstellung

const initFormState = {
  form: {
    showError: false,
    isValid: { email: false },
    values: { email: undefined }
  }
}

export class PasswortWiederherstellungForm_ extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = initFormState

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.user !== null) {
      this.props.history.push('/')
    }
  }

  componentWillUnmount () {
    if (this.props.feedback.passwordRecovery === true) {
      this.setState(state => initFormState)
      this.props.actions.resetRecoverPasswordFeedback()
    }
  }

  handleSubmit (event) {
    super.handleSubmit(event, this.props.actions.recoverPassword, this.state.form.values)
  }

  getError () {
    return this.props.feedback.passwordRecovery === false
      ? <p className='help is-danger is-size-5'>Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.</p>
      : null
  }

  getSuccessOrForm () {
    return this.props.feedback.passwordRecovery === true
      ? <p className='help is-success is-size-5'>Sie erhalten in Kürze eine E-Mail mit einem Link. Klicken Sie auf diesen Link um das Passwort neu zu setzen.</p>
      : this.getForm()
  }

  getForm () {
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('email', isValid)}
          getValue={value => this.updateValue('email', value)}
          showError={this.state.form.showError}
          id='passwordRecoveryEmail'
          displayName='E-Mail'
          type='email'
          title='Bitte geben Sie eine gültige E-Mail Adresse ein'
          required />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary' onClick={this.handleSubmit}>Zurücksetzen</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const error = this.getError()
    const successOrForm = this.getSuccessOrForm()

    return (
      <div className='column is-half is-offset-one-quarter'>
        {error}
        {successOrForm}
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return {
    user: state.user.current,
    feedback: state.user.feedback
  }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

const PasswortWiederherstellungForm =
  connect(mapStateToProps, mapDispatchToProps)(PasswortWiederherstellungForm_)
