import * as actionType from './actionType'

// select a single translationId
// this action is broadcasted
export function selectTranslationId (translationId) {
  return { type: actionType.SELECT_TRANSLATION_ID, payload: { translationId } }
}

// set a specific translation for a given translationId and language
// this action is broadcasted
export function setTranslationForLanguage (translationId, translation, languageCode) {
  return {
    type: actionType.ADD_TRANSLATION_FOR_LANGUAGE,
    payload: { translationId, translation, languageCode }
  }
}
