import { Component } from 'react'
import { withRouter } from 'react-router-dom'

export class RouteHandler extends Component {
  componentWillReceiveProps (nextProps) {
    if (
      nextProps.location.pathname !== this.props.location.pathname &&
        // except for tabs, it changes url but are on the same page
        !(
          this.props.location.pathname.indexOf('wohnungsgrundriss') > 0 &&
            nextProps.location.pathname.indexOf('wohnungsgrundriss') > 0
        )
    ) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    return this.props.children
  }
}

export default withRouter(RouteHandler)
