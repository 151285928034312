import Stickyfill from 'stickyfilljs/dist/stickyfill'

export function displayEtageNummer (etageNummer) {
  if (etageNummer === 999) {
    return 'DG'
  } else if (etageNummer > 0) {
    return etageNummer + '. OG'
  } else if (etageNummer === 0) {
    return 'EG'
  } else if (etageNummer < 0) {
    return etageNummer * -1 + '. UG'
  }

  return ''
}

export function deepCopy (data) {
  return JSON.parse(JSON.stringify(data))
}

export function extractDropdownItems (list, field) {
  const myList = list || []
  let values = []
  myList.forEach(item => {
    if (item[field] === undefined || item[field] === null) return
    if (values.map(v => v.value).indexOf(item[field]) === -1) {
      values.push({ text: item[field], value: item[field] })
    }
  })

  return values.sort((a, b) => a.text - b.text)
}

export function extractMinRange (list, field) {
  if (list === undefined || list.length === 0) return 0
  return list.reduce((minRange, item) => {
    if (item[field] === undefined || item[field] === null) return minRange
    if (minRange[field] === undefined || minRange[field] === null) return item
    return (minRange[field] < item[field]) ? minRange : item
  })[field] || 0
}

export function extractMaxRange (list, field) {
  if (list === undefined || list.length === 0) return 0
  return list.reduce((maxRange, item) => {
    if (item[field] === undefined || item[field] === null) return maxRange
    if (maxRange[field] === undefined || maxRange[field] === null) return item
    return (maxRange[field] > item[field]) ? maxRange : item
  })[field] || 0
}

export function getGenderList () {
  return [
    { text: 'Frau', value: 'Frau' },
    { text: 'Herr', value: 'Herr' }
  ]
}

export function renderPdfBlob (blob, filename = 'konfigurator_pdf') {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob)
    return
  }

  const data = window.URL.createObjectURL(blob)
  let link = document.createElement('a')
  link.href = data
  link.download = encodeFilename(filename) + '.pdf'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  setTimeout(() => window.URL.revokeObjectURL(data), 100)
}

export function getTimeAgo (timestamp) {
  if (timestamp === undefined || timestamp === null) {
    return ''
  }

  const diff = (Date.now() - timestamp) / 1000
  const singularPlural = (value, singular, plural) => value === 1
    ? `vor ${value} ${singular}` : `vor ${value} ${plural}`
  const years = Math.floor(diff / (60 * 60 * 24 * 365))
  if (years !== 0) {
    return singularPlural(years, 'Jahr', 'Jahren')
  }

  const months = Math.floor(diff / (60 * 60 * 24 * 30))
  if (months !== 0) {
    return singularPlural(months, 'Monat', 'Monaten')
  }

  const days = Math.floor(diff / (60 * 60 * 24))
  if (days !== 0) {
    return singularPlural(days, 'Tag', 'Tagen')
  }

  const hours = Math.floor(diff / (60 * 60))
  if (hours !== 0) {
    return singularPlural(hours, 'Stunde', 'Stunden')
  }

  const minutes = Math.floor(diff / 60)
  if (minutes !== 0) {
    return singularPlural(minutes, 'Minute', 'Minuten')
  }

  return 'vor wenigen Sekunden'
}

export function registerStickyPolyfillElement (elementClass) {
  Stickyfill.add(document.querySelectorAll(elementClass))
}

export function unregisterStickyPolyfillElement (elementClass) {
  Stickyfill.remove(document.querySelectorAll(elementClass))
}

export function encodeFilename (name) {
  return name.replace(/[^a-z0-9]/gi, '_')
}
