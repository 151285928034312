import React from 'react'
import Image from '../../Image'

const EmphasizedKategorie = props => {
  const option = props.optionen[0]
  const variationen = option.variationen

  return (
    <div className='m-b-lg'>
      <h1 className='title'>{option.name}</h1>
      <p className='m-b-lg'>{option.beschreibung}</p>
      <div className='columns'>
        {variationen.map(item =>
          <EmphasizedVariation key={item.variationId} {...item}
            handleVariationChange={props.handleVariationChange}
            isReadOnly={props.isReadOnly} />)}
      </div>
    </div>
  )
}

export default EmphasizedKategorie

const EmphasizedVariation = props => {
  const isActive = props.selected ? ' is-active' : ''
  const isClickable = !props.isReadOnly ? ' is-clickable' : ''
  const action = props.isReadOnly ? () => {} : () => props.handleVariationChange(props)

  return (
    <div className='column'>
      <div onClick={action}
        className={'card' + isClickable + isActive}>
        <div className='card-image'>
          <figure className='image is-4by3'>
            <Image src={props.imagePath} alt={props.name + ' preview'} width='432w' tabletWidth='432w' mobileWidth='720w' ratio='r1.56' />
          </figure>
        </div>
        <div className='card-content'>
          <div className='content'>
            <h1 className='title'>{props.name}</h1>
            <p>{props.beschreibung}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
