import { combineReducers } from 'redux'
import * as actionType from './actionType'

export function konfigurationVReducer (state = {}, action) {
  switch (action.type) {
    case actionType.SET_KONFIGURATION_V:
      return Object.assign({}, action.payload)
    default:
      return state
  }
}

export function wohnungsgrundrissReducer (state = {}, action) {
  switch (action.type) {
    case actionType.SET_WOHNUNGSGRUNDRISS:
      return Object.assign({}, action.payload)
    default:
      return state
  }
}

export function feedbackReducer (state = {}, action) {
  switch (action.type) {
    case actionType.EXPORT_SUCCESS:
      return { export: true }
    case actionType.EXPORT_ERROR:
      return { export: false }
    default:
      return state
  }
}

export default combineReducers({
  konfigurationV: konfigurationVReducer,
  wohnungsgrundriss: wohnungsgrundrissReducer,
  feedback: feedbackReducer
})
