import React, { Component } from 'react'
import { registerStickyPolyfillElement, unregisterStickyPolyfillElement }
  from '../../App/common/helper'
import SVGInline from 'react-svg-inline'

import $ from 'jquery/dist/jquery.slim'
import IsometrieSvg from '../../assets/isometrie/isometrie.svg'
import './isometrie.css'

/* global XMLHttpRequest */

export class Isometrie extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      svg: '<svg></svg>'
    }

    this.ajax = null
  }

  componentDidMount () {
    const mql = window.matchMedia('(min-width:50em)')
    this.loadSVG(mql)
    mql.addListener(this.loadSVG.bind(this))
    registerStickyPolyfillElement('.isometrie')
  }

  componentWillUnmount () {
    if (this.ajax !== null) {
      this.ajax.abort()
    }
    unregisterStickyPolyfillElement('.isometrie')
  }

  loadSVG (mql) {
    if (!mql.matches) {
      return
    }

    this.ajax = new XMLHttpRequest()
    this.ajax.open('GET', IsometrieSvg, true)

    this.ajax.onload = e => {
      this.setState(state => ({ svg: this.ajax.responseText }))
    }

    this.ajax.send()
  }

  openLevel (id) {
    const id_ = id.replace('.', '-')
    let $svgElement = $(`#${id_}`)
    $svgElement.parent().removeClass('isometry--up').nextAll().addClass('isometry--up')
    $svgElement.parent().prevAll().removeClass('isometry--up')
    $svgElement.addClass('isometry--active')
  }

  closeLevel (id) {
    const id_ = id.replace('.', '-')
    let $svgElement = $(`#${id_}`)
    $svgElement.removeClass('isometry--active')
  }

  closeAll () {
    $('.isometrie svg').find('.isometry--up').removeClass('isometry--up')
  }

  render () {
    return (
      <div className='isometrie'>
        <SVGInline svg={this.state.svg} />
      </div>
    )
  }
}

export default Isometrie
