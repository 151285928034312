import { connect } from "react-redux";
import {
  Konfigurator,
  mapStateToProps,
  mapDispatchToProps,
} from "../../../lib/App/common/konfigurator";
import { kategorienSwitch } from "./components/Kategorien";
import {
  optionenSwitch,
  BooleanOption,
  TextOption,
  InfoOption,
} from "./components/Optionen";
import {
  variationenSwitch,
  Variation,
  ReadOnlyVariation,
} from "./components/Variationen";
import EmphasizedKategorie from "./components/EmphasizedKategorie";
import PackageVariationKategorie from "./components/PackageVariationKategorie";
import PackageKategorie from "./components/PackageKategorie";
import Kategorie from "./components/Kategorie";

class KonfiguratorAlthard extends Konfigurator {
  getSwitches() {
    return {
      kategorienSwitch: kategorienSwitch,
      optionenSwitch: optionenSwitch,
      variationenSwitch: variationenSwitch,
    };
  }

  getComponents() {
    return {
      EmphasizedKategorie,
      PackageVariationKategorie,
      PackageKategorie,
      Kategorie,
      BooleanOption,
      TextOption,
      InfoOption,
      Variation,
      ReadOnlyVariation,
    };
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KonfiguratorAlthard);
