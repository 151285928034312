import { combineReducers } from 'redux'
import * as actionType from './actionType'

export function userReducer (state = null, action) {
  switch (action.type) {
    case actionType.SET:
      return Object.assign({}, action.payload)
    case actionType.REMOVE:
      return null
    default:
      return state
  }
}

export function feedbackReducer (state = {}, action) {
  switch (action.type) {
    case actionType.REGISTRATION_SUCCESS:
      return { registration: true }
    case actionType.REGISTRATION_ERROR:
      return { registration: false }
    case actionType.REGISTRATION_RESET:
      return { registration: undefined }
    case actionType.LOGIN_ERROR:
      return { login: false }
    case actionType.ACTIVATION_ERROR:
      return { activation: false }
    case actionType.PASSWORD_RECOVERY_SUCCESS:
      return { passwordRecovery: true }
    case actionType.PASSWORD_RECOVERY_ERROR:
      return { passwordRecovery: false }
    case actionType.PASSWORD_RECOVERY_RESET:
      return { passwordRecovery: undefined }
    case actionType.PASSWORD_RESET_ERROR:
      return { passwordReset: false }
    case actionType.PROFILE_UPDATE_SUCCESS:
      return { profilUpdate: true }
    case actionType.PROFILE_UPDATE_ERROR:
      return { profilUpdate: false }
    case actionType.PROFILE_PASSWORD_UPDATE_SUCCESS:
      return { profilPasswordUpdate: true }
    case actionType.PROFILE_PASSWORD_UPDATE_ERROR:
      return { profilPasswordUpdate: false }
    default:
      return state
  }
}

export default combineReducers({
  current: userReducer,
  feedback: feedbackReducer
})
