import axios from 'axios'
import store from '../localStore'
import * as config from './config'

const URL = config.API_URL + '/' + config.API_VERSION
const api = axios.create()

api.interceptors.response.use(response => {
  if (response.data.renewToken !== undefined) {
    store.setItem('renewToken', response.data.renewToken)
  }

  if (response.data.accessToken !== undefined) {
    store.setItem('accessToken', response.data.accessToken)
  }

  return response
})

api.authGet = (url, config) => {
  return renewAccessToken(config => api.get(url, config), config)
}

api.authPost = (url, data, config) => {
  return renewAccessToken(config => api.post(url, data, config), config)
}

api.authPut = (url, data, config) => {
  return renewAccessToken(config => api.put(url, data, config), config)
}

api.authDelete = (url, data, config) => {
  return renewAccessToken(config => api.delete(url, config), config)
}

export default api

function renewAccessToken (apiFunc, config) {
  const accessToken = store.getItem('accessToken')
  const renewToken = store.getItem('renewToken')
  if (accessToken === null || renewToken === null) {
    return apiFunc(config)
  }
  const renewConfig = { headers: { Authorization: 'renew ' + renewToken } }
  if (isExpired(accessToken)) {
    return api.post(URL + '/auth/access', undefined, renewConfig).then(response => {
      const renewedAccessToken = store.getItem('accessToken')
      const accessConfig = { headers: { Authorization: 'access ' + renewedAccessToken } }
      return apiFunc(Object.assign({}, config, accessConfig))
    })
  } else if (willExpiresSoon(accessToken)) {
    const accessConfig = { headers: { Authorization: 'access ' + accessToken } }
    const apiCall = apiFunc(Object.assign({}, config, accessConfig))
    api.post(URL + '/auth/access', undefined, renewConfig)
    return apiCall
  } else {
    const accessConfig = { headers: { Authorization: 'access ' + accessToken } }
    return apiFunc(Object.assign({}, config, accessConfig))
  }
}

function isExpired (token) {
  const { exp } = parseJwt(token)
  return new Date().getTime() >= 1000 * exp
}

function willExpiresSoon (token) {
  const { exp } = parseJwt(token)
  return 1000 * exp - new Date().getTime() > 660 * exp
}

function parseJwt (token) {
  return JSON.parse(window.atob(token.split('.')[1].replace('-', '+').replace('_', '/')))
}
