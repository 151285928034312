import React from "react";

const SearchGroupUnorderedList = (props) => (
  <ul className="group">{props.children}</ul>
);

export const SearchGroupOrderedList = (props) => (
  <ol className="group">{props.children}</ol>
);

export const SearchGroupListEntry = (props) => (
  <li className="group">{props.children}</li>
);

export default SearchGroupUnorderedList;
