import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import InfoTooltip from '../../App/common/InfoTooltip'
import KonfigurationName from '../../App/common/KonfigurationName'
import { ConfirmModal } from '../../App/common/Modal'

/* eslint-disable jsx-a11y/anchor-is-valid */

export class Table extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      toggleRemoveModal: false,
      removeModalActiveKonfigurationId: undefined,
      removeModalActiveKonfigurationName: undefined,
      toggleFinalizeModal: false,
      finalizeModalActiveKonfigurationId: undefined,
      finalizeModalActiveKonfigurationName: undefined
    }

    this.toggleRemoveModal = this.toggleRemoveModal.bind(this)
    this.removeKonfiguration = this.removeKonfiguration.bind(this)
    this.toggleFinalizeModal = this.toggleFinalizeModal.bind(this)
    this.finalizeKonfiguration = this.finalizeKonfiguration.bind(this)
  }

  toggleRemoveModal (konfigurationId, konfigurationName) {
    this.setState(state => ({
      toggleRemoveModal: !state.toggleRemoveModal,
      removeModalActiveKonfigurationId: konfigurationId,
      removeModalActiveKonfigurationName: konfigurationName
    }))
  }

  removeKonfiguration () {
    this.toggleRemoveModal()
    this.props.actions.remove(this.state.removeModalActiveKonfigurationId)
  }

  toggleFinalizeModal (konfigurationId, konfigurationName) {
    this.setState(state => ({
      toggleFinalizeModal: !state.toggleFinalizeModal,
      finalizeModalActiveKonfigurationId: konfigurationId,
      finalizeModalActiveKonfigurationName: konfigurationName
    }))
  }

  finalizeKonfiguration () {
    this.toggleFinalizeModal()
    this.props.actions.updateStatus(this.state.finalizeModalActiveKonfigurationId, 'finalisieren')
  }

  getFirstNameColumn () {
    return this.props.isMakler ? <th>Vorname</th> : null
  }

  getLastNameColumn () {
    return this.props.isMakler ? <th>Nachname</th> : null
  }

  render () {
    const rows = this.props.rows || []
    const firstNameColumn = this.getFirstNameColumn()
    const lastNameColumn = this.getLastNameColumn()

    return (
      <div>
        <table className='table is-striped is-hoverable is-fullwidth'>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>ID</th>
              {firstNameColumn}
              {lastNameColumn}
              <th style={{ width: '28%' }}>Name Konfiguration <InfoTooltip
                text='Klicken Sie auf den jeweiligen Konfigurationsnamen um diesen zu ändern.' /></th>
              <th>Wohnungscode</th>
              <th style={{ width: '20%' }}>Status</th>
              <th style={{ width: '20%' }} className='has-text-right'>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(row => <Row key={row.konfigurationId} {...row}
              toggleRemoveModal={this.toggleRemoveModal} toggleFinalizeModal={this.toggleFinalizeModal}
              actions={this.props.actions} isMakler={this.props.isMakler} report={this.props.report} />)}
            {rows.length === 0 ? <NoEntries isMakler={this.props.isMakler} /> : null}
          </tbody>
        </table>
        <ConfirmModal
          isActive={this.state.toggleRemoveModal}
          toggleModal={this.toggleRemoveModal}
          confirm={this.removeKonfiguration}
          title={'Löschen von"' + this.state.removeModalActiveKonfigurationName + '"'}
          description={'Möchten Sie die Konfiguration "' + this.state.removeModalActiveKonfigurationName + '" wirklich löschen?'}
          buttonText={'Löschen'} />
        <ConfirmModal
          isActive={this.state.toggleFinalizeModal}
          toggleModal={this.toggleFinalizeModal}
          confirm={this.finalizeKonfiguration}
          title={'Finalisieren von "' + this.state.finalizeModalActiveKonfigurationName + '"'}
          description={'Möchten Sie die Konfiguration "' + this.state.finalizeModalActiveKonfigurationName + '" wirklich finalisieren?'}
          buttonText={'Finalisieren'} />
      </div>
    )
  }
}

Table.propTypes = {
  isMakler: PropTypes.bool,
  actions: PropTypes.shape({
    updateStatus: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      konfigurationId: PropTypes.number.isRequired,
      nameFirst: PropTypes.string,
      nameLast: PropTypes.string,
      name: PropTypes.string.isRequired,
      wohnungsCode: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      ownStatus: PropTypes.bool.isRequired,
      possibleStatus: PropTypes.array
    }).isRequired
  ).isRequired
}

Table.defaultProps = {
  isMakler: false,
  rows: []
}

export default Table

export const NoEntries = props => {
  const cols = props.isMakler ? 7 : 5
  return (
    <tr>
      <td colSpan={cols}><strong>Keine Konfigurationen gefunden.</strong></td>
    </tr>
  )
}

export class Row extends Component {
  isFree () {
    return this.props.status === 'frei'
  }

  isReserved () {
    return this.props.status === 'reserviert'
  }

  isFinal () {
    return this.props.status === 'finalisiert'
  }

  showReserve () {
    return this.props.possibleStatus !== undefined &&
      this.props.possibleStatus.indexOf('reserviert') !== -1
  }

  getReserve () {
    const iconTitle = this.isFinal() ? 'finalisieren rückgängig' : 'reserverien'
    return this.showReserve()
      ? (
        <a className='m-l-lg' onClick={() => this.props.actions.updateStatus(this.props.konfigurationId, 'reservieren')}>
          <i title={iconTitle} className='fa fa-handshake-o' />
        </a>
      )
      : null
  }

  showFinalize () {
    return this.props.possibleStatus !== undefined &&
      this.props.possibleStatus.indexOf('finalisiert') !== -1
  }

  getFinalize () {
    return this.showFinalize()
      ? (
        <a className='m-l-md' onClick={() => this.props.toggleFinalizeModal(this.props.konfigurationId, this.props.name)}>
          <i title='finalisieren' className='fa fa-lock' />
        </a>
      )
      : null
  }

  showExportV () {
    return this.props.possibleStatus !== undefined &&
      this.props.possibleStatus.indexOf('exportiert') !== -1
  }

  getExportV () {
    return this.showExportV()
      ? (
        <Link className='m-l-md' to={'/konfiguration/' + this.props.konfigurationId + '/export'}>
          <i title='exportieren' className='fa fa-upload' />
        </Link>
      )
      : null
  }

  showFree () {
    return this.props.possibleStatus !== undefined &&
      this.props.possibleStatus.indexOf('frei') !== -1
  }

  getFree () {
    return this.showFree()
      ? (
        <a className='m-l-md' onClick={() => this.props.actions.updateStatus(this.props.konfigurationId, 'frei')}>
          <i title='reservieren rückgängig' className='fa fa-unlock' />
        </a>
      )
      : null
  }

  getMakler (reserve, finalize, exportv, free, style) {
    return this.props.isMakler
      ? {
        column: {
          nameFirst: <td>{this.props.nameFirst}</td>,
          nameLast: <td>{this.props.nameLast}</td>
        },
        action: <span style={style}>
          {reserve}
          {finalize}
          {exportv}
          {free}
        </span>
      }
      : { column: { nameFirst: null, nameLast: null }, action: null }
  }

  showEditAction (isFree, isReserved) {
    return !this.props.ownStatus || (isFree || isReserved)
  }

  getEditAction (isFree, isReserved) {
    return this.showEditAction(isFree, isReserved)
      ? (
        <a className='m-l-md' onClick={() => this.props.actions.goto(this.props.konfigurationId)}>
          <i title='editieren' className='fa fa-pencil' />
        </a>
      )
      : (
        <a className='m-l-md' onClick={() => this.props.actions.gotoReadOnly(this.props.konfigurationId)}>
          <i title='ansehen' className='fa fa-search' />
        </a>
      )
  }

  showCopyAction (isFree, isReserved) {
    return isFree || isReserved
  }

  getCopyAction (isFree, isReserved) {
    return this.showCopyAction(isFree, isReserved)
      ? (
        <a className='m-l-md' onClick={() => this.props.actions.copy(this.props.konfigurationId)}>
          <i title='kopieren' className='fa fa-files-o' />
        </a>
      )
      : null
  }

  showDownloadAction (reportStatus) {
    return reportStatus.generatePdf &&
      reportStatus.konfiguration.konfigurationId === this.props.konfigurationId
  }

  getDownloadAction (reportStatus) {
    return this.showDownloadAction(reportStatus)
      ? (
        <span className='m-l-sm' style={{ padding: '1px' }}>
          <i title='pdf wird heruntergeladen...' style={{ width: '13px', height: '13px' }}
            className='fa fa-spinner fa-pulse fa-fw' />
        </span>
      ) : (
        <a className='m-l-md' onClick={() => this.props.actions.fetchReport(this.props.konfigurationId, this.props.name)}>
          <i title='pdf herunterladen' className='fa fa-download' />
        </a>
      )
  }

  showRemoveAction (isFree) {
    return isFree || !this.props.ownStatus
  }

  getRemoveAction (isFree) {
    return this.showRemoveAction(isFree)
      ? (
        <a className='m-l-md' onClick={() => this.props.toggleRemoveModal(this.props.konfigurationId, this.props.name)}>
          <i title='löschen' className='fa fa-trash-o' />
        </a>
      ) : null
  }

  getOwnStatus () {
    return this.props.ownStatus ? ' (diese Konfiguration)' : ''
  }

  getStatus () {
    return this.props.status
  }

  render () {
    const report = this.props.report || { generatePdf: false }
    const isFree = this.isFree()
    const isReserved = this.isReserved()
    const reserve = this.getReserve()
    const finalize = this.getFinalize()
    const exportv = this.getExportV()
    const free = this.getFree()
    const makler = this.getMakler(reserve, finalize, exportv, free)
    const editAction = this.getEditAction(isFree, isReserved)
    const copyAction = this.getCopyAction(isFree, isReserved)
    const downloadAction = this.getDownloadAction(report)
    const removeAction = this.getRemoveAction(isFree)
    const ownStatus = this.getOwnStatus()
    const status = this.getStatus()

    return (
      <tr>
        <td>{this.props.konfigurationId}</td>
        {makler.column.nameFirst}
        {makler.column.nameLast}
        <td>
          <KonfigurationName konfigurationId={this.props.konfigurationId} name={this.props.name} />
        </td>
        <td>{this.props.wohnungsCode}</td>
        <td>{status + ownStatus}</td>
        <td className='has-text-right'>
          {makler.action}
          {editAction}
          {copyAction}
          {downloadAction}
          {removeAction}
        </td>
      </tr>
    )
  }
}
