import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { registerStickyPolyfillElement } from '../../helper'
import Image from '../../Image'

import './rendering.css'

export class Rendering extends Component {
  componentDidMount () {
    registerStickyPolyfillElement('.rendering')
  }

  render () {
    return (
      <Image src={this.props.imagePath} className='rendering'
        alt={this.props.name + ' preview'} height='469p' ratio='r1.60' />
    )
  }
}

Rendering.propTypes = {
  imagePath: PropTypes.string,
  name: PropTypes.string
}

export default Rendering
