import { takeLatest, put, call, select, fork, take } from 'redux-saga/effects'
import * as actionType from './actionType'
import * as konfigurationActionType from '../App/common/konfigurator/actionType'
import * as errorActionType from '../App/common/error/actionType'
import provider from './provider'
import history from '../App/common/history'
import { renderPdfBlob } from '../App/common/helper'

export function * fetchList (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_LIST)
      const response = yield call(api.getList, action.payload)
      yield put({ type: actionType.FILTER_INIT, payload: response.data })
      yield call(updateList, response.data)
    } catch (error) {
      yield put({ type: actionType.SET_LIST, payload: [] })
      yield put({ type: actionType.SET_FILTERED_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * setFilteredList () {
  try {
    const data = yield select(state => {
      return { filters: state.konfigurationList.filters, list: state.konfigurationList.list }
    })
    const response = yield call(filterList, data.list, data.filters)
    yield put({ type: actionType.SET_FILTERED_LIST, payload: response })
  } catch (error) {
    yield put({ type: actionType.SET_FILTERED_LIST, payload: [] })
    yield put({ type: errorActionType.ERROR, payload: error })
  }
}

export function * gotoKonfiguation (api) {
  while (true) {
    try {
      const action = yield take(actionType.GOTO)
      const response = yield call(api.headAsCopy, action.payload)
      yield put({
        type: konfigurationActionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
      const historyPush = history.push.bind(history)
      yield call(historyPush, '/konfigurator/' +
        response.data.konfigurationV.konfigurationVId + '/zusammenfassung')
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * gotoReadOnlyKonfiguation (api) {
  while (true) {
    try {
      const action = yield take(actionType.GOTO_READ_ONLY)
      const response = yield call(api.head, action.payload)
      yield put({
        type: konfigurationActionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data } })
      const historyPush = history.push.bind(history)
      yield call(historyPush, '/konfigurator/' +
        response.data.konfigurationVId + '/zusammenfassung')
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * copyKonfiguration (api) {
  while (true) {
    try {
      const action = yield take(actionType.COPY)
      const response = yield call(api.copy, action.payload)
      const list = yield select(state => state.konfigurationList.list)
      const updatedList = yield call(addCopyToList, list, response.data.konfiguration)
      yield call(updateList, updatedList)
    } catch (error) {
      yield put({ type: actionType.SET_LIST, payload: [] })
      yield put({ type: actionType.SET_FILTERED_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * removeFromList (api) {
  while (true) {
    try {
      const action = yield take(actionType.REMOVE)
      yield call(api.remove, action.payload)
      const list = yield select(state => state.konfigurationList.list)
      const updatedList = yield call(removeListItem, list, action.payload)
      yield call(updateList, updatedList)
    } catch (error) {
      yield put({ type: actionType.SET_LIST, payload: [] })
      yield put({ type: actionType.SET_FILTERED_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateList (list) {
  yield put({ type: actionType.SET_LIST, payload: list })
  const filters = yield select(state => state.konfigurationList.filters)
  const filtered = yield call(filterList, list, filters)
  yield put({ type: actionType.SET_FILTERED_LIST, payload: filtered })
}

export function * updateStatus (api) {
  while (true) {
    try {
      const action = yield take(actionType.UPDATE_STATUS)
      yield call(api.updateStatus, action.payload.id, action.payload.type)
      const response = yield call(api.getList, true)
      const filters = yield select(state => state.konfigurationList.filters)
      yield put({ type: actionType.FILTER_INIT, payload: response.data })
      yield put({ type: actionType.SET_LIST, payload: response.data })
      const filteredResponse = yield call(filterList, response.data, filters)
      yield put({ type: actionType.SET_FILTERED_LIST, payload: filteredResponse })
      yield put({ type: actionType.FILTER_UPDATE_WITHOUT_AVAILABLE, payload: filters })
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateNameKonfiguration (api) {
  while (true) {
    try {
      const action = yield take(actionType.UPDATE_NAME_KONFIGURATION)
      yield call(api.updateName, action.payload.id, action.payload.name)
      yield put({ type: konfigurationActionType.SET_KONFIGURATION_V_NAME, payload: { konfigurationV: action.payload } })
      const list = yield select(state => state.konfigurationList.list)
      const updatedList = yield call(
        updateNameOfListItem, list, action.payload.id, action.payload.name)
      yield call(updateList, updatedList)
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * fetchReport (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_REPORT)
      const response = yield call(api.fetchReport, action.payload.konfigurationId)
      yield call(renderPdfBlob, response.data, action.payload.filename)
      yield put({ type: actionType.FETCH_REPORT_FINISHED, payload: action.payload.konfigurationId })
    } catch (error) {
      yield put({ type: actionType.FETCH_REPORT_FINISHED })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export const sagas = [
  fork(fetchList, provider),
  takeLatest(actionType.FILTER_UPDATE, setFilteredList),
  takeLatest(actionType.FILTER_RESET, setFilteredList),
  fork(gotoKonfiguation, provider),
  fork(gotoReadOnlyKonfiguation, provider),
  fork(copyKonfiguration, provider),
  fork(removeFromList, provider),
  fork(updateStatus, provider),
  fork(updateNameKonfiguration, provider),
  fork(fetchReport, provider)
]

function filterList (list, filters) {
  return list
    .filter(
      item => filters.id === '' ||
              item.konfigurationId.toString().indexOf(filters.id) !== -1)
    .filter(
      item => filters.nameFirst === '' ||
              item.nameFirst.toLowerCase().indexOf(filters.nameFirst.toLowerCase()) !== -1)
    .filter(
      item => filters.nameLast === '' ||
              item.nameLast.toLowerCase().indexOf(filters.nameLast.toLowerCase()) !== -1)
    .filter(
      item => filters.name === '' ||
              item.name.toLowerCase().indexOf(filters.name.toLowerCase()) !== -1)
    .filter(
      item => filters.wohnungsCode === '' ||
              item.wohnungsCode.toLowerCase()
                .indexOf(filters.wohnungsCode.toLowerCase()) !== -1)
    .filter(
      item => filters.status === '' || item.status === filters.status)
}

function addCopyToList (list, copy) {
  return [...list, copy]
}

function removeListItem (list, id) {
  return list.filter(item => item.konfigurationId !== id)
}

function updateNameOfListItem (list, id, name) {
  return list.map(item => item.konfigurationId === id ? Object.assign({}, item, { name }) : item)
}
