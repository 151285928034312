import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../action'
import { withRouter } from 'react-router-dom'
import Form, { Input, Dropdown } from '../../App/common/Form'
import { getGenderList } from '../../App/common/helper'
import Authorized from '../../App/common/Authorized'
import Hero from '../../App/common/Hero'

export class Profil extends Authorized {
  render () {
    return (
      <div>
        <Hero
          title='Profil'
          description='Ändern Sie hier Ihre Benutzerdaten oder das Passwort.' />
        <div className='section'>
          <div className='container'>
            <div className='content'>
              <div className='columns'>
                <ProfilDetailForm user={this.props.user}
                  feedback={this.props.feedback}
                  actions={this.props.actions} />
                <ProfilPasswordForm feedback={this.props.feedback} actions={this.props.actions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return { user: state.user.current || {}, feedback: state.user.feedback }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profil))

export class ProfilDetailForm extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      formSend: false,
      form: {
        showError: false,
        isValid: {
          email: false,
          title: false,
          nameFirst: false,
          nameLast: false,
          addressStreet: false,
          addressZip: false,
          addressPlace: false,
          addressCountry: false,
          phoneNr: false,
          companyName: true

        },
        values: {
          email: undefined,
          title: undefined,
          nameFirst: undefined,
          nameLast: undefined,
          addressStreet: undefined,
          addressZip: undefined,
          addressPlace: undefined,
          addressCountry: undefined,
          phoneNr: undefined,
          companyName: undefined
        }
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event) {
    this.setState(state => ({ formSend: true }))
    super.handleSubmit(event, this.props.actions.updateProfile, this.state.form.values)
  }

  getError (feedback) {
    return feedback.profilUpdate === false && this.state.formSend
      ? <p className='help is-danger is-size-5'>Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben.</p>
      : null
  }

  getSuccess (feedback) {
    return feedback.profilUpdate === true && this.state.formSend
      ? <p className='help is-success is-size-5'>Die Angaben wurden erfolgreich geändert.</p>
      : null
  }

  getGenderList () {
    return getGenderList()
  }

  getForm (user) {
    const optionen = this.getGenderList()
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('email', isValid)}
          getValue={value => this.updateValue('email', value)}
          showError={this.state.form.showError}
          type='email'
          displayName='E-Mail'
          id='profilEmail'
          title='Bitte geben Sie Ihre E-Mail ein'
          value={user.email}
          disabled
          required />
        <Dropdown
          isValid={isValid => this.updateIsValid('title', isValid)}
          getValue={value => this.updateValue('title', value)}
          showError={this.state.form.showError}
          id='profilTitle'
          displayName='Anrede'
          title='Bitte geben Sie Ihre Anrede ein'
          value={user.title}
          optionen={optionen}
          required />
        <Input
          isValid={isValid => this.updateIsValid('nameFirst', isValid)}
          getValue={value => this.updateValue('nameFirst', value)}
          showError={this.state.form.showError}
          id='profilNameFirst'
          displayName='Vorname'
          title='Bitte geben Sie Ihren Vornamen ein'
          value={user.nameFirst}
          required />
        <Input
          isValid={isValid => this.updateIsValid('nameLast', isValid)}
          getValue={value => this.updateValue('nameLast', value)}
          showError={this.state.form.showError}
          id='profilNameLast'
          displayName='Nachname'
          title='Bitte geben Sie Ihren Nachname ein'
          value={user.nameLast}
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressStreet', isValid)}
          getValue={value => this.updateValue('addressStreet', value)}
          showError={this.state.form.showError}
          id='profilAddressStreet'
          displayName='Strasse'
          title='Bitte geben Sie Ihre Strasse ein'
          value={user.addressStreet}
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressZip', isValid)}
          getValue={value => this.updateValue('addressZip', value)}
          showError={this.state.form.showError}
          id='profilAddressZip'
          displayName='Postleitzahl'
          title='Bitte geben Sie Ihre Postleitzahl ein'
          value={user.addressZip}
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressPlace', isValid)}
          getValue={value => this.updateValue('addressPlace', value)}
          showError={this.state.form.showError}
          id='profilPlace'
          displayName='Ort'
          title='Bitte geben Sie Ihren Ort ein'
          value={user.addressPlace}
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressCountry', isValid)}
          getValue={value => this.updateValue('addressCountry', value)}
          showError={this.state.form.showError}
          id='profilAddressCountry'
          displayName='Land'
          title='Bitte geben Sie Ihr Land ein'
          value={user.addressCountry}
          required />
        <Input
          isValid={isValid => this.updateIsValid('phoneNr', isValid)}
          getValue={value => this.updateValue('phoneNr', value)}
          showError={this.state.form.showError}
          id='profilPhoneNr'
          displayName='Telefonnummer'
          title='Bitte geben Sie Ihre Telefonnummer ein'
          value={user.phoneNr}
          required />
        <Input
          isValid={isValid => this.updateIsValid('companyName', isValid)}
          getValue={value => this.updateValue('companyName', value)}
          showError={this.state.form.showError}
          id='profilCompanyName'
          displayName='Firma'
          title='Bitte geben Sie Ihre Arbeitsfirma ein'
          value={user.companyName} />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary'
              onClick={this.handleSubmit}>Details ändern</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const { user, feedback } = this.props
    const error = this.getError(feedback)
    const success = this.getSuccess(feedback, user)
    const form = this.getForm(user)
    return (
      <div className='column'>
        <h2>Details ändern</h2>
        {success}
        {error}
        {form}
      </div>
    )
  }
}

export class ProfilPasswordForm extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = {
      formSend: false,
      form: {
        showError: false,
        isValid: {
          password: false,
          passwordConfirm: false
        },
        values: {
          password: undefined,
          passwordConfirm: undefined
        }
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event) {
    this.setState(state => ({ formSend: true }))
    super.handleSubmit(event, this.props.actions.updateProfilePassword, this.state.form.values)
  }

  getError (feedback) {
    return feedback.profilPasswordUpdate === false && this.state.formSend
      ? <p className='help is-danger is-size-5'>Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben.</p>
      : null
  }

  getSuccess (feedback) {
    let { password, passwordConfirm } = this.state.form.values
    let success = null
    if (feedback.profilPasswordUpdate === true && this.state.formSend) {
      success = <p className='help is-success is-size-5'>Das Passwort wurde erfolgreich geändert</p>
      password = undefined
      passwordConfirm = undefined
    }

    return { success, password, passwordConfirm }
  }

  getForm (password, passwordConfirm) {
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('password', isValid)}
          getValue={value => this.updateValue('password', value)}
          showError={this.state.form.showError}
          id='profilPassword'
          displayName='Passwort'
          type='password'
          title='Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen'
          pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
          value={password}
          required />
        <Input
          isValid={isValid => this.updateIsValid('passwordConfirm', isValid)}
          getValue={value => this.updateValue('passwordConfirm', value)}
          showError={this.state.form.showError}
          id='profilPasswordConfirm'
          displayName='Passwort bestätigen'
          type='password'
          title='Das Passwort stimmt nicht überein'
          pattern={'^' + this.patternValueEscape(this.state.form.values.password) + '$'}
          value={passwordConfirm}
          required />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary'
              onClick={this.handleSubmit}>Passwort ändern</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const { feedback } = this.props
    const error = this.getError(feedback)
    let { success, password, passwordConfirm } = this.getSuccess(feedback)
    const form = this.getForm(password, passwordConfirm)
    return (
      <div className='column'>
        <h2>Passwort ändern</h2>
        {success}
        {error}
        {form}
      </div>
    )
  }
}
