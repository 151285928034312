import React from 'react'
import VariationenSwitch, { SliderVariation, TextVariation } from './Variationen'
import Translate from '../../translator/components/translate'
import { CurrencyFormat } from '../../ValueFormatter'

export function optionenSwitch (option, components, OwnOptionComponent) {
  const { BooleanOption, TextOnlyOption, TextOption, NumberOption } = components

  if (option.classes.indexOf('boolean') > -1) {
    return BooleanOption
  } else if (~option.classes.indexOf('text_only')) {
    return TextOnlyOption
  } else if (~option.classes.indexOf('text')) {
    return TextOption
  } else if (~option.classes.indexOf('info')) {
    return TextOnlyOption
  } else if (~option.classes.indexOf('number')) {
    return NumberOption
  } else if (OwnOptionComponent !== undefined) {
    return OwnOptionComponent
  } else {
    return VariationenSwitch
  }
}

const OptionenSwitch = props => (
  <div>
    {props.optionen.map(option => {
      const OptionComponent =
        props.switches.optionenSwitch(option, props.components, props.ownOptionComponent)

      return <OptionComponent key={option.optionId} {...option}
        kategorieId={props.kategorieId}
        switches={props.switches}
        components={props.components}
        isReadOnly={props.isReadOnly}
        handleVariationChange={props.handleVariationChange}
        handleFrontendPropertyChange={props.handleFrontendPropertyChange} />
    })}
  </div>
)

export default OptionenSwitch

export const BooleanOption = props => {
  const elementId = 'boolean-switch-' + props.optionId
  const trueVariation = props.variationen.filter(variation => variation.name === '1')[0]
  const falseVariation = props.variationen.filter(variation => variation.name === '0')[0]
  const charge = props.aufpreis !== undefined
    ? <span className='m-l-md'><CurrencyFormat value={props.aufpreis} currency='CHF' /></span> : undefined
  const checked = trueVariation.selected
  const beschreibung = props.beschreibung !== undefined ? (
    <p className='m-t-md'>{props.beschreibung &&
    <Translate id={'optionen/' + props.optionCode + '/beschreibung'}>{props.beschreibung}</Translate>}</p>
  ) : undefined

  return (
    <div className='variation-item m-b-md m-t-md'>
      <div className='field'>
        <input onChange={(event) => props.handleVariationChange(event.target.checked ? trueVariation : falseVariation)}
          id={elementId} defaultChecked={checked} type='checkbox' name={elementId}
          className='switch is-rounded' disabled={props.isReadOnly} />
        <label htmlFor={elementId}>
          <Translate id={'optionen/' + props.optionName + '/name'}>{props.name}</Translate> {charge}
        </label>
        {beschreibung}
      </div>
    </div>
  )
}

export const TextOnlyOption = props => {
  return (
    <div>
      <h4 className='m-t-md title is-6 is-uppercase'>{props.name}</h4>
      <p>{props.beschreibung}</p>
    </div>
  )
}

export const TextOption = props => {
  const text = props.text || {}
  return (
    <div style={props.style}>
      <div>
        <h2 className='subtitle'>{props.name}</h2>
        <p>{props.beschreibung || ''}</p>
      </div>
      <div>
        <TextVariation {...props} {...text} />
      </div>
    </div>
  )
}

export const NumberOption = props => {
  const number = props.number || {}
  return (
    <div className='m-b-xl' style={props.style}>
      <div>
        <h2 className='subtitle'>{props.name}</h2>
        <p>{props.beschreibung || ''}</p>
      </div>
      <div>
        <SliderVariation {...props} {...number} />
      </div>
    </div>
  )
}
