import React, { Fragment } from "react";
import { connect } from "react-redux";
import SubHeader from "../App/common/Header/SubHeader";
import Steps from "../App/common/Steps/Steps";
import Rendering from "./components/Rendering";
import KonfiguratorNavigation from "../App/common/Konfigurator/components/KonfiguratorNavigation";
import {
  Zusammenfassung,
  mapStateToProps,
  mapDispatchToProps,
} from "../lib/Zusammenfassung";
import LinkBar from "./components/LinkBar";
import Kategorien from "./components/Kategorien";

import Highlights from "../App/common/Hightlights/Highlights";
import StickyNotePrice from "../App/common/StickyNote/StickyNotePrice";

export class ZusammenfassungLeonardo extends Zusammenfassung {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.interieurKategorien !== undefined &&
      nextProps.interieurKategorien.length > 0
    ) {
      const kategorien = this.filterKategorien(nextProps.interieurKategorien);
      this.setState(() => ({
        kategorien,
        kategorie: Object.assign({}, kategorien[0]),
      }));
    }

    const konfigurationVId = Number(nextProps.match.params.konfigurationVId);

    if (
      this.props.konfigurationV.konfigurationVId &&
      konfigurationVId !== this.props.konfigurationV.konfigurationVId
    ) {
      this.setKonfigurationVId(konfigurationVId);
    }
  }

  render() {
    const nav = this.getNav();
    const showSaveButton = this.props.user === null;

    return (
      <Fragment>
        <SubHeader
          title="Zusammen&shy;fassung"
          translateId={"Zusammenfassung/"}
          fixedWidth
        />
        <Steps
          step={4}
          konfigurationVId={this.props.konfigurationV.konfigurationVId}
        />
        <Rendering />
        <section className="group bk-options">
          <div className="bk-options-group group row">
            <LinkBar
              showSaveButton={showSaveButton}
              report={this.props.report}
              konfigurationId={this.props.konfigurationV.konfigurationId}
              konfigurationName={this.props.konfigurationV.name}
              fetchReport={() =>
                this.props.konfigurationActions.fetchReport(
                  this.props.konfigurationV.konfigurationVId,
                  this.props.konfigurationV.name
                )
              }
            />
            <Highlights />
          </div>
          <form className="group" action="./" method="post">
            <fieldset className="bk-options-group group row">
              <Kategorien kategorien={this.props.kategorien} />
            </fieldset>
            <KonfiguratorNavigation {...nav} />
          </form>
        </section>
        <StickyNotePrice />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZusammenfassungLeonardo);
