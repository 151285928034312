import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './collapsable.css'

const Collapsable = props => {
  return (<div>{props.children}</div>)
}

export default Collapsable

export class CollapsableItem extends Component {
  render () {
    const { angleType, bodyType } = this.props.isOpen
      ? { angleType: 'down', bodyType: 'opened' }
      : { angleType: 'right', bodyType: 'closed' }
    const { title, children } = this.props

    return (
      <div className='card collapsable'>
        <header className='card-header is-clickable' onClick={this.props.isOpenHandle}>
          <span className='card-header-icon'>
            <span className='icon'>
              <i className={'fa fa-angle-' + angleType} />
            </span>
          </span>
          <p className='card-header-title'>
            {title}
          </p>
        </header>
        <div className={'collapsable-' + bodyType}>
          <div className='card-content'>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

CollapsableItem.propTypes = {
  title: PropTypes.element.isRequired,
  isOpen: PropTypes.bool,
  isOpenHandle: PropTypes.func
}

CollapsableItem.defaultProps = {
  isOpen: false,
  isOpenHandle: () => {}
}
