import React, { Component } from "react";
import PropTypes from "prop-types";
import Translate from "../../../lib/App/common/translator/components/translate";

class SubHeader extends Component {
  render() {
    const translateTitle = (
      <Translate id={this.props.translateId + "titel"}>
        {this.props.title}
      </Translate>
    );
    const translateDesc = this.props.description ? (
      <Translate id={this.props.translateId + "beschreibung"}>
        {this.props.description}
      </Translate>
    ) : (
      ""
    );

    return (
      <section className="group bk-intro">
        <div className="group row">
          <div className="group columns large-4 medium-4 small-12">
            <h1 className={`${this.props.fixedWidth ? "header-width" : ""}`}>
              {translateTitle}
            </h1>
          </div>
          <div className="group columns large-8 medium-8 small-12">
            <p>{translateDesc}</p>
          </div>
        </div>
      </section>
    );
  }
}

SubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  translateId: PropTypes.string,
  fixedWidth: PropTypes.bool,
};

export default SubHeader;
