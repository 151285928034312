import React, { Fragment } from "react";
import { CurrencyFormat } from "../../../../lib/App/common/ValueFormatter";
import Translate from "../../../../lib/App/common/translator/components/translate";

const ZusatzoptionenHighlights = (props) => {
  const konfigurationV = props.konfigurationV || {};
  const translateParkplatz = (
    <Translate id="ZusatzoptionenHighlights/parkplatz">
      Parkplatz pro Jahr
    </Translate>
  );
  const translateLager = (
    <Translate id="ZusatzoptionenHighlights/lager">
      Lagerraum pro Jahr
    </Translate>
  );
  const translateRichtpreis = (
    <Translate id="ZusatzoptionenHighlights/richtpreis">
      Richtpreis Umzug
    </Translate>
  );
  const translateMwst = (
    <Translate id="ZusatzoptionenHighlights/mwst">MWST 8.1%</Translate>
  );
  const translateGross = (
    <Translate id="ZusatzoptionenHighlights/gross">
      Total Bruttopreis pro Jahr
    </Translate>
  );

  return (
    <Fragment>
      <tr>
        <td>{translateLager}</td>
        <td>
          <CurrencyFormat
            value={konfigurationV.lagerraumQmJahrPreis}
            currency="CHF"
          />{" "}
          /m<sup>2</sup>
        </td>
      </tr>
      <tr>
        <td>{translateParkplatz}</td>
        <td>
          <CurrencyFormat
            value={konfigurationV.parkplatzProJahrPreis}
            currency="CHF"
          />
        </td>
      </tr>
      <tr>
        <td>{translateMwst}</td>
        <td>
          <CurrencyFormat value={konfigurationV.vat} currency="CHF" />
        </td>
      </tr>
      <tr>
        <td>{translateGross}</td>
        <td>
          <CurrencyFormat value={konfigurationV.totalGross} currency="CHF" />
        </td>
      </tr>
    </Fragment>
  );
};

export default ZusatzoptionenHighlights;
