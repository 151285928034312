import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../action'
import Form, { Input, Dropdown } from '../../App/common/Form'
import { getGenderList } from '../../App/common/helper'
import Hero from '../../App/common/Hero'

export const Registrierung = props => {
  return (
    <div>
      <Hero
        title='Registrieren'
        description='Erstellen Sie ein Benutzerkonto. So können Sie Ihre Konfigurationen speichern und später wieder aufrufen.' />
      <div className='section'>
        <div className='container'>
          <div className='content'>
            <div className='columns'>
              <RegistrierungForm history={props.history} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registrierung

const initFormState = {
  form: {
    showError: false,
    isValid: {
      email: false,
      password: false,
      passwordConfirm: false,
      title: false,
      nameFirst: false,
      nameLast: false,
      addressStreet: false,
      addressZip: false,
      addressPlace: false,
      addressCountry: false,
      phoneNr: false,
      companyName: true
    },
    values: {
      email: undefined,
      password: undefined,
      passwordConfirm: undefined,
      title: undefined,
      nameFirst: undefined,
      nameLast: undefined,
      addressStreet: undefined,
      addressZip: undefined,
      addressPlace: undefined,
      addressCountry: undefined,
      phoneNr: undefined,
      companyName: undefined
    }
  }
}

export class RegistrierungForm_ extends Form {
  constructor (props, context) {
    super(props, context)

    this.state = initFormState

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.user !== null) {
      this.props.history.push('/')
    }
  }

  componentWillUnmount () {
    if (this.props.feedback.registration === true) {
      this.setState(state => initFormState)
      this.props.actions.resetRegistrationFeedback()
    }
  }

  handleSubmit (event) {
    super.handleSubmit(event, this.props.actions.fetchNew, this.state.form.values)
  }

  getError () {
    return this.props.feedback.registration === false
      ? <p className='help is-danger is-size-5'>Es ist ein Fehler aufgetretten. Bitte überprüfen Sie Ihre eingaben.</p>
      : null
  }

  getSuccessOrForm () {
    return this.props.feedback.registration === true
      ? <p className='help is-success is-size-5'>Sie erhalten in Kürze eine E-Mail mit einem Link, mit dem Sie die Registrierung abschliessen können</p>
      : this.getForm()
  }

  getGenderList () {
    return getGenderList()
  }

  getForm () {
    const optionen = this.getGenderList()
    return (
      <form>
        <Input
          isValid={isValid => this.updateIsValid('email', isValid)}
          getValue={value => this.updateValue('email', value)}
          showError={this.state.form.showError}
          type='email'
          displayName='E-Mail'
          id='registrationEmail'
          title='Bitte geben Sie Ihre E-Mail ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('password', isValid)}
          getValue={value => this.updateValue('password', value)}
          showError={this.state.form.showError}
          id='registrationPassword'
          displayName='Passwort'
          type='password'
          title='Das Passwort muss mindestens 8 Zeichen enthalten und aus Gross-, Kleinbuchstaben und Zahlen bestehen'
          pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
          required />
        <Input
          isValid={isValid => this.updateIsValid('passwordConfirm', isValid)}
          getValue={value => this.updateValue('passwordConfirm', value)}
          showError={this.state.form.showError}
          id='registrationPasswordConfirm'
          displayName='Passwort bestätigen'
          type='password'
          title='Das Passwort stimmt nicht überein'
          pattern={'^' + this.patternValueEscape(this.state.form.values.password) + '$'}
          required />
        <Dropdown
          isValid={isValid => this.updateIsValid('title', isValid)}
          getValue={value => this.updateValue('title', value)}
          showError={this.state.form.showError}
          id='registrationTitle'
          displayName='Anrede'
          title='Bitte geben Sie Ihre Anrede ein'
          optionen={optionen}
          required />
        <Input
          isValid={isValid => this.updateIsValid('nameFirst', isValid)}
          getValue={value => this.updateValue('nameFirst', value)}
          showError={this.state.form.showError}
          id='registrationNameFirst'
          displayName='Vorname'
          title='Bitte geben Sie Ihren Vornamen ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('nameLast', isValid)}
          getValue={value => this.updateValue('nameLast', value)}
          showError={this.state.form.showError}
          id='registrationNameLast'
          displayName='Nachname'
          title='Bitte geben Sie Ihren Nachname ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressStreet', isValid)}
          getValue={value => this.updateValue('addressStreet', value)}
          showError={this.state.form.showError}
          id='registrationAddressStreet'
          displayName='Strasse'
          title='Bitte geben Sie Ihre Strasse ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressZip', isValid)}
          getValue={value => this.updateValue('addressZip', value)}
          showError={this.state.form.showError}
          id='registrationAddressZip'
          displayName='Postleitzahl'
          title='Bitte geben Sie Ihre Postleitzahl ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressPlace', isValid)}
          getValue={value => this.updateValue('addressPlace', value)}
          showError={this.state.form.showError}
          id='registrationPlace'
          displayName='Ort'
          title='Bitte geben Sie Ihren Ort ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('addressCountry', isValid)}
          getValue={value => this.updateValue('addressCountry', value)}
          showError={this.state.form.showError}
          id='registrationAddressCountry'
          displayName='Land'
          title='Bitte geben Sie Ihr Land ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('phoneNr', isValid)}
          getValue={value => this.updateValue('phoneNr', value)}
          showError={this.state.form.showError}
          id='registrationPhoneNr'
          displayName='Telefonnummer'
          title='Bitte geben Sie Ihre Telefonnummer ein'
          required />
        <Input
          isValid={isValid => this.updateIsValid('companyName', isValid)}
          getValue={value => this.updateValue('companyName', value)}
          showError={this.state.form.showError}
          id='registrationCompanyName'
          displayName='Firma'
          title='Bitte geben Sie Ihre Arbeitsfirma ein' />
        <div className='field is-pulled-right'>
          <div className='control'>
            <button className='button is-primary' onClick={this.handleSubmit}>Registrieren</button>
          </div>
        </div>
      </form>
    )
  }

  render () {
    const error = this.getError()
    const successOrForm = this.getSuccessOrForm()

    return (
      <div className='column is-half is-offset-one-quarter'>
        {error}
        {successOrForm}
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return {
    user: state.user.current,
    feedback: state.user.feedback
  }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

const RegistrierungForm = connect(mapStateToProps, mapDispatchToProps)(RegistrierungForm_)
