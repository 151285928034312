import * as config from '../App/common/config'
import apiInstance from '../App/common/api'

/* global FormData */

const URL = config.API_URL + '/' + config.API_VERSION + '/konfigurations/'

const upload = (id, file, onUploadProgress, api = apiInstance) => {
  const data = new FormData()
  data.append('file', file)
  return api.authPost(
    URL + encodeURIComponent(id) + '/status/exportieren', data, { onUploadProgress })
}

export default { upload }
