import React from 'react'
import Rendering from './Rendering'
import { TreeableKategorie, getKategorieRendering, getKategorieSize } from './Kategorien'
import OptionenSwitch from './Optionen'
import VariationenSwitch from './Variationen'

class Kategorie extends TreeableKategorie {
  render () {
    const subKategorien = this.getSubKategorien(this.props)
    const optionen = <Optionen {...this.props} />
    return this.props.isSubset
      ? <SubsetKategorie {...this.props} kategorien={subKategorien} optionen={optionen} />
      : <RootKategorie {...this.props} kategorien={subKategorien} optionen={optionen} />
  }
}

export default Kategorie

const Optionen = props => {
  const { optionen } = props

  if (optionen === undefined || optionen.length === 0) {
    return null
  }

  const ownOptionComponent = Option
  return <OptionenSwitch {...props} ownOptionComponent={ownOptionComponent} firstOption={optionen[0]} />
}

const RootKategorie = props => {
  const rendering = getKategorieRendering(props, Rendering)
  const size = getKategorieSize(props)

  return (
    <div className='box m-b-lg'>
      <h1 className='title'>{props.name}</h1>
      <div className='columns'>
        <div className={'column' + size}>
          <p>{props.beschreibung}</p>
          <div>
            {props.kategorien}
            {props.optionen}
          </div>
        </div>
        {rendering}
      </div>
    </div>
  )
}

RootKategorie.defaultProps = {
  rendering: { imagePath: undefined }
}

const SubsetKategorie = props => {
  const rendering = getKategorieRendering(props, Rendering)
  const size = getKategorieSize(props)

  return (
    <div className='m-b-lg'>
      <div className='columns'>
        <div className={'column' + size}>
          <h1 className='subtitle'>{props.name}</h1>
          <p>{props.beschreibung}</p>
          <div>
            {props.kategorien}
            {props.optionen}
          </div>
        </div>
        {rendering}
      </div>
    </div>
  )
}

const Option = props => {
  return (
    <div key={props.optionId} className='m-t-lg'>
      <h2 className='subtitle'>{props.name}</h2>
      <div className='variationen'>
        <div className='variation-header'>{props.beschreibung}</div>
        <VariationenSwitch {...props} />
      </div>
    </div>
  )
}
