import apiInstance from '../api'
import * as config from '../config'

const URL = config.API_URL + '/' + config.API_VERSION + '/konfigurationVs'
const URL_KONFIGURATION = config.API_URL + '/' + config.API_VERSION + '/konfigurations'

const fetch = (id, api = apiInstance) => {
  return api.authGet(URL + '/' + encodeURIComponent(id))
}

const create = (request = {}, api = apiInstance) => {
  return api.authPost(URL, request)
}

const createKonfiguration = (request = {}, api = apiInstance) => {
  return api.authPost(URL_KONFIGURATION, request)
}

const fetchVariationList = (id, kategorieClasses = [], onlySelected = false, api = apiInstance) => {
  const query = getQuery(kategorieClasses, onlySelected)
  return api.authPost(URL + '/' + encodeURIComponent(id) + '/variationen' + query)
}

const updateVariation = (konfigurationVId, variationId, kategorieClasses, api = apiInstance) => {
  const query = getQuery(kategorieClasses)
  return api.authPost(URL + '/' + encodeURIComponent(konfigurationVId) +
    '/variationen/' + encodeURIComponent(variationId) + query)
}

const updateNumberVariation = (konfigurationVId, numberId, value, kategorieClasses, api = apiInstance) => {
  const query = getQuery(kategorieClasses)
  return api.authPost(URL + '/' + encodeURIComponent(konfigurationVId) +
    '/number/' + encodeURIComponent(numberId) + query, { value })
}

const updateTextVariation = (konfigurationVId, textId, value, kategorieClasses, api = apiInstance) => {
  const query = getQuery(kategorieClasses)
  return api.authPost(URL + '/' + encodeURIComponent(konfigurationVId) +
    '/text/' + encodeURIComponent(textId) + query, { value })
}

const updateFrontendProperty = (request, api = apiInstance) => {
  const suffix = request.type !== undefined
    ? '/' + request.type + '/' + encodeURIComponent(request.id) : ''
  const url = URL + '/' + encodeURIComponent(request.konfigurationVId) +
    '/frontendProperty' + suffix

  return api.authPut(url, request.frontendProperty)
}

const updateWohnungsgrundriss = (id, wohnungsgrundrissId, api = apiInstance) => {
  return api.authPost(URL + '/' + encodeURIComponent(id) +
    '/wohnungsgrundriss/' + wohnungsgrundrissId)
}

const fetchShareTokens = (id, api = apiInstance) => {
  return api.authPost(URL + '/' + encodeURIComponent(id) + '/share')
}

const fetchFromShareToken = (shareToken, api = apiInstance) => {
  return api.authPost(URL_KONFIGURATION + '/byShareToken/' + encodeURIComponent(shareToken))
}

const fetchReport = (id, api = apiInstance) => {
  return api.authGet(URL + '/' + encodeURIComponent(id) + '/report', { responseType: 'blob' })
}

export default {
  fetch,
  create,
  createKonfiguration,
  fetchVariationList,
  updateVariation,
  updateNumberVariation,
  updateTextVariation,
  updateFrontendProperty,
  updateWohnungsgrundriss,
  fetchShareTokens,
  fetchFromShareToken,
  fetchReport
}

function getQuery (kategorieClasses, onlySelected) {
  const kategorieClassesQuery = kategorieClasses.length > 0
    ? 'kategorieClasses=' + kategorieClasses.join(',') : undefined
  const onlySelectedQuery = onlySelected ? 'onlySelected=true' : undefined
  const query = [kategorieClassesQuery, onlySelectedQuery]
    .filter(item => item !== undefined)
    .join('&')

  return query.length > 0 ? '?' + query : ''
}
