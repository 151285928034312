import React from "react";
import Columns from "./Columns";
import Translate from "../../../../lib/App/common/translator/components/translate";

const SearchGroupLabel = (props) => {
  const translateTitle =
    props.translateId && props.title ? (
      <Translate id={props.translateId + "name"}>{props.title}</Translate>
    ) : (
      ""
    );
  const translateDesc =
    props.translateId && props.desc ? (
      <Translate id={props.translateId + "beschreibung"}>
        {props.desc}
      </Translate>
    ) : (
      ""
    );

  return (
    <Columns large={5} medium={5} small={12}>
      <h2>{translateTitle}</h2>
      <p>{translateDesc}</p>
    </Columns>
  );
};

export default SearchGroupLabel;
