import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './action'
import * as konfigurationActions from '../App/common/konfigurator/action'
import { withRouter, Link } from 'react-router-dom'
import Hero from '../App/common/Hero'
import Steps from '../App/common/Steps'
import Highlights from '../App/common/Highlights'
import NextPrevNavigation from '../App/common/NextPrevNavigation'
import Image from '../App/common/Image'
import Content from '../App/common/kategorieTabs/Content'
import { TabHeader, TabContent } from '../App/common/kategorieTabs/Tabs'
import Share from './components/Share'

export class Zusammenfassung extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      kategorien: [],
      kategorie: { kategorieId: undefined, optionen: [], classes: [] }
    }

    this.handleTabClick = this.handleTabClick.bind(this)
  }

  componentWillMount () {
    this.setKonfigurationVId(Number(this.props.match.params.konfigurationVId))
  }

  componentDidMount () {
    this.props.konfigurationActions.updateKonfigurationFrontendProperty({
      step: 4,
      url: this.props.history.location.pathname
    })
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.interieurKategorien !== undefined && nextProps.interieurKategorien.length > 0) {
      const kategorien = this.filterKategorien(nextProps.interieurKategorien)
      this.setState(state => ({ kategorien, kategorie: Object.assign({}, kategorien[0]) }))
    }

    const konfigurationVId = Number(nextProps.match.params.konfigurationVId)
    if (konfigurationVId !== this.props.konfigurationV.konfigurationVId) {
      this.setKonfigurationVId(konfigurationVId)
    }
  }

  filterKategorien (kategorien) {
    return kategorien
  }

  setKonfigurationVId (konfigurationVId) {
    isNaN(konfigurationVId)
      ? this.props.history.push('/fehler/404')
      : this.props.actions.init({ konfigurationVId: konfigurationVId, kategorieClasses: [] })
  }

  handleTabClick (event, kategorie) {
    event.preventDefault()
    this.setState(state => ({ kategorie: Object.assign({}, kategorie) }))
  }

  getNav () {
    return { previousLink: '/', previousTitle: 'Konfiguration verlassen' }
  }

  render () {
    const nav = this.getNav()
    const ausstattungslinie = this.props.konfigurationV.highlights !== undefined
      ? this.props.konfigurationV.highlights.ausstattungslinie.name : ''
    const zusatzOptionen = this.props.zusatzOptionenKategorien.length > 0
      ? (
        <div className='m-b-lg'>
          <h1 className='title'>Zusatzoptionen
            <Link className='m-l-md'
              to={'/konfigurator/' + this.props.konfigurationV.konfigurationVId + '/zusatzoptionen'}>
              <i className='fa fa-pencil' />
            </Link>
          </h1>
          <div className='notification is-light'>
            {this.props.zusatzOptionenKategorien.map(
              kategorie => <Content key={kategorie.kategorieId} currentKategorie={kategorie} />)}
          </div>
        </div>
      )
      : null
    const { frontendProperty = {} } = this.props.konfigurationV
    const { generatePdf = false } = this.props.report || {}
    const pdfDownloadName = generatePdf ? 'PDF wird generiert..' : 'PDF Download'
    const pdfDownloadClasses = !generatePdf ? ' is-clickable' : ''

    return (
      <div>
        <Hero
          title='Ihre Wohnung auf einen Blick'
          subtitle={'Wohnung ' + this.props.wohnungsgrundriss.wohnungsCodeReadable}
          description='So sieht Ihre Wohnung in Datahouse nach Ihren Wünschen aus. Sie können weitere Anpassungen vornehmen, oder Ihre Auswahl speichern und später wieder abrufen oder auch als PDF herunterladen. Ihre Konfiguration ist vollkommen unverbindlich, aber eine wertvolle Besprechungsgrundlage bei Ihrem persönlichen Beratungstermin.' />
        <div className='section'>
          <div className='container'>
            <div className='content'>
              <Steps step={4}
                highestStep={frontendProperty.highestStep}
                wohnungsgrundrissId={this.props.wohnungsgrundriss.wohnungsgrundrissId}
                konfigurationVId={this.props.konfigurationV.konfigurationId} />
            </div>
            <div className='content'>
              <Highlights {...this.props.wohnungsgrundriss}
                aufpreis={this.props.konfigurationV.aufpreis}
                totalpreis={this.props.konfigurationV.preis}
                konfigurationName={this.props.konfigurationV.name}
                readOnly={this.props.konfigurationV.readOnly}
                editKonfigurationName
                konfigurationId={this.props.konfigurationV.konfigurationId}
                showSaveButton={this.props.user === null}
                highlights={this.props.konfigurationV.highlights}
                wohnungsgrundrissName={this.props.wohnungsgrundriss.grundrissName} />
            </div>
            <Share shareToken={this.props.shareToken} />
            <div className='content'>
              <h1>PDF download</h1>
              <p>
                <button
                  onClick={() =>
                    this.props.konfigurationActions.fetchReport(
                      this.props.konfigurationV.konfigurationVId,
                      this.props.konfigurationV.name)}
                  disabled={generatePdf}
                  className={'button is-primary is-medium ' + pdfDownloadClasses}>
                  <span className='icon is-small'>
                    <i className='fa fa-file-pdf-o' />
                  </span>
                  <span>{pdfDownloadName}</span>
                </button>
              </p>
            </div>
            <div className='content'>
              <h1>Grundriss: {this.props.wohnungsgrundriss.grundrissName}
                <Link className='m-l-md'
                  to={'/konfigurator/' + this.props.konfigurationV.konfigurationVId + '/wohnungsgrundriss/' + this.props.wohnungsgrundriss.wohnungsgrundrissId}>
                  <i className='fa fa-pencil' />
                </Link>
              </h1>
              <Image src={this.props.wohnungsgrundriss.imagePath}
                alt='grundriss wohnung' height='603p' ratio='r1.66Vec' type='svg' />
              <h1>Ausstattungslinie: {ausstattungslinie}
                <Link className='m-l-md'
                  to={'/konfigurator/' + this.props.konfigurationV.konfigurationVId + '/interieur'}>
                  <i className='fa fa-pencil' />
                </Link>
              </h1>
            </div>
            <TabHeader currentKategorie={this.state.kategorie}
              kategorien={this.state.kategorien} handleTabClick={this.handleTabClick} />
            <TabContent currentKategorie={this.state.kategorie} />
            {zusatzOptionen}
            <div className='content'>
              <NextPrevNavigation {...nav} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state) {
  const kategorien = state.zusammenfassung.variationList.slice(1)
  return {
    wohnungsgrundriss: state.wohnungsgrundriss.current,
    konfigurationV: state.konfiguration.currentV,
    user: state.user.current,
    kategorien: kategorien,
    interieurKategorien: kategorien.filter(
      kategorie => kategorie.classes.indexOf('interieur') > -1),
    zusatzOptionenKategorien: kategorien.filter(
      kategorie => kategorie.classes.indexOf('zusatzoptionen') > -1),
    shareToken: state.konfiguration.shareToken,
    report: state.konfiguration.report
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    konfigurationActions: bindActionCreators(konfigurationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Zusammenfassung))
