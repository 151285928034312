import * as config from '../../common/config'
import apiInstance from '../../common/api'

const URL = config.API_URL + '/' + config.API_VERSION + '/auth'

const access = (api = apiInstance) => {
  return api.authGet(URL + '/access')
}

export default { access }
