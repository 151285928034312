import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from './action'

import Konfiguration from './compontents/Konfiguration'
import { kategorienSwitch } from './compontents/Kategorien'
import EmphasizedKategorie from './compontents/EmphasizedKategorie'
import CollapsableKategorie from './compontents/CollapsableKategorie'
import Kategorie from './compontents/Kategorie'
import PackageKategorie from './compontents/PackageKategorie'
import { optionenSwitch, BooleanOption, TextOnlyOption, TextOption, NumberOption }
  from './compontents/Optionen'
import { variationenSwitch, Variation, ReadOnlyVariation } from './compontents/Variationen'

export class Konfigurator extends Component {
  constructor (props, context) {
    super(props, context)

    this.getPopups = this.getPopups.bind(this)
    this.handleVariationChange = this.handleVariationChange.bind(this)
  }

  bootstrap (props, forceUpdate = false) {
    const { konfigurationVId, kategorieClasses } = props
    this.props.actions.bootstrap(konfigurationVId, kategorieClasses, false, forceUpdate)
  }

  componentWillMount () {
    this.bootstrap(this.props, true)
  }

  componentWillReceiveProps (nextProps) {
    this.bootstrap(nextProps)
  }

  getSwitches () {
    return {
      kategorienSwitch: this.props.switches.kategorienSwitch,
      optionenSwitch: this.props.switches.optionenSwitch,
      variationenSwitch: this.props.switches.variationenSwitch
    }
  }

  getPopups (classes) {
    return []
  }

  getComponents () {
    return {
      EmphasizedKategorie: this.props.components.EmphasizedKategorie,
      CollapsableKategorie: this.props.components.CollapsableKategorie,
      PackageKategorie: this.props.components.PackageKategorie,
      Kategorie: this.props.components.Kategorie,
      BooleanOption: this.props.components.BooleanOption,
      TextOnlyOption: this.props.components.TextOnlyOption,
      TextOption: this.props.components.TextOption,
      NumberOption: this.props.components.NumberOption,
      Variation: this.props.components.Variation,
      ReadOnlyVariation: this.props.components.ReadOnlyVariation
    }
  }

  handleVariationChange (variation, value = undefined, type = 'variation') {
    switch (type) {
      case 'number':
        this.props.actions.updateNumber(variation.numberId, value, this.props.kategorieClasses)
        break
      case 'text':
        this.props.actions.updateText(variation.textId, value, this.props.kategorieClasses)
        break
      default:
        if (!variation.selected) {
          this.props.actions.updateVariation(variation.variationId, this.props.kategorieClasses)
        }
    }
  }

  render () {
    const components = this.getComponents()
    const switches = this.getSwitches()

    return (
      <Konfiguration kategorien={this.props.kategorien}
        isReadOnly={this.props.isReadOnly || false}
        handleVariationChange={this.handleVariationChange}
        handleFrontendPropertyChange={this.props.actions.updateFrontendProperty}
        components={components}
        switches={switches}
        getPopups={this.getPopups} />
    )
  }
}

Konfigurator.propTypes = {
  konfigurationVId: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool,
  page: PropTypes.string,
  switches: PropTypes.shape({
    kategorienSwitch: PropTypes.func,
    optionenSwitch: PropTypes.func,
    variationenSwitch: PropTypes.func
  }),
  components: PropTypes.shape({
    EmphasizedKategorie: PropTypes.func, // React Component
    CollapsableKategorie: PropTypes.func, // React Component
    PackageKategorie: PropTypes.func, // React Component
    Kategorie: PropTypes.func, // React Component
    BooleanOption: PropTypes.func, // React Component
    TextOnlyOption: PropTypes.func, // React Component
    TextOption: PropTypes.func, // React Component
    NumberOption: PropTypes.func, // React Component
    Variation: PropTypes.func, // React Component
    ReadOnlyVariation: PropTypes.func // React Component
  })
}

Konfigurator.defaultProps = {
  isReadOnly: false,
  switches: {
    kategorienSwitch,
    optionenSwitch,
    variationenSwitch
  },
  components: {
    EmphasizedKategorie,
    CollapsableKategorie,
    PackageKategorie,
    Kategorie,
    BooleanOption,
    TextOnlyOption,
    TextOption,
    NumberOption,
    Variation,
    ReadOnlyVariation
  }
}

export function mapStateToProps (state) {
  return {
    kategorien: state.konfiguration.variationList,
    konfigurationV: state.konfiguration.currentV
  }
}

export function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators(actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Konfigurator)
