import React from 'react'
import VariationenSwitch from './Variationen'
import { BooleanOption } from './Optionen'

const PackageKategorie = props => {
  const booleanOption = props.optionen.filter(option => option.classes.indexOf('boolean') > -1)[0]
  const variationOption = props.optionen.filter(option => option.classes.indexOf('boolean') === -1)[0]
  const variations = variationOption !== undefined
    ? <VariationenSwitch {...variationOption} components={props.components} switches={props.switches}
      handleVariationChange={props.handleVariationChange} isReadOnly={props.isReadOnly} />
    : null

  return (
    <div className='box m-b-lg'>
      <h1 className='title'>{props.name}</h1>
      <p>{props.beschreibung}</p>
      <div className='columns'>
        <div className='column is-5'>
          <BooleanOption {...booleanOption} isReadOnly={props.isReadOnly}
            handleVariationChange={props.handleVariationChange} />
        </div>
        <div className='column is-7 variationen'>
          <div className='variation-body'>
            {variations}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PackageKategorie
