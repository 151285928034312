import * as config from '../App/common/config'
import apiInstance from '../App/common/api'

const URL = config.API_URL + '/' + config.API_VERSION + '/users'

const register = (formData, api = apiInstance) => {
  return api.authPost(URL + '/register', formData)
}

const login = (formData, api = apiInstance) => {
  return api.authPost(URL + '/login', formData)
}

const activate = (token, api = apiInstance) => {
  return api.authPut(URL + '/activation/' + encodeURIComponent(token))
}

const recoverPassword = (formData, api = apiInstance) => {
  return api.authPost(URL + '/passwordRecovery', formData)
}

const resetPassword = (formData, api = apiInstance) => {
  return api.authPut(URL + '/passwordRecovery/' + encodeURIComponent(formData.token), formData)
}

const updateProfile = (id, formData, api = apiInstance) => {
  return api.authPut(URL + '/' + encodeURIComponent(id), formData)
}

export default { register, login, activate, recoverPassword, resetPassword, updateProfile }
