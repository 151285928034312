import React from "react";
import { NumberVariation, TextVariation } from "./Variationen";
import Image from "../../App/common/Image";
import Translate from "../../lib/App/common/translator/components/translate";

const Optionen = (props) => {
  const optionen = props.optionen || [];
  return optionen.map((option) => {
    const optionWithStyle = { ...option, styling: props.styling };
    return optionenSwitch(optionWithStyle);
  });
};

export default Optionen;

function optionenSwitch(option) {
  if (
    ~option.classes.indexOf("boolean") &&
    ~option.classes.indexOf("no_image") &&
    ~option.classes.indexOf("summary_full_width")
  ) {
    return <FullWidthOption key={option.optionId} {...option} />;
  } else if (
    ~option.classes.indexOf("boolean") &&
    ~option.classes.indexOf("no_image")
  ) {
    return <BooleanNoImage key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("boolean")) {
    return <BooleanOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("info")) {
    return <InfoOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("number")) {
    return <NumberOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("text")) {
    return <TextOption key={option.optionId} {...option} />;
  } else if (~option.classes.indexOf("summary_full_width")) {
    return <FullWidthOption key={option.optionId} {...option} />;
  } else {
    return <Option key={option.optionId} {...option} />;
  }
}

const BooleanOption = (props) => {
  const variation = props.variationen[0] || {};
  const variationName =
    variation.name === "1"
      ? "Ja"
      : variation.name === "0"
      ? "Nein"
      : variation.name;
  const rowSpan = ~props.classes.indexOf("zusatzkosten")
    ? "large-12 medium-12 small-12"
    : "large-6 medium-6 small-12";
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variationName}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className={"bk-options-summary group columns " + rowSpan}
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOption}</h2>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
    </div>
  );
};

const BooleanNoImage = (props) => {
  const variation = props.variationen[0] || {};
  const variationName =
    variation.name === "1"
      ? "Ja"
      : variation.name === "0"
      ? "Nein"
      : variation.name;

  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variationName}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className={"bk-options-summary group columns large-6 medium-6 small-12"}
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOption}</h2>
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
    </div>
  );
};

const InfoOption = (props) => {
  const variation = props.variationen[0] || {};
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = variation.name ? (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variation.name}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOption}</h2>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
      </div>
    </div>
  );
};

const NumberOption = (props) => {
  const number = props.number || {};

  const translateOptionName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateOptionDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOptionName}</h2>
        <p>{translateOptionDesc}</p>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <NumberVariation
          {...number}
          name={props.name}
          code={props.optionCode}
        />
      </div>
    </div>
  );
};

const TextOption = (props) => {
  const text = props.text || {};

  const translateOptionName = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateOptionDesc = props.beschreibung ? (
    <Translate id={"optionen/" + props.optionCode + "/beschreibung"}>
      {props.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOptionName}</h2>
        <p>{translateOptionDesc}</p>
      </div>
      <div className="columns large-6 medium-6 small-12">
        <TextVariation {...text} code={props.optionCode} />
      </div>
    </div>
  );
};

const FullWidthOption = (props) => {
  const variation = props.variationen[0] || {};
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variation.name}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-12 medium-12 small-12"
      style={props.styling}
    >
      <div className="columns large-12 medium-12 small-12">
        <h2>{translateOption}</h2>
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
    </div>
  );
};

const Option = (props) => {
  const variation = props.variationen[0] || {};
  const aufpreisBeschreibung =
    variation.aufpreisBeschreibung !== undefined ? (
      <p className="costs-additional">
        <Translate
          id={
            "variationen/" + variation.variationCode + "/aufpreisBeschreibung"
          }
        >
          {variation.aufpreisBeschreibung}
        </Translate>
      </p>
    ) : null;
  const image =
    variation.imagePath !== undefined ? (
      <Image
        src={variation.imagePath}
        alt={"IMAGE " + variation.name}
        height="88p"
        ratio="r3.00"
      />
    ) : null;

  const translateOption = (
    <Translate id={"optionen/" + props.optionCode + "/name"}>
      {props.name}
    </Translate>
  );
  const translateVariationName = (
    <Translate id={"variationen/" + variation.variationCode + "/name"}>
      {variation.name}
    </Translate>
  );
  const translateVariationDesc = variation.beschreibung ? (
    <Translate id={"variationen/" + variation.variationCode + "/beschreibung"}>
      {variation.beschreibung}
    </Translate>
  ) : (
    ""
  );

  return (
    <div
      className="bk-options-summary group columns large-6 medium-6 small-12"
      style={props.styling}
    >
      <div className="columns large-6 medium-6 small-12">
        <h2>{translateOption}</h2>
        <h3>{translateVariationName}</h3>
        {aufpreisBeschreibung}
        <p>{translateVariationDesc}</p>
      </div>
      <div className="columns large-6 medium-6 small-12">{image}</div>
    </div>
  );
};
