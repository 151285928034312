import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as actions from './action'
import Hero from '../App/common/Hero'
import Filter from './components/Filter'
import Table from './components/Table'
import Authorized from '../App/common/Authorized'

export class KonfigurationList extends Authorized {
  constructor (props, context) {
    super(props, context)

    this.removeKonfiguration = this.removeKonfiguration.bind(this)
  }

  componentDidMount () {
    this.props.actions.fetchList(this.props.isMaklerList)
  }

  removeKonfiguration (event, id) {
    event.preventDefault()
    this.props.actions.remove(id)
  }

  isMakler () {
    return this.props.isMaklerList
  }

  getFilter (isMakler) {
    return isMakler ? <div><Filter isMakler={isMakler} /><hr /></div> : null
  }

  getTitle (isMakler) {
    return isMakler
      ? 'Kundenkonfigurationen'
      : 'Wählen Sie Ihre Traumwohnung'
  }

  getDescription (isMakler) {
    return isMakler
      ? 'Managen Sie die Konfigurationen ihrer Kunden'
      : 'Stellen Sie sich in wenigen Schritten Ihr neues Zuhause zusammen: Wählen Sie unten Ihre Wohnung. Danach können Sie je nach Wohnung den Grundriss definieren, die Interieur-Linie bestimmen und mögliche Zusatzoptionen auswählen.'
  }

  render () {
    const isMakler = this.isMakler()
    const filter = this.getFilter(isMakler)
    const title = this.getTitle(isMakler)
    const description = this.getDescription(isMakler)

    return (
      <div>
        <Hero title={title} description={description} />
        <div className='section'>
          <div className='container'>
            {filter}
            <Table actions={this.props.actions} rows={this.props.filteredList} isMakler={isMakler} report={this.props.report} />
          </div>
        </div>
      </div>
    )
  }
}

export function mapStateToProps (state) {
  return {
    filteredList: state.konfigurationList.filteredList,
    list: state.konfigurationList.list,
    user: state.user.current,
    report: state.konfigurationList.report
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

KonfigurationList.defaultProps = { isMaklerList: false }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KonfigurationList))
