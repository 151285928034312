import * as actionType from './actionType'
import * as konfigurationActionType from '../App/common/konfigurator/actionType'

export function init (konfigurationVId) {
  return { type: actionType.INIT, payload: konfigurationVId }
}

export function showKonfigurationVisualization () {
  return { type: konfigurationActionType.SAVE_KONFIGURATION }
}
