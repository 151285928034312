import { actionChannel, fork, call, put, select, take, takeLatest, delay } from 'redux-saga/effects'
import * as actionType from './actionType'
import * as zusammenfassungActionType from '../../../Zusammenfassung/actionType'
import * as errorActionType from '../error/actionType'
import provider from './provider'
import history from './../history'
import { buffers } from 'redux-saga'
import { renderPdfBlob } from './../helper'

import React from 'react'
import { toast } from 'react-toastify'
import Toaster from '../Toaster'

export function * bootstrap () {
  while (true) {
    const action = yield take(actionType.BOOTSTRAP)
    const kategorieClasses = yield select(state => state.konfiguration.kategorieClasses || [])
    yield call(bootstrapDecision, action, kategorieClasses)
  }
}

export function * bootstrapDecision (action, kategorieClasses) {
  if (kategorieClasses.length !== action.payload.kategorieClasses.length ||
    kategorieClasses.every((value, index) => value !== action.payload.kategorieClasses[index])) {
    yield put({ type: actionType.FETCH_VARIATION_LIST, payload: action.payload })
    yield put({ type: actionType.SET_KATEGORIE_CLASSES_LIST, payload: action.payload.kategorieClasses })
  } else if (action.payload.forceUpdate) {
    yield put({ type: actionType.SET_KONFIGURATION_V_READ_ONLY })
    yield put({ type: actionType.FETCH_VARIATION_LIST, payload: action.payload })
  }
}

export function * fetch (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH)
      const response = yield call(api.fetch, action.payload)
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data } })
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * fetchNew (api) {
  while (true) {
    const action = yield take(actionType.FETCH_NEW)
    const konfiguration = yield select(state => state.konfiguration.currentV)
    yield call(fetchNewDecisions, action, konfiguration, api)
  }
}

export function * fetchNewDecisions (action, konfiguration, api) {
  try {
    const user = yield select(state => state.user.current)
    if (konfiguration.konfigurationVId === undefined) {
      const response = yield call(
        api.createKonfiguration, {
          wohnungsgrundrissId: action.payload.wohnungsgrundrissId,
          variationIds: action.payload.variationIds || [],
          numbers: action.payload.numbers || []
        })
      yield call(setResponse, response, user, action.payload.nextPage)
    } else if (konfiguration.wohnungsgrundrissId !== action.payload.wohnungsgrundrissId) {
      const response = yield call(
        api.createKonfiguration, {
          wohnungsgrundrissId: action.payload.wohnungsgrundrissId,
          variationIds: action.payload.variationIds || [],
          numbers: action.payload.numbers || []
        })
      yield call(setResponse, response, user, action.payload.nextPage)
    } else {
      yield call(redirectToNextPage, konfiguration.konfigurationVId, action.payload.nextPage)
    }
  } catch (error) {
    yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
    yield put({ type: errorActionType.ERROR, payload: error })
  }
}

export function * setResponse (response, user, nextPage) {
  yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
  yield call(redirectToNextPage, response.data.konfigurationV.konfigurationVId, nextPage)
}

export function * redirectToNextPage (konfigurationVId, nextPage) {
  const historyPush = history.push.bind(history)
  yield call(historyPush, nextPage(konfigurationVId))
}

export function * fetchVariationList (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_VARIATION_LIST)
      const response = yield call(
        api.fetchVariationList, action.payload.konfigurationVId,
        action.payload.kategorieClasses, action.payload.onlySelected)
      yield put({ type: actionType.SET_VARIATION_LIST, payload: response.data.kategorien })
      const konfiguration = yield select(state => state.konfiguration.currentV)
      yield call(fetchVariationListDecision, konfiguration, response)
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: actionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * fetchVariationListDecision (konfigurationV, response) {
  if (konfigurationV.konfigurationVId !== response.data.konfigurationV.konfigurationVId) {
    yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
    const url = history.location.pathname
      .replace(/\/konfigurator\/.+\/(.+)/, '/konfigurator/' + response.data.konfigurationV.konfigurationVId + '/$1')
    const historyPush = history.push.bind(history)
    yield call(historyPush, url)
  } else {
    yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
  }
}

export function * selectVariation () {
  while (true) {
    const action = yield take(actionType.SELECT_VARIATION)
    yield put({ type: actionType.SET_VARIATION, payload: action.payload.variationId })
    yield put({ type: actionType.UPDATE_VARIATION, payload: action.payload })
  }
}

export function * selectNumberVariation () {
  while (true) {
    const action = yield take(actionType.SELECT_NUMBER_VARIATION)
    yield put({ type: actionType.UPDATE_NUMBER_VARIATION, payload: action.payload })
  }
}

export function * selectTextVariation () {
  while (true) {
    const action = yield take(actionType.SELECT_TEXT_VARIATION)
    yield put({ type: actionType.UPDATE_TEXT_VARIATION, payload: action.payload })
  }
}

export function * updateVariation (api) {
  const buffer = buffers.expanding()
  const channel = yield actionChannel(actionType.UPDATE_VARIATION, buffer)

  while (true) {
    const action = yield take(channel)

    try {
      const konfigurationVId = yield select(state => state.konfiguration.currentV.konfigurationVId)
      const response = yield call(
        api.updateVariation, konfigurationVId, action.payload.variationId, action.payload.kategorieClasses)
      yield put({ type: actionType.SAVE_KONFIGURATION })
      if (buffer.isEmpty()) {
        yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
        yield put({ type: actionType.SET_VARIATION_LIST, payload: response.data.kategorien })
        yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      }
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: actionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateNumberVariation (api) {
  const buffer = buffers.expanding()
  const channel = yield actionChannel(actionType.UPDATE_NUMBER_VARIATION, buffer)

  while (true) {
    const action = yield take(channel)

    try {
      if (buffer.isEmpty()) {
        const konfigurationVId = yield select(state => state.konfiguration.currentV.konfigurationVId)
        const response = yield call(
          api.updateNumberVariation, konfigurationVId,
          action.payload.numberId, action.payload.value, action.payload.kategorieClasses)
        yield put({ type: actionType.SAVE_KONFIGURATION })
        yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
        yield put({ type: actionType.SET_VARIATION_LIST, payload: response.data.kategorien })
        yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      }
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: actionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateTextVariation (api) {
  const buffer = buffers.expanding()
  const channel = yield actionChannel(actionType.UPDATE_TEXT_VARIATION, buffer)

  while (true) {
    const action = yield take(channel)

    try {
      if (buffer.isEmpty()) {
        const konfigurationVId = yield select(state => state.konfiguration.currentV.konfigurationVId)
        const response = yield call(
          api.updateTextVariation, konfigurationVId,
          action.payload.textId, action.payload.value, action.payload.kategorieClasses)
        yield put({ type: actionType.SAVE_KONFIGURATION })
        yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
        yield put({ type: actionType.SET_VARIATION_LIST, payload: response.data.kategorien })
        yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      }
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: actionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateKonfigurationFrontendProperty (api) {
  while (true) {
    const action = yield take(actionType.UPDATE_KONFIGURATION_FRONTEND_PROPERTY)
    let konfiguration = yield select(state => state.konfiguration.currentV)
    if (Object.keys(konfiguration).length === 0) {
      yield take(actionType.SET_KONFIGURATION_V)
      konfiguration = yield select(state => state.konfiguration.currentV)
    }
    const konfigurationVFrontendProperty = yield call((frontendProperty = {}, action) => {
      const currentHighestStep = frontendProperty.highestStep || 1
      const step = action.payload.step || 1
      const highestStep = currentHighestStep < step ? step : currentHighestStep
      const lastUrl = action.payload.url
      return Object.assign({}, frontendProperty, { lastUrl, highestStep })
    }, konfiguration.frontendProperty, action)
    yield call(function * (konfigurationVFrontendProperty, konfiguration) {
      if (JSON.stringify(konfigurationVFrontendProperty) !==
          JSON.stringify(konfiguration.frontendProperty)) {
        const konf = Object.assign({}, konfiguration,
          { frontendProperty: konfigurationVFrontendProperty })
        yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: konf } })
        yield put({ type: actionType.UPDATE_FRONTEND_PROPERTY,
          payload: { type: undefined, frontendProperty: konfigurationVFrontendProperty } })
      }
    }, konfigurationVFrontendProperty, konfiguration)
  }
}

export function * updateFrontendProperty (api) {
  while (true) {
    try {
      const action = yield take(actionType.UPDATE_FRONTEND_PROPERTY)
      const frontendProperty = yield call(() => Object.assign({}, action.payload))
      let konfigurationVId = yield select(state => state.konfiguration.currentV.konfigurationVId)
      yield call(() => (frontendProperty.konfigurationVId = konfigurationVId))
      yield put({ type: actionType.SET_FRONTEND_PROPERTY, payload: frontendProperty })
      yield call(api.updateFrontendProperty, frontendProperty)
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateWohnungsgrundriss (api) {
  while (true) {
    try {
      const action = yield take(actionType.UPDATE_WOHNUNGSGRUNDRISS)
      const wohnungsgrundrissId =
        yield select(state => state.konfiguration.currentV.wohnungsgrundrissId)
      yield call(updateWohnungsgrundrissDecision, wohnungsgrundrissId, action, api)
    } catch (error) {
      yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: {} } })
      yield put({ type: actionType.SET_KATEGORIE_CLASSES_LIST, payload: ['nostate!'] })
      yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * updateWohnungsgrundrissDecision (wohnungsgrundrissId, action, api) {
  if (wohnungsgrundrissId !== action.payload.wohnungsgrundrissId) {
    const response = yield call(
      api.updateWohnungsgrundriss,
      action.payload.konfigurationVId,
      action.payload.wohnungsgrundrissId)
    yield put({ type: actionType.SAVE_KONFIGURATION })
    yield put({ type: actionType.SET_KONFIGURATION_V, payload: { konfigurationV: response.data.konfigurationV } })
    yield put({ type: actionType.SET_KATEGORIE_CLASSES_LIST, payload: ['nostate!'] })
    yield put({ type: zusammenfassungActionType.SET_VARIATION_LIST, payload: [] })
  }
}

export function * fetchFromShareToken (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_FROM_SHARE_TOKEN)
      const response = yield call(api.fetchFromShareToken, action.payload)
      const historyPush = history.push.bind(history)
      yield call(historyPush,
        '/konfigurator/' + encodeURIComponent(response.data.konfigurationV.konfigurationVId) + '/zusammenfassung')
    } catch (error) {
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * fetchReport (api) {
  while (true) {
    try {
      const action = yield take(actionType.FETCH_REPORT)
      const response = yield call(api.fetchReport, action.payload.konfigurationVId)
      yield call(renderPdfBlob, response.data, action.payload.filename)
      yield put({ type: actionType.FETCH_REPORT_FINISHED })
    } catch (error) {
      yield put({ type: actionType.FETCH_REPORT_FINISHED })
      yield put({ type: errorActionType.ERROR, payload: error })
    }
  }
}

export function * saveKonfigurationVisualisation () {
  const user = yield select(state => state.user.current)
  if (user !== null && user !== undefined) {
    yield call(showToast)
  }
}

export const sagas = [
  fork(bootstrap),
  fork(fetch, provider),
  fork(fetchNew, provider),
  fork(fetchVariationList, provider),
  fork(fetchReport, provider),
  fork(selectVariation),
  fork(selectNumberVariation),
  fork(selectTextVariation),
  fork(updateVariation, provider),
  fork(updateNumberVariation, provider),
  fork(updateTextVariation, provider),
  fork(updateKonfigurationFrontendProperty, provider),
  fork(updateFrontendProperty, provider),
  fork(updateWohnungsgrundriss, provider),
  fork(fetchFromShareToken, provider),
  takeLatest(actionType.SAVE_KONFIGURATION, saveKonfigurationVisualisation)
]

let toastrId = null

export function * showToast () {
  if (!toast.isActive(toastrId)) {
    yield delay(350)
    toastrId = yield call(toast.info, <Toaster />, {
      autoClose: 2000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER })
  } else {
    yield call(toast.update, toastrId)
  }
}
