import * as actionType from "./actionType";

export function toggleActiveBuero(number) {
  return { type: actionType.TOGGLE_ACTIVE_BUERO, payload: number };
}

export function setHighlightedUnits(units) {
  return { type: actionType.SET_HIGHLIGHTED_UNITS, payload: units };
}

export function setUnitsBeforeHover(units) {
  return { type: actionType.SET_UNITS_BEFORE_HOVER, payload: units };
}
