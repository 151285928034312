import React, { Component } from 'react'
import PropTypes from 'prop-types'

const Modal = props => {
  const active = props.isActive ? ' is-active' : ''
  return (
    <div>
      <div className={'modal' + active}>
        <div className='modal-background' onClick={props.toggleModal} />
        <div className='modal-content'>
          {props.children}
        </div>
        <button onClick={props.toggleModal} className='modal-close is-large' aria-label='close' />
      </div>
    </div>
  )
}

export default Modal

export class BaseModal extends Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      toggleModal: false
    }

    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal () {
    this.setState(state => ({ toggleModal: !state.toggleModal }))
  }

  render () {
    return <div />
  }
}

export const ConfirmModal = props => {
  return (
    <Modal isActive={props.isActive} toggleModal={props.toggleModal}>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>{props.title}</p>
          <button onClick={props.toggleModal} className='delete' aria-label='close' />
        </header>
        <section className='modal-card-body'>
          {props.description}
        </section>
        <footer className='modal-card-foot'>
          <button onClick={props.confirm} className='button is-primary'>{props.buttonText}</button>
          <button onClick={props.toggleModal} className='button'>Abbrechen</button>
        </footer>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
}
