import { takeLatest, put, call, select, take, fork } from "redux-saga/effects";
import * as actionType from "../lib/WohnungList/actionType";
import * as errorActionType from "../lib/App/common/error/actionType";
import provider from "../lib/WohnungList/provider";

export function* fetchList(api) {
  while (true) {
    const action = yield take(actionType.FETCH_LIST);
    const list = yield select((state) => state.wohnungList.list);
    yield call(fetchListIfNotExists, list, action.payload, api);
  }
}

export function* fetchListIfNotExists(list, queries, api) {
  if (list === undefined || list.length === 0) {
    try {
      const response = yield call(api.getList);
      yield put({ type: actionType.FILTER_INIT, payload: response.data });
      yield put({ type: actionType.SET_LIST, payload: response.data });
      yield put({ type: actionType.FILTER_UPDATE, payload: queries });
      const filters = yield select((state) => ({
        filters: state.wohnungList.filters,
        sort: state.wohnungList.sort,
      }));
      const filteredResponse = yield call(
        filterList,
        response.data,
        filters.filters,
        filters.sort
      );
      yield put({
        type: actionType.SET_FILTERED_LIST,
        payload: filteredResponse,
      });
    } catch (error) {
      yield put({ type: actionType.SET_LIST, payload: [] });
      yield put({ type: actionType.SET_FILTERED_LIST, payload: [] });
      yield put({ type: errorActionType.ERROR, payload: error });
    }
  }
}

export function* setFilteredList() {
  try {
    const data = yield select((state) => {
      return {
        filters: state.wohnungList.filters,
        list: state.wohnungList.list,
        sort: state.wohnungList.sort,
      };
    });
    const response = yield call(filterList, data.list, data.filters, data.sort);
    yield put({ type: actionType.SET_FILTERED_LIST, payload: response });
  } catch (error) {
    yield put({ type: actionType.SET_FILTERED_LIST, payload: [] });
    yield put({ type: errorActionType.ERROR, payload: error });
  }
}

function filterList(list, filters, sort) {
  let flaeche = filters.area;
  if (filters.lastToggled === "staff") {
    flaeche = filters["search-area"];
  }
  const filteredList = list.filter(
    (item) =>
      Number(item.flaechenbedarfMax) >= Number(flaeche) &&
      Number(item.flaechenbedarfMin) <= Number(flaeche)
  );
  return sortList(filteredList, sort);
}

export function* setSortedList() {
  try {
    const data = yield select((state) => {
      return {
        sort: state.wohnungList.sort,
        list: state.wohnungList.filteredList,
      };
    });
    const response = yield call(sortList, data.list, data.sort);
    yield put({ type: actionType.SET_FILTERED_LIST, payload: response });
  } catch (error) {
    yield put({ type: actionType.SET_FILTERED_LIST, payload: [] });
    yield put({ type: errorActionType.ERROR, payload: error });
  }
}

function sortList(list, sort) {
  const sortASC = (a, b, field) => {
    if (a[field] > b[field]) return 1;
    else if (a[field] < b[field]) return -1;
    return 0;
  };

  list.sort((a, b) => {
    const comparison = sortASC(a, b, sort.field);
    return sort.order === "DESC" ? comparison * -1 : comparison;
  });

  return list;
}

export const sagas = [
  fork(fetchList, provider),
  takeLatest(actionType.FILTER_UPDATE, setFilteredList),
  takeLatest(actionType.FILTER_RESET, setFilteredList),
  takeLatest(actionType.SORT_UPDATE, setSortedList),
];
